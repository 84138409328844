.toastNotification {
  position: fixed;
  top: 5px;
  left: 50%;
  transform: translate(-50%, -200px);
  width: calc(100% - 20px);
  max-width: 500px;
  height: fit-content;
  padding: 20px;
  box-sizing: border-box;
  background: #ff6900;
  border-radius: 20px;
  z-index: 9;
  animation: slideTopToBottom 6s;
  text-align: left;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  a {
    color: #ff6900;
  }
}
.swipeable {
  position: fixed;
  top: 100px;
  left: 10px;
  transition: transform 0.5s linear;
  z-index: 9;
}
.swiped {
  opacity: 0;
  transition: 0.5s;
}
@keyframes slideTopToBottom {
  0% {
    transform: translate(-50%, -200px);
  }
  10% {
    transform: translate(-50%, 10px);
  }
  90% {
    transform: translate(-50%, 10px);
  }
  100% {
    transform: translate(-50%, -200px);
  }
}
