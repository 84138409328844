.listCart {
  text-align: left;
  position: relative;
  height: 100%;
  width: 100%;
  &.other {
    min-height: fit-content;
  }
  button {
    background: transparent;
    border: none;
    color: #555;
    padding: 10px 0;
    margin-bottom: 1px;
    opacity: 1 !important;
  }
  button:disabled {
    display: none;
  }
  .checkboxLabel {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    display: block;
    input {
      display: none;
    }
    .checkmark {
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 50%;
      border: 1px solid #ccc;
      box-sizing: border-box;
      position: relative;
      transition: 0.35s;
      svg {
        position: absolute;
        left: 0;
        top: 0;
        margin: 0;
        padding: 0;
        transform: scale(0);
        width: 100%;
        height: 100%;
        display: block;
        transition: 0.35s;
      }
    }
  }
  .shoppingListItem {
    display: block !important;
    padding: 5px 0 !important;
    margin: 0 !important;
    width: 100%;
    opacity: 1 !important;
    .checkedIngredientLine {
      display: block;
      width: 0;
    }
    .flexHeader {
      padding: 5px 0;
    }
  }
  .shoppingListItem:disabled {
    opacity: 1 !important;
    p,
    small,
    .flexHeaderSpan {
      color: #ccc;
      transition: 0.35s;
      pointer-events: none;
    }
    img {
      filter: grayscale(1);
    }
    .checkedIngredientLine {
      display: block;
      width: calc(100% - 60px);
      height: 2px;
      position: absolute;
      top: 20px;
      left: 35px;
      background: rgba(0, 0, 0, 0.3);
      transition: 0.5s;
      border-radius: 50px;
    }
  }

  .checkboxLabel input:checked ~ .checkmark {
    background-color: #ff6900;
    border: 1px solid #ff6900;
    animation: bounce 0.35s;

    svg {
      transform: scale(0.9);
      color: #fff;
    }
  }
}

.listItems {
  position: relative;
  padding: 0;
  border-radius: 20px;
  min-height: 45vh;
  height: 45vh;
  margin: 10px 0;
  display: flex;
  align-items: center;
  overflow: auto;
  .listQuantityButton {
    display: none;
  }
  .emptyShoppingList {
    min-width: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40vh;
    img {
      width: 30%;
    }
    h4 {
      color: #999;
      font-weight: 600;
    }
  }
  &.skeleton {
    .btnPrimary {
      background: #fff;
      width: 38px;
      height: 38px;
    }
    input:invalid {
      background: #f5f5f5 !important;
      border: 1px solid #eee;
    }
    .flexShare {
      svg {
        color: #fff !important;
      }
      small {
        display: block;
        width: 44px;
        height: 14px;
        background: #f5f5f5;
        margin: 5px auto 0 auto;
        border-radius: 50px;
      }
    }
  }
  .flexHeader {
    margin-bottom: 0;
  }
  form {
    position: relative;
    input {
      margin: 0;
    }
    .flexInput {
      border: 0;
      margin: 10px 0;
      .quantityUnit {
        .input {
          width: fit-content;
        }
        .inputText {
          width: 40px;
        }
        select {
          width: 40px;
          border: none;
        }
        max-width: 30%;
        padding: 0 5px;
        .input:last-child {
          margin-left: 5px;
        }
        .input {
          border: 1px solid #555 !important;
          border-radius: 10px;
          input,
          select {
            padding: 10px 5px !important;
          }
        }
      }
    }
    button {
      width: fit-content;
      padding: 0;
      min-width: 41px;
      min-height: 41px;
      border-radius: 10px;
      text-align: center;
      background: #ff6900;
      svg {
        width: 100%;
        height: 100%;
        padding: 8px;
        box-sizing: border-box;
        display: block;
        color: #fff;
      }
    }
  }
  .listItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin: 10px 0;
    width: 100%;
    box-sizing: border-box;
    border-radius: 8px;
    background: #000;
    svg {
      display: block;
    }
    .listItemName {
      width: 50%;
      text-align: left;
    }
    .listItemQuantities {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 25%;
    }
  }
}

.ingredientImage {
  // min-width: 100%;
  // height: 120px;
  min-width: 116px !important;
  margin: 8px 0;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
  width: 100%;
  aspect-ratio: 1;
  width: fit-content;
  img {
    background: #f5f5f5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    aspect-ratio: 1;
    display: none;
    object-fit: cover;
    border-radius: 50%;
    display: block;
    border: 2px solid #f9f9f9;
  }
  input {
    display: none;
  }
  label {
    display: flex;
    align-items: center;
    span {
      text-align: center;
      margin: 0 auto;
      color: #999;
      svg {
        display: block;
        font-size: 40px;
        margin: 0 auto 10px auto;
      }
    }
  }
}
