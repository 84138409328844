.calendar {
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 4;
  .swiper {
    overflow: visible;
    width: 100%;
    .swiper-slide {
      opacity: 1;
      transition: 0.35s;
      overflow: visible;
      &.swiper-slide-next {
        opacity: 0;
        transition: 0.35s;
      }
      &.swiper-slide-prev {
        opacity: 0;
        transition: 0.35s;
      }
    }
    .backdrop999 {
      position: fixed !important;
      top: -150px !important;
      left: -6% !important;
      width: 112% !important;
      background: transparent;
    }
    .modalNotify {
      transform: translate(-50%, 10vh) !important;
    }
    .modal {
      top: 50px;
      z-index: 9;
      padding: 0;
      .selectedDayContent {
        position: relative;
        height: fit-content;
        overflow: auto;
        min-height: calc(100vh - 150px);
        height: calc(100vh - 150px);
        max-height: calc(100vh - 150px);
        .flexCalendarFix {
          position: relative;
          .addedRecipe {
            background: #fff;
          }

          .modal {
            position: absolute;
            top: 0 !important;
            min-height: fit-content !important;
            height: fit-content !important;
            z-index: 9;
            .flexCalendarFix {
              margin: 0;
            }
          }
        }
      }
      &.noPadding {
        top: -35px;
        overflow: visible;
        .modalBackground {
          position: fixed;
          top: 0;
          left: 0;
          background: #fff !important;
          z-index: 9;
          overflow-y: scroll;
          overflow-x: visible;
          box-sizing: border-box;
          height: fit-content;
          background: #fff;
          width: 100%;
          .btnPrimary:disabled {
            display: block;
            opacity: 0.5;
          }
          button:disabled {
            display: none;
          }
          .card {
            box-sizing: border-box;
            padding: 0;
            img {
              pointer-events: initial;
              min-width: 100%;
              width: 100%;
              max-width: 500px;
              aspect-ratio: 1/1;
            }
            .flexHeader {
              padding: 10px 0;
            }
          }
        }
      }
      .closeModalBtn {
        width: fit-content !important;
        left: 0;
        background: transparent !important;
        box-shadow: none !important;
        svg {
          color: #555 !important;
        }
      }
      form {
        position: relative;
        padding: 0;
      }
      .flexHeader {
        margin: 0 !important;
        padding: 10px 0;
      }
    }
    .modalBackground {
      padding: 0;
    }
  }
  .filledDay {
    position: relative;
    width: 41px;
    height: 34px;
    z-index: 6;
    .emptyButton {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .calendarHeader {
    margin-bottom: -5px;
    .flexBox {
      margin: 0;
      .modalFull {
        width: 36px;
        color: #777;
      }
    }
    .calendarDays {
      box-shadow: none;
      font-size: 11px;
    }

    // .modalFull:nth-child(6) {
    //   color: #FF6900;
    // }
    .modalFull:nth-child(7) {
      color: #ff6900;
    }
  }
  .disabledBtn {
    pointer-events: none;
  }
  .flexBox {
    margin-bottom: 5px;
  }
  .flexHeader {
    button:disabled {
      color: #aaa;
    }

    .flexHeaderBtn {
      font-size: 15px;
      background: #f5f5f5;
      color: #999;
      border: none;
      border-radius: 50px;
      padding: 2px 10px;
      transition: 0.35s;
      &:disabled {
        opacity: 0.4;
        transition: 0.35s;
      }
    }
    button {
      background: transparent;
      color: #1a1b1c;
      padding: 0;
      margin: 0;
      font-size: 18px;
      svg {
        display: block;
      }
    }
  }
}

.calendarDays {
  height: fit-content;
  background: #fff;
  text-align: center;
  position: relative;
  border-radius: 8px;
  margin: 0 0 8px 0;
  display: block;
  font-size: 12px !important;
  font-weight: 600;
  border: 0;
  padding: 0;
  width: 36px;
  p {
    margin: 0;
    padding: 0;
  }
  .calendarDayProgress {
    background: #ddd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: fit-content;
    width: fit-content;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 50px;

    button:disabled {
      transform: scale(0);
    }
    button {
      width: 10px;
      height: 4px;
      background: transparent;
      border: none;
      padding: 0;
    }
    button:nth-child(1) {
      background: #c5e90b;
    }
    button:nth-child(2) {
      background: #86dc3d;
    }
    button:nth-child(3) {
      background: green;
    }
  }
}

.menuAdder {
  box-sizing: border-box;
  height: 36px;
  width: 36px;
  position: relative;
  border-radius: 5px;
  .dateDay {
    width: 36px;
    height: 100%;
    margin: 0;
    padding: 0;
    background: transparent;
    color: #777;
    border-radius: 50%;
  }
  .dateDay:disabled {
    opacity: 0.3;
  }
  .dateDay:hover,
  .active {
    .dayNumber {
      color: #ff6900;
      transition: 0.35s linear;
    }
  }
  .dayNumber {
    z-index: 3;
    position: relative;
    transition: 0.35s linear;
    color: #777;
    &.empty {
      display: block;
      width: 19px;
      height: 19px;
      background: #eee;
      border-radius: 50%;
      margin: 0 auto;
    }
  }
  .backdropTransparent {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    background: transparent;
    transform: translate(0);
    z-index: 7;
  }
  button:disabled {
    filter: grayscale(1);
    color: #999;
  }
  .filledDay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background: #fff;
    text-align: center;
    color: #333;
    z-index: 6;
    &.addedDay {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 7;
      .dayNumber {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 5;
        background: rgba(90, 200, 251, 0.3);
        border-radius: 50px;
        width: 26px;
        display: block;
        align-items: center;
        text-align: center;
        padding: 2px 0;
      }
    }
  }

  .flexProgress {
    .progressMiddle {
      position: absolute;
      top: 2px;
      left: 2px;
      width: 32px;
      height: 32px;
      background: #fff;
      z-index: 2;
      border-radius: 50%;
    }
    .progressFilled,
    .progressEmpty {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 36px;
      height: 36px;
      border-radius: 50%;
    }
    .progressFilled {
      &.breakfast {
        background: conic-gradient(
          from 0deg at 50% 50%,
          #c5e90b 2%,
          #c5e90b 21.55%,
          transparent 21.55%,
          transparent 100%
        );
      }
      &.lunch {
        background: conic-gradient(
          from 0deg at 50% 50%,
          transparent 23.55%,
          #86dc3d 23.55%,
          #86dc3d 43.1%,
          transparent 43.1%,
          transparent 100%
        );
      }
      &.dinner {
        background: conic-gradient(
          from 0deg at 50% 50%,
          transparent 45.1%,
          #378805 45.1%,
          #378805 64.65%,
          transparent 64.65%,
          transparent 100%
        );
      }
      &.snack {
        background: conic-gradient(
          from 0deg at 50% 50%,
          transparent 66.65%,
          #5855d6 66.65%,
          #5855d6 75.65%,
          transparent 75.65%,
          transparent 100%
        );
      }
      &.baby {
        background: conic-gradient(
          from 0deg at 50% 50%,
          transparent 77.65%,
          #5ac8fb 77.65%,
          #5ac8fb 86.65%,
          transparent 86.65%,
          transparent 100%
        );
      }
      &.pet {
        background: conic-gradient(
          from 0deg at 50% 50%,
          transparent 88.65%,
          #ffcc00 88.65%,
          #ffcc00 98%,
          transparent 98%,
          transparent 100%
        );
      }
    }
    .progressEmpty {
      &.breakfast {
        background: conic-gradient(
          from 0deg at 50% 50%,
          #eee 2%,
          #eee 21.55%,
          transparent 21.55%,
          transparent 100%
        );
      }
      &.lunch {
        background: conic-gradient(
          from 0deg at 50% 50%,
          transparent 23.55%,
          #ddd 23.55%,
          #ddd 43.1%,
          transparent 43.1%,
          transparent 100%
        );
      }
      &.dinner {
        background: conic-gradient(
          from 0deg at 50% 50%,
          transparent 45.1%,
          #ccc 45.1%,
          #ccc 64.65%,
          transparent 64.65%,
          transparent 100%
        );
      }
      &.snack {
        background: conic-gradient(
          from 0deg at 50% 50%,
          transparent 66.65%,
          #bbb 66.65%,
          #bbb 75.65%,
          transparent 75.65%,
          transparent 100%
        );
      }
      &.baby {
        background: conic-gradient(
          from 0deg at 50% 50%,
          transparent 77.65%,
          #aaa 77.65%,
          #aaa 86.65%,
          transparent 86.65%,
          transparent 100%
        );
      }
      &.pet {
        background: conic-gradient(
          from 0deg at 50% 50%,
          transparent 88.65%,
          #999 88.65%,
          #999 98%,
          transparent 98%,
          transparent 100%
        );
      }
    }
  }
  .specialBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
  }
  .modal {
    top: 0;
    left: 0;
    width: 100%;
    padding: 0;
    overflow-y: scroll;
    height: fit-content;
    padding: none !important;
    &.skeletonLoader {
      .flexShare .flexShareBtn small {
        min-width: 40px;
        min-height: 14px;
        border-radius: 50px;
        background: #f5f5f5;
        display: block;
      }
      .menuHeaderSkeleton {
        min-width: 150px;
        min-height: 26px;
        display: block;
        background: #f5f5f5;
        border-radius: 50px;
      }
      .dateHeaderSkeleton {
        min-width: 106px;
        min-height: 26px;
        display: block;
        background: #f5f5f5;
        border-radius: 50px;
      }
      .progressContainer .progressCover h3 {
        min-width: 19px;
        min-height: 17px;
        display: block;
        background: #f5f5f5;
        border-radius: 50px;
      }
      .caloriesSkeleton {
        min-width: 45px;
        min-height: 15px;
        display: inline-block;
        background: #f5f5f5;
        border-radius: 50px;
      }
      .caloriesSkeletonNumber {
        min-width: 24px;
        min-height: 32px;
        display: inline-block;
        background: #f5f5f5;
        border-radius: 50px;
      }
      .flexCalendarFix {
        height: fit-content !important;
        span {
          background: #fff;
          width: 50px;
          height: 22px;
          border-radius: 50px;
        }
        button {
          background: #fff;
          width: 22px;
          height: 22px;
          border-radius: 50px;
        }
      }
    }
    .modalBackground {
      background: #fff !important;
      z-index: 9;
      overflow-y: scroll;
      box-sizing: border-box;
      height: fit-content;
      padding: 0;
      background: #fff;
      .btnPrimary:disabled {
        display: block;
        opacity: 0.5;
      }
      button:disabled {
        display: none;
      }
      .card {
        width: 100%;
        img {
          pointer-events: initial;
          min-width: calc(100% - 10px);
          min-height: calc(100% - 10px);
        }
        .flexHeader {
          padding: 10px 0;
        }
      }
    }
    .modal {
      height: fit-content;
      overflow-y: scroll;
      top: 0;
      left: 0;
      border-radius: 20px;
      padding: 0;
      z-index: 9;
      .closeModalBtn {
        width: fit-content !important;
        left: 0;
        background: transparent !important;
        box-shadow: none !important;
        svg {
          color: #555 !important;
        }
      }
      .modalBackground {
        position: fixed;
        top: 0;
        left: 0;
        background: #fff !important;
        z-index: 9;
        overflow-y: scroll;
        overflow-x: visible;
        box-sizing: border-box;
        height: fit-content;
        background: #fff;
        .btnPrimary:disabled {
          display: block;
          opacity: 0.5;
        }
        button:disabled {
          display: none;
        }
        .card {
          width: 100%;
          img {
            pointer-events: initial;
            min-width: calc(100% - 10px);
            min-height: calc(100% - 10px);
          }
          .flexHeader {
            padding: 10px 0;
          }
        }
      }
    }
    .flexHeader {
      background: #fff;
      padding: 10px;
      border-radius: 20px;
      margin: 8px 0;
      position: relative;
      overflow: hidden;
      span {
        position: relative;
        z-index: 2;
      }
      img {
        width: 65px;
        height: 65px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
    }
    .card {
      width: calc(100% - 10px);
      border: none;
      margin: 10px 0;
      padding: 0;
      overflow: hidden !important;
      .pageLeft {
        border-radius: 20px;
        display: block;
        z-index: 5;
      }
    }
  }

  .card {
    padding: 5px;
    margin: 20px 0;
    box-sizing: border-box;
    .flexHeader {
      padding: 10px;
      border-radius: 20px;
      font-size: 18px;
      span {
        font-size: 18px;
      }
    }
    .recipePageIngredient {
      padding: 5px 10px;
    }
    .cardFlex {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding: 5px;
    }
  }
  .addRecipeButton {
    background: #ff6900;
    padding: 5px 5px 5px 5px;
    margin: 0;
    color: #fff;
    width: fit-content;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-radius: 50px;
    font-weight: 600;
    span {
      color: #fff;
      background: transparent;
      border: none;
      display: block;
      svg {
        color: #fff;
        font-size: 23px;
        display: block;
        background: #ff6900;
        border-radius: 50%;
        padding: 2px;
        box-sizing: border-box;
      }
    }
  }
}

.flexCalendar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.35s;
  &.wrap {
    flex-wrap: wrap;
    justify-content: space-between;
    transition: 0.35s;
    .modalNotify {
      width: 100%;
      .modalCenter {
        box-shadow: 0 2px 6px -2px #999;
      }
    }
  }
  &.monthly {
    transition: 0.35s;
    display: grid;
    grid-template-columns: repeat(7, 36px);
    .menuAdder {
      width: 36px !important;
      margin: 2px 0;
    }
    .modal {
      top: 280px !important;
      min-height: calc(100vh - 400px) !important;
      max-height: calc(100vh - 400px) !important;
      height: calc(100vh - 400px) !important;
    }
  }
  .modal {
    top: 50px !important;
    transition: 0.35s;
  }
  .filledDay,
  .dateDay {
    border: none;
  }
  .filledDay:disabled {
    opacity: 0.3;
    .progressBreakfast {
      filter: grayscale(1);
    }
    .progressLunch {
      filter: grayscale(1);
    }
    .progressDinner {
      filter: grayscale(1);
    }
  }
}

.flexCalendarFix {
  background: #f5f5f5;
  margin: 5px 0;
  border-radius: 30px;
  padding: 10px;
  .tabButtonPrimary {
    margin: 0 8px 0 0 !important;
    h3 {
      color: #777 !important;
    }
    &.active {
      h3 {
        color: #ff6900 !important;
      }
    }
  }
  .flexHeader {
    background: transparent !important;
  }
  .flexCalendar {
    width: 100%;
    .flexCalendarScroll {
      min-width: 100%;
      width: fit-content;
      padding: 0;
      box-sizing: border-box;
      .addedRecipe {
        width: 100%;
        padding: 0px;
        border-radius: 50px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        overflow: hidden;
        text-align: left;
        margin: 4px 4px 0 0;
        background: #f5f5f5;
        img {
          width: 54px !important;
          height: 54px !important;
          min-width: 54px !important;
          min-height: 54px !important;
          border-radius: 50%;
          object-fit: cover;
          margin: 5px !important;
        }
        p {
          width: calc(100% - 100px);
          margin-left: 5px;
          color: #555;
          font-weight: 600;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          line-clamp: 2;
        }
        .addBtn {
          svg {
            background: #86dc3d;
          }
        }

        button {
          color: #fff;
          background: transparent;
          border: none;
          margin-left: auto;
          svg {
            display: block;
            background: #ff6900;
            border-radius: 50%;
            padding: 5px;
          }
        }
      }
    }
  }
}
