//FORM
form {
  width: 100%;
  margin: 0 auto;
  h2 {
    text-align: center;
  }
  p {
    text-align: center;
  }
  .sponsoreInput {
    input:disabled {
      display: block !important;
      background: transparent;
      opacity: 0.5;
    }
  }
  input[type="radio"]:disabled + label {
    display: block;
    opacity: 0.3;
  }
}
//INPUT
::placeholder {
  font-family: "Montserrat";
  color: #777;
}
input {
  background: transparent;
  font-family: "Montserrat";
  border-radius: 10px;
  outline: none;
  color: #777;
  font-size: 16px;
  transition: 0.35s;
  font-weight: 500;
  transition: 0.35s;
}
@keyframes wobbleAnim {
  0% {
    margin-left: -12px;
  }
  25% {
    margin-left: 12px;
  }
  50% {
    margin-left: -12px;
  }
  75% {
    margin-left: 12px;
  }
  100% {
    margin-left: -12px;
  }
}
.input {
  position: relative;
  margin: 8px 0;
  &.error {
    input {
      border: 1px solid red !important;
      animation: wobbleAnim 0.3s linear;
    }
    label {
      color: red !important;
    }
  }
  .inputTextIcon ~ input {
    padding: 12px 12px 12px 50px;
  }
  &.filled {
    .inputLabel {
      top: -6px;
      transform: translateY(0);
    }
  }
  &.phone {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // select {
    //   max-width: 90px;
    //   font-weight: 600;
    // }
    .input:first-of-type {
      width: 90px !important;
    }
    .input:last-of-type {
      width: calc(100% - 94px) !important;
    }
  }
  .inputTextIcon {
    position: absolute;
    top: 6px;
    left: 3px;
    height: 40px;
    width: 40px;
    display: block;
    border-radius: 10px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 1px solid #999;
    svg {
      color: #777;
      height: 100%;
    }
  }
  .inputRequiredIcon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
    color: #ff6900;
    font-size: 20px;
    transition: 0.35s;
  }
  input:focus ~ .inputRequiredIcon {
    opacity: 0;
    transition: 0.35s;
  }

  input:valid ~ .inputRequiredIcon {
    opacity: 0;
    transition: 0.35s;
  }
  textarea:valid ~ .inputRequiredIcon {
    opacity: 0;
    transition: 0.35s;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}
.inputText:focus {
  background: transparent;
  border: 1px solid #555;
  color: #242424;
}

.inputText {
  width: 100%;
  padding: 16px 12px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  margin: 3px 0;
  &.hidden {
    display: none;
  }
}
.password {
  svg {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translate(-5px, -50%);
    font-size: 25px;
    padding: 0;
    color: #ccc;
    z-index: 3;
    transition: 0.5s;
  }
}
.inputLabel {
  position: absolute;
  top: -6px;
  left: 8px;
  width: fit-content;
  padding: 0 4px;
  background: #fff;
  margin: 0;
  transition: 0.3s;
  font-size: 14px;
  border-radius: 4px;
  // transform: translateY(-50%);
  &:focus {
    top: 0;
    transform: translateY(0);
  }
  &.labelImage {
    width: 100% !important;
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f9f9f9;
    position: relative;
    left: 0 !important;
    border-radius: 20px;
    padding: 0;
    border: 2px solid #f5f5f5;
    img {
      object-fit: cover !important;
      height: 100%;
    }
    svg {
      font-size: 50px;
      color: #999;
    }
    span {
      text-align: center;
    }
  }
}
input[type="checkbox"] {
  display: none;
}
.sliderPrivacy {
  svg:last-of-type {
    color: #1a1b1c;
  }
}
input ~ .inputLabel {
  width: fit-content;
  margin: 0 auto;
  text-align: left;
  font-weight: 600;
  color: #999;
}
input {
  &:focus {
    .inputLabel {
      top: -6px;
      left: 8px;
      color: #555 !important;
      font-weight: 600;
      width: fit-content;
      transform: translate(0);
    }
  }
}

input:valid ~ .inputLabel {
  top: -6px;
  left: 8px !important;
  font-weight: 600;
  width: fit-content;
  transform: translate(0);
  &.labelImage {
    left: 0 !important;
  }
}

.authError {
  background: #fce205;
  border-radius: 50px;
  font-size: 11px;
  width: fit-content;
  margin: 0 auto;
  padding: 5px 20px;
  p {
    margin: 0;
    color: #333 !important;
    font-weight: 600;
  }
}
select {
  position: relative;
  background: transparent;
  border-radius: 10px;
  color: #777;
  width: 100%;
  border: 1px solid #ccc;
  transition: 0.35s;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: "Montserrat";
  font-size: 14px;
  padding: 16.5px;
  outline: none !important;
}
select:focus:valid {
  border: 1px solid #555;
  color: #555;
}
select:focus {
  border: 1px solid #555;
  color: #555;
  outline: none !important;
}
select:valid ~ .inputRequiredIcon {
  font-size: 0;
}
select ~ .inputLabel {
  width: fit-content;
  margin: 0 auto;
  text-align: left;
  font-weight: 600;
  color: #999;
}
select:focus ~ .inputLabel {
  top: -6px;
  left: 8px;
  color: #555 !important;
  font-weight: 600;
  width: fit-content;
  transform: translate(0);
}
select:valid ~ .inputLabel {
  top: -6px;
  left: 8px !important;
  // color: #555 !important;
  font-weight: 600;
  width: fit-content;
  transform: translate(0);
}
select:focus:valid ~ .inputLabel {
  top: -6px;
  left: 8px;
  color: #555 !important;
  font-weight: 600;
  width: fit-content;
  transform: translate(0);
}
textarea {
  background: #fff;
  font-family: "Montserrat";
  font-size: 14px;
  border-radius: 10px;
  border: 1px solid #555;
  outline: none;
  color: #555;
  width: 100%;
  resize: none;
  min-height: 100px;
  max-height: 100%;
  padding: 10px;
  box-sizing: border-box;
}
textarea:valid {
  border: 1px solid #555;
}
textarea:focus:valid {
  border: 1px solid #555;
}
textarea:focus {
  border: 1px solid #555;
}
textarea ~ .inputLabel {
  position: absolute;
  top: -6px;
  left: 8px;
  width: fit-content;
  padding: 0 4px;
  background: #fff;
  margin: 0;
  transition: 0.3s;
  font-size: 14px;
  font-weight: 600;
  color: #999;
  margin: 0 auto;
  text-align: left;
  transform: translate(0);
}
.inputSearch {
  position: relative;
  width: 100%;
  input {
    width: 100%;
    padding: 12px 8px 12px 42px;
    box-sizing: border-box;
    background: #f5f5f5;
    border: none;
  }
  input ~ .inputLabel {
    position: absolute;
    top: 50%;
    left: 40px;
    transform: translate(0, -50%);
    background: #fff;
  }
  input:focus ~ .inputLabel {
    position: absolute;
    top: -6px;
    left: 40px;
    background: #fff;
  }
  input:valid ~ .inputLabel {
    position: absolute;
    top: -6px;
    left: 40px;
    background: #fff;
  }
  svg {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 42px;
    height: 100%;
    padding: 8px 8px 8px 12px;
    box-sizing: border-box;
    color: #999;
  }
}
.tabButtonsFlex {
  background: transparent;
  border: none;
  color: #333;
  &--inactive {
    color: #999;
  }
}
.authTerms {
  color: #999;
  font-size: 13px;
  margin: 8px 0;
}
.customCheckbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  width: 23px;
  height: 23px;
  input {
    display: none;
  }
  .checkbox {
    width: 100%;
    height: 100%;
    transition: background-color 0.3s ease, transform 0.2s ease;
    background-color: transparent;
    border: 2px solid #ddd;
    border-radius: 30%;
    transition: 0.35s linear;
    box-sizing: border-box;
    &.checked {
      background-color: #f9f9f9;
      animation: pop 0.35s linear;
      margin: 0;
      padding: 0;
    }
    &::after {
      content: "\2713";
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #ddd;
      font-size: 19px;
      padding: 1px 0 0 0;
      transition: 0.35s linear;
    }
  }
  input:checked + .checkbox {
    background-color: #f9f9f9;
    border: 2px solid #4caf50;
    border-radius: 30%;
    transition: 0.35s linear;
    &::after {
      opacity: 1;
      color: #4caf50;
      transition: 0.35s linear;
    }
  }
}
.required {
  .inputLabel:after {
    content: "*";
  }
}
@keyframes pop {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}
.inputText:focus {
  background: transparent;
  border: 1px solid #555;
}
input:focus ~ .inputLabel {
  color: #555 !important;
}
.input {
  &.filled {
    .inputText:focus:valid {
      background: transparent;
      border: 1px solid #4caf50;
    }
    .inputText:focus:valid ~ .inputLabel {
      top: -6px;
      left: 8px;
      color: #4caf50 !important;
      font-weight: 600;
      width: fit-content;
      transform: translate(0);
    }
  }
}
