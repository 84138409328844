.darkTheme {
  body,
  .App,
  html,
  #root {
    background: #333 !important;
  }
  .btnPrimary {
    border: 2px solid #fff;
    color: #1a1b1c;
    background: #fff;
  }
  .btnSecondary {
    border: 2px solid #fff;
    color: #fff;
  }
  .btnTertiary {
    color: #fff;
  }
  .transparentButton {
    background: transparent !important;
    color: #fff;
  }
  .languageSelector {
    .fi {
      box-shadow: 0 3px 6px #252525;
    }
  }
  .btnSecondary {
    background: #333;
  }
  .btnTertiary {
    background: #333;
  }
  .carrouselItemText {
    background: #252525;
  }
  .homepage .homepageCenter h1 {
    color: #fff;
  }
  .userDetails {
    .userDetailsName {
      color: #fff !important;
    }
    .userDetailsWebsite {
      color: #ccc;
    }
  }
  .flexHeader {
    .flexHeaderSpan {
      img {
        background: transparent;
      }
    }
    &.iconBlack {
      button {
        background: transparent;
      }
    }
  }
  .flexHeaderNoSpace {
    .pageBackBtn {
      color: #fff;
    }
  }
  .btnList {
    color: #fff;
  }
  .toastNotification {
    background: #252525;
    box-shadow: 0 2px 5px -2px #111;
  }
  .navSecondary {
    box-shadow: 0 16px 15px 15px rgba(51, 51, 51, 1);
    background: #333 !important;
    &.transparent {
      background: transparent !important;
      box-shadow: none;
    }
    &.image {
      margin-bottom: 10px !important;
      h1 {
        color: #fff !important;
        background: linear-gradient(transparent, #333) !important;
        .userHeaderBtn {
          background: #252525 !important;
        }
      }
    }
  }
  .buttonBack {
    background: rgba(0, 0, 0, 0.3);
  }
  input ~ .inputLabel {
    color: #eee !important;
  }
  input:focus ~ .inputLabel {
    color: #fff !important;
  }
  input:focus {
    border: 1px solid #fff !important;
    color: #fff !important;
  }

  input:valid {
    border: 1px solid #fff !important;
    color: #fff !important;
  }
  input:valid ~ .inputLabel {
    color: #fff !important;
  }
  input {
    border: 1px solid #555 !important;
    color: #fff !important;
  }

  select {
    background: #333 !important;
  }
  select ~ .inputLabel {
    color: #eee !important;
  }
  select:focus ~ .inputLabel {
    color: #fff !important;
  }
  select:focus {
    border: 1px solid #fff !important;
    color: #fff !important;
  }
  select {
    border: 1px solid #555 !important;
    color: #777 !important;
  }
  select:valid {
    border: 1px solid #fff !important;
    color: #fff !important;
  }
  select:valid ~ .inputLabel {
    color: #fff !important;
  }
  textarea ~ .inputLabel {
    color: #999 !important;
  }
  textarea:focus ~ .inputLabel {
    color: #fff !important;
  }
  textarea:focus {
    border: 1px solid #fff !important;
    color: #fff !important;
  }
  textarea {
    border: 1px solid #555 !important;
    color: #fff !important;
    background: #333;
  }
  textarea:valid {
    border: 1px solid #fff !important;
    color: #fff !important;
  }
  textarea:valid ~ .inputLabel {
    color: #fff !important;
  }
  ::placeholder {
    color: #999;
  }
  .btnsAuthTab {
    border-bottom: 2px solid #252525;
  }
  .swiperFeatures {
    .swiper-slide {
      button {
        background: #252525;
        small {
          color: #ddd;
        }
      }
    }
  }
  .cardClaim {
    button {
      color: #fff;
    }
  }
  .homepage {
    background: #333;
    .homepageCenter {
      .cardHomepage {
        h2 {
          color: #fff !important;
        }
        h3 {
          color: #ddd;
        }
        .homepageButtons {
          .btnPrimary {
            background: #fff !important;
            border: 2px solid #fff !important;
            color: #252525 !important;
          }
          .btnSecondary {
            border: 2px solid #fff !important;
            color: #fff;
          }
        }
      }
    }
  }
  .cardMeshGradient {
    p,
    button {
      color: #fff;
    }
  }
  .adviceAdd {
    box-shadow: 0 2px 8px -2px #111;
  }
  input {
    color: #fff !important;
  }
  .dayHeader {
    background: #333;
  }
  .dashboard {
    background: #333;
  }
  .modalNotify {
    .modalCenter {
      background: #333;
    }
  }
  .ingredientImage {
    background: #252525;
  }
  .modalTooltip:hover.active {
    .modalButton:hover.active {
      border: none !important;
    }
  }
  .modalTooltip {
    background: transparent;
    .pointer {
      background: #111;
    }
    button:disabled {
      color: #333;
    }
    button {
      color: #fff;
      svg {
        color: #fff;
      }
    }
    .modalFullContent {
      background: #252525 !important;
      h5 {
        margin: 10px 5px 10px 0;
        text-align: left;
      }
      .btnLanguage:disabled {
        color: #777;
      }
      .btnLanguage {
        color: #fff;
      }
      a {
        button {
          color: #fff;
        }
      }
      a:hover,
      .active {
        button {
          color: #777;
        }
      }
    }
  }
  table {
    .recipeSelect {
      .recipeSelector {
        color: #fff;
      }
    }
  }
  .checkboxCardBox {
    button {
      color: #fff;
    }
  }
  .recipes {
    .recipe {
      button {
        background: #252525 !important;
      }
      .recipeItems {
        div {
          background: #252525;
          h3 {
            color: #fff;
          }
        }
      }
    }
  }
  .flexHeader {
    color: #fff;
    .modalTooltip {
      border: none !important;
      .modalFullContent {
        background: #252525 !important;
        a {
          button {
            color: #fff;
          }
        }
        a:hover,
        .active {
          button {
            color: #999;
          }
        }
      }
    }
  }

  .flexHeaderSpan {
    color: #fff;
  }
  .flexHeader button {
    background-color: #252525;
    color: #fff;
  }
  .flexHeader .infoButtonTransparent {
    background: transparent;
    svg {
      display: block;
    }
  }
  .tabButtonLarge--inactive {
    color: #fff !important;
    // transition: 0.35s linear;
  }
  .tabButtonLarge {
    color: red;
    // transition: 0.35s linear;
  }
  .tabButtonsSecondary {
    border-bottom: 2px solid #252525;
    .tabButtonSmall--inactive {
      border-bottom: 2px solid #252525 !important;
      // transition: 0.35s linear;
      color: #aaa;
    }
    .tabButtonSmall:hover,
    .active {
      border-bottom: 2px solid #ff6900 !important;
      // transition: 0.35s linear;
    }
  }

  .carrouselItemAccountsEmpty {
    border: 1px solid #777;
    min-width: 122px;
    width: 122px;
    span {
      background: #252525 !important;
    }
    h3 {
      background: #252525 !important;
    }
    p {
      background: #252525 !important;
    }
    button {
      background: #252525 !important;
    }
  }

  .carrouselItemAccounts {
    background: #333 !important;
    border: 1px solid #777 !important;
    img {
      background: #333 !important;
      box-shadow: 0 2px 4px -1px #252525 !important;
    }
    .followBtn {
      border: 1.5px solid #ff6900 !important;
    }
  }
  table {
    background: #333;
  }
  #myInput {
    background: #252525;
    color: #fff;
  }
  .inputLabel {
    background: #333;
  }

  .flexRecipe {
    background: #252525;
    span {
      label {
        color: #fff;
      }
    }
  }
  .pageSwiper .swiper .swiper-slide {
    .cardPreferences {
      background: #252525;
      color: #fff;
    }
  }
  .flex {
    input {
      color: #fff;
    }
    ::placeholder {
      color: #fff;
    }
    svg {
      color: #fff;
    }
    button {
      background: #252525;
    }
  }
  .buttonAccount {
    box-shadow: 0 2px 4px -1px #111 !important;
    background: #333 !important;
    &.admin {
      background: #252525 !important;
    }
  }
  .calendarDays {
    background: #333;
    box-shadow: 0 2px 4px -1px #111;
    color: #fff;
  }

  .calendar {
    .calendarDayProgress {
      background: #555 !important;
    }
    .calendarHeader {
      .modalFull:nth-child(6) {
        .calendarDays {
          color: #ff6900 !important;
        }
      }
    }
  }
  .calendar {
    .calendarHeader {
      .modalFull:nth-child(7) {
        .calendarDays {
          color: #ff6900 !important;
        }
      }
    }
  }
  .flexRadioSmall .checkboxCardBox span p {
    color: #fff !important;
  }
  .flexRadioSmall .checkboxCardBoxCuisine span p {
    color: #fff !important;
  }
  .flexRecipe {
    span {
      background: #252525;
    }
  }
  .checkboxCardBox {
    p {
      color: #fff !important;
    }
  }
  .flexRecipeSecondary {
    span {
      background: #252525;
      color: #fff;
    }
  }
  .commentInfo {
    span {
      color: #fff;
    }
  }
  .centeredPlaceholder {
    .chatbot,
    .textPreMade,
    .textPreMadeCard p {
      color: #fff !important;
    }
    small {
      color: #ddd;
    }
  }
  .small {
    color: #fff;
  }
  .addRecipeForm {
    &.foodgpt {
      background: #333;
      .btnPRimary,
      .btnTertiary {
        background: #252525;
        svg {
          color: #fff;
        }
      }
    }
    img {
      background: #252525 !important;
    }
    select:invalid {
      background: rgba(255, 105, 0, 0.1) !important;
    }
    textarea {
      color: #fff;
    }
    .ingredientsButton:nth-child(even) {
      background: #252525;
      color: #fff;
    }
    .ingredientsButton {
      background: #333;
      color: #fff;
    }
  }

  .flexButton {
    button {
      color: #fff !important;
      background: transparent;
      &:nth-child(2) {
        color: #ff6900 !important;
      }
    }
  }
  .flexInput {
    border-bottom: 1px solid #111;
  }
  .flexInput:last-of-type {
    margin-bottom: 10px !important;
  }
  .progressChart {
    background: #252525;
  }
  .progressCover {
    background: #333;
  }
  .calorieCounter {
    background: #333 !important;
    small {
      color: #fff !important;
      // span {
      //   color:#fff !important;
      // }
    }
  }
  .selectedCounter {
    .emptyStat {
      background: conic-gradient(#252525, #252525) !important;
    }
    .middleStat {
      background: #333 !important;
    }
  }

  .menuAdder {
    .modal {
      .flexHeader {
        button {
          background: transparent;
          svg {
            color: #fff;
          }
        }
      }
      .modalBackground {
        background: #333 !important;
      }
    }
    .progressMiddle {
      background: #333;
    }
    .flexCalendarFix {
      background-color: #252525;
      svg {
        color: #fff;
      }
    }
    .flexHeader {
      background: transparent;
    }
    .dateDay {
      color: #fff;
    }
    .dateDay:disabled {
      opacity: 0.3;
    }
    .filledDayAdded:disabled {
      opacity: 0.3;
    }
    .filledDay:disabled {
      opacity: 0.3;
    }
    .filledDayAdded {
      background: #333 !important;
      color: #fff;
      .modalTooltip {
        background: #111;
        button:disabled {
          color: #777;
        }
      }
    }
    .filledDay {
      background: #333 !important;
      color: #fff;
      .modalTooltip {
        background: #111;
      }
    }
    .modal {
      .card {
        .pageLeft {
          .accordion:hover,
          .active {
            border: none !important;
          }
          .accordionButton {
            color: #fff !important;
          }
        }
      }
    }
  }

  .flexRecipe span {
    color: #fff;
  }
  .recipePageIngredient {
    background: #252525;
  }
  .recipePageIngredient:nth-child(odd) {
    background: #333;
  }
  .dashboard {
    label {
      background: transparent;
    }
  }
  .modalBottom {
    background: #333;
    .fixedButton {
      background: #333;
      border: 1px solid #333;
    }
  }
  .addButton {
    box-shadow: 0 3px 8px -2px #000;
  }
  .card {
    background: #333;
    border: 2px solid #252525 !important;
    &.recipe {
      background: #252525 !important;
      .ctaButtons {
        button {
          background: transparent !important;
        }
      }
    }
    &.pricing {
      border: none !important;
    }
    &.padding {
      background: #252525;
      label {
        background: #252525;
      }
    }
    &.feedback {
      small {
        color: #999;
      }
    }
    img {
      background: #555;
    }
    .buttonFav,
    .buttonEdit {
      background: #333;
      svg {
        color: #fff !important;
      }
    }
    .pageLeft {
      background: #252525;
      .recipeName {
        color: #fff !important;
      }
      .recipeCategory {
        color: #fff !important;
      }
      .recipeAuthor {
        color: #fff !important;
        small {
          background: #1a1b1c;
        }
      }
    }
  }
  .copyright {
    span {
      color: #fff;
      span {
        color: #ff6900;
      }
    }
    .version {
      color: #ccc;
    }
  }
  .modal {
    background: #333;
  }
  .navBottom {
    box-shadow: none !important;
    background: #252525;
    box-shadow: 0 20px 25px -20px #000 !important;
    a {
      button {
        svg {
          color: #999 !important;
          transition: 0.35s linear;
        }
      }
    }
    a:hover,
    .active {
      button {
        svg {
          color: #ff6900 !important;
          transition: 0.35s linear;
        }
      }
    }
  }
  nav {
    background: #333;
    box-shadow: 0 16px 15px 15px rgba(51, 51, 51, 1);
    .logo {
      color: #fff;
    }
    .navButtons {
      a {
        .navTopBtn {
          img {
            border: 2px solid #fff;
          }
        }
      }
      a:hover,
      .active,
      .focus {
        .navTopBtn {
          background: #ff6900;
          // transition: 0.35s;
          svg {
            color: #ff6900;
            background: #333;
          }
          img {
            border: 2px solid #ff6900;
          }
        }
      }
      a:first-of-type {
        margin: 0 8px;
      }
      a:hover,
      .active {
        .navTopBtn {
          background: #ff6900 !important;
          svg {
            color: #ff6900 !important;
          }
        }
        .navTopBtn:hover,
        .active {
          background: transparent !important;
          svg {
            color: #ff6900 !important;
          }
        }
      }
      .navTopBtn:hover,
      .active {
        background: #ff6900 !important;
        svg {
          color: #ff6900 !important;
        }
      }
      .navTopBtn:first-of-type {
        padding: 0;
        border-radius: 12px;
        svg {
          box-sizing: border-box;
          padding: 4px;
          margin: 2px;
          color: #fff;
          background: #333;
          width: 26px;
          height: 26px;
        }
      }
    }
  }
  .btnEditSmall {
    color: #eee !important;
  }
  .flexShare .flexShareBtn small {
    color: #eee;
    letter-spacing: 0.7px;
  }
  .flexShare .flexShareBtn svg {
    background: #252525 !important;
    color: #fff;
  }
  .qrCodeImage[src] {
    box-shadow: 0 4px 6px -1px #111;
  }
  .userDetailsBio {
    color: #eee;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  label,
  li {
    color: #fff;
  }
  .userStats {
    button {
      background: transparent;
      color: #fff;
      span {
        color: #fff;
      }
    }
  }

  .profileImg {
    background-color: #252525;
    border: 2px solid #252525;
  }
  .btnEditSmall {
    background: #252525;
    border: 2px solid #252525;
  }
  .listUsers {
    background: #252525 !important;
    .displayName {
      color: #fff !important;
    }
  }
  .accordionMethod {
    .accordionButton {
      background: linear-gradient(transparent, #333);
    }
  }
  .cardMeshGradient {
    button:disabled {
      background: #393939;
      color: #1a1b1c;
    }
    select {
      border: 1px solid #fff;
      color: #fff;
      background: #333;
      width: fit-content;
    }
  }
  .flexRecipe {
    select {
      background: transparent !important;
      border: none !important;
      width: 100%;
      option {
        background: #333;
      }
    }
  }
  #recipeImageLabel {
    background: #252525;
  }
  .btnLanguage {
    color: #fff;
  }
  .socialButtons {
    svg {
      color: #fff !important;
    }
  }
  .userRecipes {
    border-top: 2px solid #111;
    .card {
      border: none !important;
      background: #1a1b1c;
    }
    a {
      &:nth-child(3n) {
        .card {
          margin-right: 0 !important;
        }
      }
    }
    a {
      img {
        border-radius: 0;
        object-fit: cover;
        min-width: 33.333vw !important;
        min-height: 33.333vw !important;
        width: 33.333vw !important;
        height: 33.333vw !important;
        background: #252525 !important;
      }
    }
  }
  .inputSearch {
    input {
      color: #fff !important;
      background: #252525 !important;
      border: none !important;
      &::placeholder {
        color: #fff !important;
      }
    }
    svg {
      color: #fff !important;
    }
  }
  .mention {
    color: #ff6900 !important;
  }
  .spinner {
    border: 5px solid #252525 !important;
    border-top: 5px solid #ff6900 !important;
  }
  .flexHeaderSpan {
    color: #fff !important;
  }
  .pageRecipeText {
    background: #333;
    .category {
      color: #ddd;
    }
  }
  .mainImage {
    background: #252525 !important;
  }
  .swiper-pagination-progressbar {
    background: #252525 !important;
  }
  .navTopBtn {
    background: #fff !important;
  }
  .roleIndicatorBox {
    img {
      border: 2px solid #333;
    }
  }
  .profileImageBox {
    img {
      border: 2px solid #333;
    }
  }

  .followBtn,
  .messageBtn {
    color: #ff6900;
  }
  .modalBottomCloseButton {
    background: #333;
  }
  .notification {
    background: #252525;
    &.empty {
      .notificationText {
        background: #333;
      }
      .notificationDate {
        background: #333;
      }
      .notificationImg {
        background: #333;
      }
    }
    &.unviewed {
      animation: notificationPulseDark 4s linear;
    }
    .roleIndicatorBox {
      img {
        border: 2px solid #252525;
      }
    }
  }
  .pageRecipeSkeleton {
    &:before {
      box-shadow: 0 0 40px 15px #333;
    }
    .pageRecipeText {
      .category,
      .name,
      .user,
      .views,
      .date,
      .timing {
        background: #111;
      }
    }
  }
  .closeModalBtn {
    background: #252525 !important;
    svg {
      color: #fff !important;
    }
  }

  .modalScanner {
    video {
      background: #252525;
    }
    .buttonScanner {
      background: rgba(0, 0, 0, 0.3) !important;
      svg {
        color: #fff;
      }
    }
  }
  .tabButtonsMain {
    .tabButtonMain {
      color: #ff6900;
    }
    .tabButtonMain--inactive {
      color: #fff;
    }
  }
  .menuAdder {
    .modal {
      &.skeletonLoader {
        .menuHeaderSkeleton {
          background: #252525 !important;
        }
        .dateHeaderSkeleton {
          background: #252525 !important;
        }
        .flexShare .flexShareBtn small {
          background: #252525;
        }
        .progressContainer .progressCover h3 {
          background: #252525;
        }
      }
    }
  }
  .tabs {
    .tabButtonPrimary {
      &.skeleton {
        h3 {
          background: #252525 !important;
        }
      }
    }
    .tabButtonSecondary {
      &.skeleton {
        background: #252525 !important;
      }
    }
  }
  .skeletonHeader {
    background: #252525 !important;
  }
  .sliderBoxEmpty {
    .carrouselItem {
      background: #252525 !important;
    }
    .carrouselItemText {
      background: #252525 !important;
    }
  }
  .productScanner {
    .imagePlaceholder {
      background: #252525;
    }
  }
  .ingredientNameBrand {
    small {
      color: #eee;
    }
  }
  .quantityUnit {
    span {
      color: #fff;
    }
    .inputLabel {
      background: #333;
      color: #fff;
    }
  }
  .carrouselBottomItem {
    img {
      background: #252525 !important;
    }
  }
  .cardWide {
    background: #252525;
  }
  .flexCalendarFix {
    background: #252525;
    .addedRecipe {
      background: #151515 !important;
      p {
        color: #fff !important;
      }
    }
  }
  .tabButtonPrimary {
    &.active {
      h3 {
        color: #ff6900 !important;
      }
    }
  }
  .flexHeaderBtn {
    background: #252525 !important;
    color: #fff;
  }
  .onlineIndicator {
    border: 3px solid #252525 !important;
  }
  .filtersBtn {
    background: #252525;
  }
  .cardList {
    background: #252525;
    .onlineIndicator {
      bottom: -8px !important;
    }
    .text {
      span:last-of-type {
        color: #fff;
      }
    }
  }
  .addImageContainer {
    label {
      background: #252525;
    }
  }
  .textFlexLeft {
    svg {
      border: 2px solid #fff;
    }
    small {
      color: #bbb;
    }
    strong {
      color: #fff;
    }
  }
  .modalBottom {
    h3 {
      small {
        color: #bbb;
      }
    }
  }
  .userProfileSkeleton {
    .navSecondary {
      h1 {
        .text {
          background: #252525;
        }
      }
    }
    .name {
      background: #252525;
    }
    .bio {
      background: #252525;
    }
    .cta {
      .followBtn {
        background: #252525;
        border: none !important;
      }
      .shareBtn {
        background: #252525;
      }
    }
    .flexHeaderAccount {
      .profileImageBox {
        background: #252525;
      }
      .userStats {
        .count {
          background: #252525;
        }
        .text {
          background: #252525;
        }
      }
    }
    .userRecipes {
      border-top: 3px solid #252525;
    }
  }
  .followBtn {
    border: 1.5px solid #ff6900 !important;
  }
  .chat {
    background: #333;
    .chatMessages {
      background: #333;
    }
    .flexSearch {
      background: #333;
    }
  }
  .followBtn {
    border: 1.5px solid #fff !important;
    color: #fff !important;
  }
  .counterBtn {
    color: #fff !important;
    span {
      color: #fff !important;
    }
  }
  @keyframes notificationPulseDark {
    0% {
      background: #444;
    }
    33% {
      background: #111;
    }
    66% {
      background: #111;
    }
    100% {
      background: #111;
    }
  }
  .flexShareBtn {
    span {
      background: #252525;
    }
  }
  .listQuantityButton {
    background: #ff6900;
    border: 2px solid #ff6900 !important;
    color: #fff;
  }
  .map {
    background: #252525;
  }
  .businessFacilities {
    span {
      background: #252525;
      color: #fff;
    }
  }
  .weekHeader {
    color: #fff;
  }

  .ctaButtons {
    button {
      background: #252525 !important;
    }
    &.recipePage {
      button {
        svg {
          color: #fff;
        }
      }
    }
    &.business {
      button {
        background: #252525 !important;
        svg {
          color: #fff !important;
        }
      }
    }
  }
  .addToCartBtn {
    background: #252525 !important;
  }
  .recipeAuthor {
    small {
      background: #252525;
      color: #fff;
      &.highlightAllergen {
        color: #252525;
      }
    }
  }
  .programCalendar {
    .days {
      background: #252525;
    }
    input {
      background: #252525 !important;
    }
  }
  .recipeMethodBox {
    background: #252525;
  }
  .comments {
    .commentInfo {
      span {
        color: #fff;
      }
    }
  }
  .appLogo {
    background: #333;
  }
  .modalFullContent {
    background: #252525;
  }
  .feedbacksFilter {
    button {
      background: #333;
    }
  }
  .compareListItem {
    span {
      background: #252525;
    }
  }
  .businessBox {
    background: #252525;
    .btnSecondary {
      border: 2px solid #fff;
      color: #fff;
    }
  }
  .btnChart {
    button {
      background: #333;
      &:first-of-type {
        background: #999;
      }
    }
  }
  .chartGraph {
    background-image: linear-gradient(#333 1px, transparent 1px),
      linear-gradient(to right, #333 1px, transparent 1px);
  }
  .featuresSwiper {
    background: #252525 !important;
    .swiper-slide {
      color: #fff !important;
      .checkbox {
        &.checked {
          background: #252525;
        }
      }
    }
  }
  .completeProfile {
    .flexButton {
      &.btnThree {
        background: #333 !important;
      }
    }
    .slide {
      &.one,
      &.two,
      &.three,
      &.four {
        label {
          small {
            color: #ddd !important;
          }
        }
      }
    }
  }
  .sliderPrivacy {
    svg:last-of-type {
      color: #1a1b1c !important;
    }
  }

  .react-joyride__tooltip {
    background: #252525 !important;
    color: #fff !important;
    button {
      svg {
        color: #fff !important;
      }
    }
  }
  .userProfileCta {
    .messageBtn {
      background: #252525 !important;
    }
    .userActionBtn {
      &.unfollowBtn {
        background: #252525 !important;
      }
    }
  }
  .swiperFeatures {
    &.menu {
      .swiper-slide {
        background: #252525;
      }
    }
  }
  .myFeaturesSwiper {
    span {
      color: #fff;
    }
  }
  .accordionButton {
    background: #252525 !important;
    color: #fff !important;
    border: 2px solid #252525 !important;
    &.active {
      border: 2px solid #ff6900 !important;
    }
  }
  .accordionContent {
    color: #fff !important;
    background: #252525;
  }
  .flexBox {
    &.widthFit {
      &.marginVertical {
        color: #fff;
      }
    }
  }
  .carrouselItemAccountsBtn {
    button {
      background: #252525 !important;
    }
  }
  .chatsList {
    button {
      background: transparent;
      color: #fff;
      small {
        color: #ddd;
      }
    }
    .modalBottomTooltipButton {
      svg {
        color: #fff !important;
      }
    }
  }
  .foodGptChat {
    .textLeft {
      background: #252525;
    }
  }
}
