.react-joyride-portal {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.bottomTooltip {
  position: absolute;
  bottom: 0;
  left: 0;
  background: red;
  width: 100%;
  height: 50px;
}

.__floater {
  max-width: 100vw;
  left: 0 !important;
}
.__floater__arrow {
  display: none;
}
.react-joyride__tooltip {
  text-align: left !important;
  border-radius: 20px !important;
  div {
    div {
      padding: 10px 0 !important;
    }
  }
}
.react-joyride__beacon {
  transform: scale(0.6);
  margin-top: 10px;
  span:first-of-type {
    background-color: #ff6900 !important;
  }
  span:last-of-type {
    border: 2px solid #ff6900 !important;
    background-color: rgba(255, 140, 0, 0.2) !important;
  }
}
