.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  max-height: 100vh;
  background: #fff;
  box-sizing: border-box;
  padding: 0 10px;
  transform: scale(1);
  text-align: center;
  transition: 0.35s;
  overflow: auto;
  .modalBackground {
    overflow: scroll;
    max-height: 85vh;
    .accordionButton {
      border-top: none;
    }
  }
  &.modal999 {
    z-index: 9 !important;
  }
  &.noPadding {
    padding: 0;
    overflow: scroll;
    height: fit-content !important;
    // min-height: fit-content !important;
  }
  &.relative {
    position: relative;
  }
  .modalFullContent {
    transform: scale(1);
  }
  .fixedButton {
    display: block;
    width: 100%;
    background: #fff;
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #fff;
    z-index: 2;
  }
  form {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
  }
  .formIngredients {
    position: relative;
    height: fit-content;
  }
  .tabs {
    form {
      min-height: fit-content;
    }
  }
  &.backgroundWhite {
    .flexCalendarFix {
      background: transparent !important;
      padding: 0;
    }
  }
}

.modalBottom {
  position: fixed;
  bottom: -100%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  min-width: 100%;
  max-width: 500px;
  height: fit-content;
  max-height: 85vh;
  background: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  z-index: 9;
  animation: slideUp 0.35s;
  padding: 0 10px;
  box-sizing: border-box;
  transition: 0.35s;
  overflow: scroll;
  opacity: 0;
  display: block;
  &.show {
    bottom: 0;

    transition: 0.35s;
    display: block !important;
    opacity: 1;
    transform-origin: left;
  }
  .roleIndicatorBox {
    img {
      width: 55px !important;
      min-width: 36px !important;
      max-width: 59px !important;
      height: 55px !important;
      min-height: 36px !important;
      max-height: 59px !important;
    }
  }
  .userActionBtn {
    min-width: 100% !important;
    padding: 16px !important;
    font-size: 16px !important;
  }
  .addRecipeForm {
    height: 65vh;
  }
  .flexInput {
    border-bottom: none;
    .quantityUnit {
      padding: 0;
      input {
        border: 1px solid #555 !important;
      }
      select {
        border: 1px solid #555 !important;
        width: 50px;
        font-size: 16px !important;
      }
      .input:nth-child(1) {
        width: 60px;
        margin: 0 5px;
      }
    }
  }
  .fixedButton {
    display: block;
    width: 100%;
    background: #fff;
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #fff;
    z-index: 2;
  }
  .modalBottomCloseButton {
    position: sticky;
    top: 0;
    width: 100%;
    display: block;
    padding: 15px 0;
    box-sizing: border-box;
    background: #fff;
    z-index: 4;
    span {
      width: 35%;
      height: 5px;
      background: #ddd;
      display: block;
      margin: 15px auto 0 auto;
      border-radius: 50px;
    }
  }
  .flexRadioSmall:first-of-type {
    overflow: scroll;
    width: 100%;
    box-sizing: border-box;
    .checkboxCardBox {
      width: fit-content;
      flex-wrap: nowrap;
      padding: 0;
    }
    .checkboxCardBoxCuisine {
      width: fit-content;
      padding: 0;
      overflow: scroll;
    }
  }
  .flexRadioSmall {
    width: 100%;
    padding: 0;
    box-sizing: border-box;
    .checkboxCardBox {
      width: fit-content;
      input:checked ~ label span p {
        background: transparent !important;
      }
      p {
        width: fit-content;
        display: block;
        white-space: nowrap;
        background: transparent !important;
      }
    }
    .checkboxCardBoxCuisine {
      width: fit-content;
      overflow: scroll;

      input:checked ~ label span p {
        background: transparent !important;
      }
      input:checked ~ label span {
        border: 2px solid #ff6900 !important;
        color: #ff6900;
      }
      input:checked ~ label p {
        color: #ff6900 !important;
      }
      p {
        width: fit-content;
        display: block;
        white-space: nowrap;
        background: transparent !important;
      }
    }
  }
  h3 {
    small {
      color: #555;
      font-size: 13px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    b {
      small {
        justify-content: flex-start;
      }
    }
  }
  .tabButtonPrimary {
    h3 {
      color: #999 !important;
      padding: 0px 8px 0 0;
    }
    &.active {
      h3 {
        color: #ff6900 !important;
      }
    }
  }
}
.modalFull {
  .modalFullContent {
    width: 100%;
    min-height: 100vh;
    height: fit-content;
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    transition: 0.35s;
    transform: scale(0);
    z-index: 8;
    padding: 0 5%;
    box-sizing: border-box;
    overflow: scroll;
    h3 {
      text-align: center;
      color: #555;
    }
    &.show {
      transform: scale(1);
    }
  }
  .show {
    transform: scaleX(1);
  }
}
.modalTooltip {
  button:disabled {
    color: #ccc;
  }
  .modalFullContent {
    width: 120px;
    background: #eeeeee;
    position: absolute;
    right: -5px;
    top: -5px;
    z-index: 9;
    padding: 10px;
    border-radius: 20px;
    transition: 0.35s;
    transform-origin: top right;
    transform: scale(0);
    &.show {
      transform: scale(1);
    }
    h5 {
      margin: 10px 5px 10px 0;
      text-align: left;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        padding: 0;
        button {
          background-color: transparent;
          border: none;
          margin: 5px 0;
          padding: 5px 0;
          font-size: 13px;
          display: flex;
          align-items: center;
          span {
            margin-right: 5px;
            width: 15px;
            height: 10px;
            border-radius: 2px;
          }
        }
      }
    }
  }
}
.modalTooltip {
  z-index: 7;
  background: #999;
  width: fit-content;
  display: flex;
  position: absolute;
  top: -55px;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 14px;
  &.chatTooltip {
    min-width: 30px;
    min-height: 30px;
    position: relative;
    top: unset;
    left: unset;
    margin: 0;
    border-radius: 0;
    transform: translate(0);
    .modalButton {
      &.chatButton {
        .modalBottomTooltipButton {
          padding: 4px;
          svg {
            font-size: 25px;
            color: #1a1b1c;
            display: block;
            width: 100%;
          }
        }
      }
    }
  }
  .pointer {
    display: block;
    width: 14px;
    height: 14px;
    background: #999;
    position: absolute;
    bottom: -7px;
    left: 50%;
    transform: translate(-50%, 0) rotate(45deg);
    border-bottom-right-radius: 5px;
  }
  button {
    background: transparent;
    color: #fff;
    border: none;
    padding: 10px 10px 10px 5px;
  }
  svg {
    font-size: 20px;
    display: block;
  }
}
.modalNotify {
  height: fit-content;
  width: 90%;
  max-width: 500px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  text-align: left;
  &.fixedModal {
    height: 100%;
    transform: translate(-50%, 0%);
  }
  .modalCenter {
    background: #fff;
    width: 100%;
    border-radius: 20px;
    padding: 10px 20px 10px 20px;
    box-sizing: border-box;
    overflow: hidden;
    img {
      display: none;
    }
    img[src] {
      display: block;
    }
    p {
      font-weight: 500;
      text-align: left;
      color: #777;
      span {
        color: #ff6900;
      }
    }
  }
}
