@font-face {
  font-family: "Kopenhagen";
  src: url("/assets/fonts/Kopenhagen.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("/assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf")
    format("truetype");
  font-display: swap;
}
iframe {
  width: 100%;
}
.homepageButtons {
  width: 80%;
  margin: 0 auto;
}
html,
body,
#root {
  margin: 0;
  padding: 0;
  font-family: "Montserrat";
  background: #fff;
  min-height: 100%;
  width: 100%;
  min-width: 100%;
  max-width: 500px;
  text-align: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overscroll-behavior-y: contain;
  overflow: scroll;
  box-sizing: border-box;
  zoom: 1;
  &.darkTheme {
    background: #333 !important;
    transition: 0.35s linear;
  }
}

::-webkit-scrollbar {
  display: none;
}
a {
  text-decoration: none;
  color: inherit;
}
img {
  font-size: 0 !important;
}
img[src] {
  pointer-events: none;
  font-size: 0 !important;
}
h1,
h2,
h3,
h4,
h5,
p,
label,
li {
  color: #1a1b1c;
}
button {
  cursor: pointer;
}
.pageRecipe {
  overflow: hidden;
  max-width: 500px;
  margin: 0 auto;
  position: relative;
  .tabButtonPrimary {
    h3 {
      padding: 0 10px 0 0;
      color: #999;
    }
  }
  .modal {
    z-index: 9;
    padding: 0 !important;
    .addRecipeForm {
      padding: 0;
    }
  }
  // .swiper {
  //   width: 110%;
  //   margin-left: -5%;
  //   .recipeMethodImagePlaceholder {
  //     width: 100%;
  //     height: 140px;
  //     background: #eee;
  //     display: flex;
  //     align-items: center;
  //     border-radius: 10px;
  //     margin-bottom: 5px;
  //     text-align: center;
  //     svg {
  //       display: block;
  //       margin: 0 auto;
  //       font-size: 70px;
  //       color: #ccc;
  //     }
  //   }
  //   .recipeMethodStep {
  //     padding: 0 5%;
  //     box-sizing: border-box;
  //     img {
  //       width: 100%;
  //       height: 100%;
  //       border-radius: 10px;
  //       object-fit: cover;
  //     }
  //   }
  // }
  .recipeType {
    margin-left: auto;
    left: auto;
    right: 10px;
  }
}
.recipeImageFixedSpacer {
  width: 100%;
  max-width: 500px;
  aspect-ratio: 1/1;
  margin: 0 auto;
}
.recipeImageFixed {
  height: 100vw;
  width: 100vw;
  max-height: 500px;
  max-width: 500px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(-45deg, #ddd, #fff);
  .flexMenuImages {
    height: 100%;
    min-height: 100%;
    display: block;
    .flexMenuImagesScroll {
      overflow: auto;
      height: 100%;
      min-height: 100%;
      position: relative;
      z-index: 5;
      transform: rotate(-90deg);
      .menuImageBox {
        width: 100%;
        min-width: 100%;
        height: 100%;
        min-height: 100%;
        cursor: move;
        transform: rotate(90deg);
        display: block;
        overflow: hidden;
        img {
          height: 100%;
          min-height: 100%;
          max-height: 100%;
        }
        &.multiple:last-of-type {
          margin: 0;
        }
        &.multiple:first-of-type {
          margin: 0 0 10px 0;
        }
        &.multiple:last-of-type {
          padding: 0;
          margin: -30px 0 0 0;
        }
        &.multiple {
          width: 100%;
          min-width: 100%;
          max-width: 100%;
          height: 100% !important;
          min-height: 100% !important;
          max-height: 100% !important;
          margin: -30px 0 10px 0;
          padding: 0 30px 0 0;
          box-sizing: border-box;
        }
      }
    }
  }
  img {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
  }
  .imagePlaceholder {
    width: 20%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.pageRecipeText {
  width: 100%;
  max-width: 500px;
  padding: 30px 5% 0 5%;
  text-align: left;
  box-sizing: border-box;
  overflow: visible;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  margin: -70px auto 0 auto;
  background: #fff;
  position: relative;
  .recipeMethodStep {
    background: #f9f9f9;
    padding: 8px;
    img {
      width: 80px;
    }
  }
  &.business {
    padding: 0 5%;
  }
  h2 {
    text-align: left;
    display: flex;
    img {
      width: 80px;
      height: fit-content;
      margin-left: auto;
    }
  }
  .category {
    text-align: left;
    font-size: 13px;
    color: #999;
  }
  .flexInput {
    padding: 8px;
    box-sizing: border-box;
    border-bottom: 2px solid #f9f9f9;
    &:last-of-type {
      border-bottom: none !important;
    }
  }
}
.page {
  animation: fadeIn 0.35s;
  padding: 88px 20px 20px 20px;
  max-width: 500px;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  &.overflowVisible {
    overflow: visible !important;
  }
  &.alignLeft {
    text-align: left;
  }
  &.noHorizontalPadding {
    padding: 88px 0 20px 0;
  }
  &.scroll {
    overflow: scroll;
    max-height: calc(100vh - 108px);
  }
  &.noPadding {
    padding: 0 5%;
  }
  &.paddingZero {
    padding: 0;
  }
  &.swiperFull {
    padding: 95px 5% 5px 5%;
    height: fit-content;
    overflow: visible;
    .mySwiper {
      padding-bottom: 20px;
    }
    .swiper-pagination {
      position: absolute !important;
      top: 98% !important;
      height: fit-content;
      .swiper-pagination-bullet {
        transition: 0.35s;
        height: 5px;
        width: 5px;
      }
      .swiper-pagination-bullet-active {
        width: 20px;
        border-radius: 50px;
        transition: 0.35s;
        border: none !important;
      }
    }
    .swiper-pagination-bullet-active:nth-child(1) {
      background: linear-gradient(45deg, #add100, #7b920a) !important;
    }
    .swiper-pagination-bullet-active:nth-child(2) {
      background: linear-gradient(45deg, #00c6ff, #0072ff) !important;
    }
    .swiper-pagination-bullet-active:nth-child(3) {
      background: linear-gradient(45deg, #b993d6, #8ca6db) !important;
    }
  }
  .tabButtonsPrimary {
    margin-top: 0;
    overflow: scroll;
  }
  .tabButtonsSecondary {
    overflow: auto;
    overflow-y: scroll;
  }
  .userDetails {
    text-align: left;
    margin: 10px 0;
    font-size: 16px;
    span:first-of-type {
      color: #999;
      font-size: 13px;
      display: block;
      letter-spacing: 0.6px;
    }
    .userDetailsName {
      font-size: 18px !important;
      font-weight: 600;
      padding: 5px 0;
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      color: #1a1b1c !important;
      .socialButtons {
        display: flex;
        align-items: center;
        svg {
          font-size: 23px;
          margin: 0 0 0 10px;
          display: block;
          color: #aaa;
        }
        a svg {
          color: #1a1b1c;
        }
      }
      button {
        border: none;
        svg {
          display: block;
          font-size: 20px;
        }
      }
    }
    .userDetailsWebsite:disabled {
      display: none;
    }
    .userDetailsWebsite {
      display: block;
      margin: 0 0 8px 0;
      background: 0;
      border: 0;
      text-align: left;
      font-size: 13px;
      padding: 0;
    }
  }
  h2 {
    font-family: "Montserrat";
    letter-spacing: 1px;
    color: #1a1b1c;
    margin: 0;
  }
}
.pageLeft {
  text-align: left;
  box-sizing: border-box;
  &.flex {
    display: flex;
    flex-wrap: nowrap;
    flex-shrink: 0;
    scroll-snap-type: x mandatory;
    width: 100%;
    overflow: auto;
    margin: 0 8px;
    padding: 8px;
    .card {
      flex: 0 0 calc(100% - 60px);
      margin: 0 5px !important;
      height: fit-content;
      scroll-snap-align: start;
    }
  }
  .headerIcon {
    font-size: 40px;
  }
  a {
    color: #ff6900;
    text-transform: underline;
  }
  label {
    p {
      text-align: left;
      padding: 5px 0;
      margin: 0;
    }
  }
}

//USER PHOTO & STATS INFO
.flexHeaderAccount {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0;
  width: 100%;
  .profileImageBox {
    position: relative;
    width: 84px;
    height: 84px;
    margin-right: 10px;
    img {
      position: relative;
      margin: 2px 0 0 2px;
      z-index: 3;
      border: 2px solid #fff;
      width: 68px;
      height: 68px;
    }
    .userBackground,
    .creatorBackground,
    .proBackground,
    .businessBackground,
    .adminBackground {
      width: 76px;
      height: 76px;
      border-radius: 40%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }
    .userBackground {
      background: linear-gradient(-45deg, #999999, #eee);
    }
    .creatorBackground {
      background: linear-gradient(45deg, #b993d6, #8ca6db);
    }
    .proBackground {
      background: linear-gradient(45deg, #add100, #7b920a);
    }
    .businessBackground {
      background: linear-gradient(45deg, #00c6ff, #0072ff);
    }
    .adminBackground {
      background: linear-gradient(45deg, #fd746c, #ff9068);
    }
  }
  .userStats {
    width: calc(100% - 70px);
    max-width: 300px;
    text-align: left;
    color: #1a1b1c;
    margin: 0 auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    box-sizing: border-box;
    z-index: 9;
    position: relative;
    span {
      font-size: 12px;
      font-weight: 600;
      display: block;
      color: #999;
    }

    .counterBtn:hover {
      background: #eee;
      transition: 0.35s linear;
    }
    .counterBtn {
      background: transparent;
      color: #1a1b1c;
      font-size: 13px;
      font-weight: 500;
      opacity: 1;
      min-width: 33%;
      max-width: 33%;
      width: 33%;
      border-radius: 5px;
      transition: 0.35s;
      position: relative;
      overflow: hidden;
      padding: 5px 0 10px 0;

      // .btnRipple {
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   width: 100%;
      //   height: 100%;
      //   display: block;
      //   background: #f5f5f5;
      //   transition: 0.35s linear;
      //   transform: scale(0);
      //   transform-origin: center;
      //   z-index: 1;
      // }
      span:first-of-type {
        color: #1a1b1c;
        font-size: 25px;
        z-index: 2;
        position: relative;
        min-height: 30px;
        display: block;
      }
      span:nth-child(2) {
        color: #1a1b1c;
        z-index: 2;
        position: relative;
      }
    }
  }
}

.cardList {
  display: flex !important;
  align-items: center;
  font-size: 16px;
  padding: 8px 10px;
  width: 100% !important;
  text-align: left;
  background: #f9f9f9;
  margin-bottom: 5px;
  border-radius: 50px;
  box-sizing: border-box;
  a:nth-child(2) {
    max-width: calc(100% - 140px);
    overflow: hidden;
  }

  .userActionBtn {
    font-size: 14px !important;
    padding: 4px 8px;
  }
  .modalBottom {
    .userActionBtn {
      min-width: 100% !important;
      padding: 8px !important;
      font-size: 16px !important;
    }
  }
  button {
    margin: 0 0 0 auto !important;
    background: transparent;
    .modalBottomCloseButton {
      padding: 0;
      border: 0;
    }
  }
  .btnSecondary {
    padding: 12px 0;
    border: 2px solid #ff6900 !important;
  }
  .text {
    padding: 0;
    margin: 0;
    margin-left: 10px !important;
    display: block !important;
    border: none;
    text-align: left;
    .secondaryText {
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 0.9;
      overflow: hidden;
      white-space: nowrap;
      width: fit-content;
      max-width: 100px;
      text-overflow: ellipsis;
      color: #999;
      border: none;
      padding: 0;
    }
    .primaryText {
      font-size: 14px !important;
      font-weight: 700 !important;
      letter-spacing: 0.3;
      color: #1a1b1c;
      overflow: hidden;
      white-space: nowrap;
      width: fit-content;
      max-width: 100px;
      text-overflow: ellipsis;
      display: block;
      border: none;
      padding: 0;
    }
    small {
      font-size: 11px;
      color: #999;
    }
  }
  .roleIndicatorBox {
    .userBackground,
    .creatorBackground,
    .proBackground,
    .businessBackground,
    .adminBackground {
      width: 44px;
      height: 44px;
      border-radius: 40% !important;
      margin: 0;
    }
    img {
      min-width: 36px;
      min-height: 36px;
      width: 36px;
      height: 36px;
      background: #eee;
      object-fit: cover;
      margin: 0;
      display: block;
    }
  }
  a:nth-child(2) {
    height: fit-content;
  }
}
.headerRight {
  display: flex;
  align-items: center;
  a {
    display: block;
    height: 20px;
  }
  button {
    font-size: 20px !important;
    padding: 0 0 0 20px !important;
  }
}
.flexHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  text-align: left;
  transition: 0.35s;
  position: relative;
  font-weight: 600 !important;
  z-index: 1;
  &.widthFit {
    width: fit-content;
  }
  &.withIcon {
    svg {
      margin: 0 8px 0 0;
    }
  }
  &.underline {
    align-items: flex-start;
    padding: 0 0 24px 0;
    border-bottom: 1px solid #f9f9f9;
  }
  &.skeletonHeader {
    border-radius: 50px;
    background: #f5f5f5;
    min-width: 165px;
    max-width: 165px;
    min-height: 26px;
    margin: 1em 0;
    font-size: 0;
    &.small {
      min-width: 85px;
      max-width: 85px;
      min-height: 22px;
      margin: 30px 0 10px 0;
    }
  }
  &.flexStart {
    align-items: flex-start;
  }
  &.fullWidth {
    min-width: 100%;
    width: 100%;
    display: inline;
  }
  &.marginNone {
    margin: 0;
  }
  &.marginSmall {
    margin: 5px 0;
  }
  &.marginTopNone {
    margin-top: 0;
  }
  &.marginBottomNone {
    margin-bottom: 0;
  }
  &.marginLeftAuto {
    margin-left: auto;
  }
  .leftIcon {
    margin-right: 5px;
    display: block;
  }
  &.iconBlack {
    position: sticky;
    top: 0;
    .currentChatPrompt {
      max-width: 240px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &::first-letter {
        text-transform: capitalize;
      }
    }
    button {
      background: transparent;
      padding: 0;
      color: #222;
    }
  }
  &.cartItem {
    span:first-of-type {
      width: 50%;
      display: flex;
      align-items: center;
      img {
        margin: 0 8px 0 0;
      }
    }
    span:nth-child(2) {
      width: 20%;
    }
    span:nth-child(3) {
      width: 20%;
      background: transparent;
      .cartBtn {
        width: fit-content;
      }
    }
    span:nth-child(4) {
      width: 10%;
      display: flex;
      justify-content: flex-end;
    }
  }
  &.noWeight {
    font-weight: 400 !important;
  }
  &.capitalize {
    text-transform: capitalize;
  }
  &.disabled {
    display: none;
  }
  &.noJustify {
    justify-content: start !important;
  }
  &.padding {
    padding: 0 10px;
    span {
      display: flex;
      align-items: center;
    }
  }
  svg {
    display: block;
  }
  input[type="number"] {
    border: none;
    text-align: center;
  }
  .transparentButton {
    background: transparent;
    color: #1a1b1c;
    font-size: 18px;
    margin: 0;
    padding: 2px 0;
  }
  small {
    font-size: 16px !important;
    font-weight: 500;
  }
  .infoButtonTransparent {
    background: transparent;
    color: #999;
    label {
      font-size: 13px;
      span {
        color: #ff6900;
      }
    }
    .modalButton {
      padding: 0;
      color: #999;
      background: transparent;
    }
  }

  .modalTooltip {
    position: relative;
    top: 0;
    left: 0;
    transform: translate(0);
    background-color: transparent;
    .modalFullContent {
      a:hover,
      .active {
        button {
          color: #999;
        }
      }
      a {
        button {
          color: #555;
          display: block;
        }
      }
    }
  }
  .flexHeaderSpanMain {
    display: flex;
    align-items: center;
    margin-left: auto;
    .flexHeaderSpan {
      width: fit-content;
      color: #1a1b1c;
    }
  }
  .flexHeaderSpan {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
    &.skeleton {
      img {
        border-radius: 30px;
        background: #f5f5f5 !important;
        margin-right: 5px;
        object-fit: cover;
      }
      p {
        min-width: 120px;
        min-height: 22px;
        background: #f5f5f5;
        border-radius: 50px;
      }
    }
    p {
      margin: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 50vw;
      overflow: hidden;
      small {
        color: #999;
      }
    }
    img {
      width: 30px;
      height: 30px;
      padding-right: 5px;
      object-fit: cover;
      border-radius: 8px;
    }
  }
  button {
    font-size: 15px;
    background: #f5f5f5;
    color: #999;
    border: none;
    border-radius: 50px;
    padding: 2px 10px;
    margin: 0;
  }
}

//RECIPE SELECTOR MODAL
table {
  padding: 5px;
  border-radius: 8px;
  border-collapse: collapse;
  display: table;
  width: 100%;
  border-spacing: 0;
  box-sizing: border-box;

  .recipeSelect {
    padding: 0;
    border: none;
    display: block;
    width: 100%;
    background: transparent;

    tr {
      width: 100%;
      border: 0;
      display: block;
      box-sizing: border-box;
      td {
        width: 100%;
        border: 0;
        display: block;
        box-sizing: border-box;
        .recipeSelector {
          display: flex;
          align-items: center;
          color: #1a1b1c;
          margin: 0;
          padding: 5px 0;
          width: 100%;
          text-align: left;
          border-bottom: 1px solid #ddd;
          width: 100%;
          label {
            display: block;
            font-size: 13px;
            color: #ff6900 !important;
          }
          p {
            width: 200px;
            font-size: 13px;
            margin: 0;
            color: #999;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          svg {
            width: 60px;
            height: 60px;
            padding: 10px;
            margin-right: 10px;
            color: #ddd;
          }
          img {
            width: 80px;
            height: 80px;
            margin-right: 10px;
            border-radius: 10px;
            object-fit: cover;
          }
        }
      }
    }
  }
}
.closeModalBtn {
  position: fixed !important;
  top: 10px;
  left: 10px;
  border-radius: 40% !important;
  width: 40px !important;
  height: 40px !important;
  border: none;
  background: #f9f9f9 !important;
  z-index: 6;
  padding: initial !important;
  svg {
    font-size: 25px;
    display: block;
    margin: 0 auto;
    color: #1a1b1c !important;
  }
}

.cardWide {
  position: relative;
  display: flex;
  align-items: center;
  background: #f5f5f5;
  border-radius: 20px;
  margin-bottom: 10px;
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;
  svg {
    color: #ff6900;
    margin: 8px 0 0 0;
    &:first-of-type {
      margin: 8px 12px 0 0;
    }
  }
  .businessRating {
    padding: 0 0 8px 0;
    margin: 0 0 0 4px;
    svg {
      color: orange;
      margin: 0;
    }
  }
  a {
    color: inherit;
  }
  .pageLeft {
    width: calc(100% - 85px);
  }
  .heightFit {
    display: block;
    height: 80px;
  }
  img {
    width: 80px;
    height: 80px;
    min-width: 80px;
    min-height: 80px;
    aspect-ratio: 1/1;
    border-radius: 18px;
    margin: 0 10px 0 0;
    background: #eee;
    object-fit: cover;
    box-sizing: border-box;
  }
  h3 {
    margin: 0;
  }
  label {
    margin: 0 !important;
    border-radius: 50px;
    font-size: 13px;
    font-weight: 600;
    display: block;
  }
}
.cardEmpty {
  font-weight: 600;
  position: relative;
  box-shadow: 0 2px 6px -2px #999;
  overflow: hidden;
  background: #fefefe;
  overflow: hidden;
  border-radius: 20px;
  margin: 10px 0;
  .pageLeft {
    height: 64px;
    background: #f9f9f9;
  }
  .emptyStateRay {
    background: linear-gradient(
      to right,
      transparent,
      rgba(255, 255, 255, 0.9),
      transparent
    );
    display: block;
    position: absolute;
    animation: infinite emptyState 2.5s linear;
    top: -100%;
    left: -250%;
    height: 300%;
    width: 300px;
    z-index: 5;
  }

  button:disabled {
    display: none;
  }
  .buttonFav {
    background: #eee;
    border: 3px solid #fff;
    z-index: 2;
  }
  .img {
    width: 102%;
    height: 150px;
    margin-left: -1%;
    margin-top: -1%;
    object-fit: cover;
    border-radius: 10px;
    display: block;
    background-color: #eee;
  }
}
.inputFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  input:valid {
    background: transparent;
    border: 1px solid transparent !important;
    color: #1a1b1c;
  }
  input:focus {
    background: transparent;
    border: 1px solid #555 !important;
    color: #1a1b1c;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 40%;
    .input {
      width: 49%;
    }
  }
  .input {
    width: 59%;
  }
  p {
    text-align: left;
    font-weight: 600;
  }
}
.flexRadio {
  width: 100%;
  padding: 0;
  overflow: hidden;
  &.feedbacks {
    small {
      display: flex !important;
      align-items: center;
      font-size: 16px;
      svg {
        margin: -0.5px 0 0 2px !important;
        color: #ffae00;
      }
    }
  }
  .checkboxCardBox {
    display: flex;
    width: 100%;
    overflow: scroll;
    div {
      flex-shrink: 0;
    }
    input {
      display: none;
    }
    button {
      border: 2px solid #ccc;
      display: block;
      width: fit-content;
      height: fit-content;
      border-radius: 500px;
      transition: 0.35s;
      position: relative;
      text-align: center;
      overflow: hidden;
      margin: 0 5px 0 0;
      box-sizing: border-box;
      white-space: nowrap;
      font-weight: 600;
      padding: 5px 15px;
      font-size: 13px;
      background: transparent;
    }
    span {
      border: 2px solid #ccc;
      display: inline-block;
      width: fit-content;
      height: fit-content;
      border-radius: 500px;
      transition: 0.35s;
      position: relative;
      text-align: center;
      overflow: hidden;
      margin: 0 5px 0 0;
      box-sizing: border-box;
      p {
        font-size: 13px;
        text-align: center !important;
        width: fit-content;
        margin: 0 auto;
        display: flex;
        align-items: center;
        font-weight: 600;
        padding: 5px 15px;
        color: #555;
        transition: 0.35s;
        small {
          display: block;
          flex-shrink: 0 !important;
        }
        svg {
          font-size: 20px;
          margin-right: 10px;
        }
      }
    }
    input:checked ~ label span {
      border: 2px solid #1a1b1c;
      background: #1a1b1c;
    }

    input:checked ~ label span p {
      color: #fff;
    }
  }
}
.list {
  width: 100%;
  color: #999;
  button:disabled {
    display: none;
  }
  .listFlex {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 16px;
    border: none;
    padding: 0;
    color: #1a1b1c;
    font-weight: 500;
  }
}
.addButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 0;
  border-radius: 0;
  border-radius: 50px;
  border: none;
  text-align: center;
  z-index: 8;
  overflow: hidden;
  background: #ff6900;
  border: 2px solid #ff6900;
  box-shadow: 0 3px 8px -2px #999;
  button {
    font-size: 25px;
    display: block;
    color: #fff;
    padding: 15px;
    background: #ff6900;
    border-radius: 50%;
    transition: 0.5s;
    border: none;
    svg {
      display: block;
    }
  }
  .scrollToTopBtn {
    background: #fff;
    font-size: 25px;
    color: #ff6900;
    transform: scale(0);
    height: 0;
    width: 55px;
    margin: 0 auto;
    padding: 0;
    transform-origin: bottom;
    svg {
      margin: 0 auto;
    }
  }
}
.profileImg {
  width: 80px;
  height: 80px;
  display: block;
  background: #ddd;
  font-size: 0;
  border-radius: 40%;
  object-fit: cover;
  border: 2px solid #eee;
}
// FLEX BOXES
.flexInputHeader {
  position: relative;
  display: flex;
  align-items: center;
  color: #555;
  font-size: 16px;
  padding: 15px 0;
  button {
    position: absolute;
    right: 0;
    top: 0;
    width: fit-content;
    background: transparent;
    height: 100%;
    border: none;
    color: #999;
    font-family: "Montserrat";
    text-align: center;
    padding: 0;
  }
}
.flexInput {
  position: relative;
  display: flex;
  align-items: center;
  border: none;
  padding: 0;
  background: transparent;
  width: 100%;
  &.fullWidth {
    .quantityUnit {
      width: 100% !important;
      .input {
        width: 100% !important;
      }
    }
    select {
      width: 100% !important;
    }
  }
  &.borderNone {
    border: 0;
  }
  img {
    object-fit: cover;
    width: 40px !important;
    height: 40px !important;
    min-width: 40px !important;
    min-height: 40px !important;
    max-width: 40px !important;
    max-height: 40px !important;
    background: transparent;
    border-radius: 10px;
    margin: 0 8px 0 0;
  }
  .input {
    width: 100%;
    margin: 0;
  }
  input {
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    transform: 0.35s linear;
  }
  .quantityUnit {
    display: flex;
    justify-content: space-between;
    width: 70%;
    .input {
      width: calc(50% - 2.5px);
    }
    input {
      border-radius: 10px;
      font-size: 16px !important;
      font-weight: 600;
      text-align: center;
    }
    select {
      border-radius: 10px;
      font-size: 16px !important;
      font-weight: 600;
      text-align: center;
      padding: 16px 0;
    }
    input:invalid {
      border: 1px solid #ff6900 !important;
    }
    select:invalid {
      border: 1px solid #ff6900 !important;
    }
    span {
      text-align: left;
      color: #1a1b1c;
      min-width: 40px;
      display: block;
    }
  }
  p {
    font-size: 16px;
  }
  svg {
    display: block;
    margin: 0 auto;
  }
  button {
    width: fit-content;
    background: transparent;
    height: 100%;
    border: none;
    color: #999;
    font-family: "Montserrat";
    text-align: center;
    padding: 3px 0 3px 3px;
  }
}
.flexShare {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  overflow: hidden;
  min-width: 100%;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  &.large {
    a {
      min-width: 50%;
      margin: 0 0 12px 0;
    }
    .flexShareBtn {
      width: 50%;
      span {
        width: 100px;
        height: 100px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      small {
        width: 100%;
        font-size: 14px;
        padding: 0 36px;
        text-wrap: wrap;
        word-wrap: break-word !important;
        box-sizing: border-box;
        margin: 8px auto 0 auto !important;
      }
    }
  }
  &.oneItem {
    justify-content: center;
  }
  &.textCentered {
    padding: 0;
    align-items: flex-start;
    flex-wrap: wrap;

    a {
      width: 33%;
    }
    .flexShareBtn {
      width: 100% !important;
      text-align: center;
      span {
        margin: 0 auto;
        img {
          padding: 0;
          object-fit: cover;
          aspect-ratio: 1/1 !important;
        }
      }
      small {
        text-wrap: wrap;
        height: 30px;
        display: block;
        margin: 8px 0 0 0;
        width: 100%;
      }
    }
  }
  div {
    display: flex;
    width: fit-content;
  }
  &.scroll {
    overflow: scroll;
    padding: 0;
    button {
      transform: scale(0.7);
    }
  }
  &.noJustify {
    div {
      width: fit-content;
    }
  }
  a {
    margin: 0;
  }
  .flexShareBtn:disabled {
    opacity: 0.5;
    display: block !important;
    transition: 0.35s;
    svg {
      color: #999;
    }
  }
  .flexShareBtn {
    border: none;
    font-size: 25px !important;
    padding: 0;
    margin: 0;
    background: transparent;
    width: 65px;
    transition: 0.35s;
    width: 100%;
    &.orange {
      span {
        border: 2px solid #ff6900 !important;
        transition: 0.35s linear;
        svg {
          color: #ff6900;
          transition: 0.35s linear;
          background: #fff !important;
        }
      }
      small {
        color: #ff6900;
      }
    }
    &.iconRed {
      svg {
        color: #ff1b1c;
      }
      small {
        color: #ff1b1c;
      }
      &:disabled {
        opacity: 0.5;
      }
    }
    article {
      font-size: 16px;
    }
    small {
      font-size: 13px;
      margin: 0;
      font-weight: 600;
      color: #777;
      white-space: nowrap;
    }
    span {
      display: block;
      border-radius: 50%;
      overflow: hidden;
      background: #f9f9f9;
      padding: 0;
      position: relative;
      width: 65px;
      height: 65px;
      border: 2px solid transparent;
      transition: 0.35s linear;
      margin: 0 auto;
      img {
        width: 65px;
        height: 65px;
        object-fit: contain;
        position: absolute;
        top: 0;
        left: 0;
        padding: 8px;
        box-sizing: border-box;
      }
    }
    svg {
      background: #f9f9f9 !important;
      display: block;
      margin: 0 auto;
      width: 65px;
      height: 65px;
      padding: 20px;
      box-sizing: border-box;
      color: #1a1b1c;
    }
  }
}
.flexNutrients {
  display: flex;
  justify-content: space-between;
  margin: 0 auto 0 -5%;
  box-sizing: border-box;
  width: 110%;
  overflow: scroll;
  padding: 0 5%;
  .flexRecipeSecondary {
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    .servings {
      background: rgba(255, 105, 0, 0.1);
    }
    .cardBackdrop {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      backdrop-filter: blur(10px);
    }
  }
  span:last-of-type {
    margin: 0;
  }
  span {
    position: relative;
    display: block;
    margin: 0 5px 0 0;
    min-width: 75px;
    height: 75px;
    font-size: 22px;
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
    text-align: center;
    background: #f5f5f5;
    line-height: 1.1;
    overflow: hidden;
    label {
      font-size: 13px;
      color: #ff6900;
      font-weight: 600;
      background: transparent;
      padding: 0 0 5px 0;
      white-space: nowrap;
      text-transform: capitalize;
    }
    small {
      font-size: 13px;
      font-weight: 600;
    }
    select {
      padding: 0;
      width: fit-content;
      margin: 0;
    }
    svg {
      font-size: 30px;
    }
  }
}
.flexRecipe {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  border-radius: 10px;
  box-sizing: border-box;
  width: 100%;
  overflow: scroll;
  background: #f9f9f9;
  border-radius: 10px;
  .input {
    margin: 0;
  }
  span {
    width: 33%;
    font-size: 13px;
    padding: 10px 10px;
    box-sizing: border-box;
    text-align: center;
    svg {
      display: block;
      margin: 0 auto 5px auto;
      padding: 2px;
      box-sizing: border-box;
      color: #ff6900;
    }
    label {
      color: #1a1b1c;
      font-weight: 600;
      background: transparent;
    }
    select {
      padding: 5px 0;
      width: fit-content;
      margin: 0;
    }
    svg {
      font-size: 30px;
    }
  }
}
.flexBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.widthFit {
    width: fit-content;
  }
  &.marginVertical {
    margin: 8px 0;
  }
  &.alignStart {
    align-items: flex-Start;
  }
  &.justifyEnd {
    justify-content: flex-end;
    &.marginTop {
      margin-top: 8px;
    }
  }
  &.wrap {
    justify-content: unset;
    flex-wrap: wrap;
    flex-shrink: 0;
  }
  &.btnChart {
    margin-top: 10px;
    button {
      background: #f2f2f2;
      border: none;
      font-size: 12px;
      padding: 8px 12px;
      border-radius: 50px;
      width: calc(25% - 2.5px);
      &.active {
        color: #fff;
        background: #222;
      }
      &:nth-child(2) {
        color: #ff6900;
        &.active {
          color: #fff;
          background: #ff6900;
        }
      }
      &:nth-child(3) {
        color: #5cb85c;
        &.active {
          color: #fff;
          background: #5cb85c;
        }
      }
      &:nth-child(4) {
        color: #66b3ff;
        &.active {
          color: #fff;
          background: #66b3ff;
        }
      }
    }
  }

  .btnTab {
    width: calc(24% - 4px);
    margin: 12px 2px;
    font-size: 13px;
    background: none;
    padding: 4px 0;
    border-radius: 50px;
    border: 2px solid #999;
  }
}
.feedbacksFilter {
  button {
    background: transparent;
    border-radius: 50px;
    display: flex;
    border: 2px solid #ddd;
    svg {
      color: orange;
      display: block;
    }
  }
}
.flexButtons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button:first-of-type {
    width: fit-content;
    padding: 0;
    border: 2px solid #ccc;
    svg {
      display: block;
      height: 20px;
      width: 20px;
      padding: 13px;
      color: #ccc;
    }
  }
  button {
    border: 2px solid #ff6900;
    color: #ff6900;
    width: calc(100% - 56px);
    padding: 12px 0;
    background: transparent;
    border-radius: 50px;
    svg {
      font-size: 23px;
      display: block;
      width: 100%;
    }
  }
  button:disabled {
    border: 2px solid #ddd;
    background: transparent;
    color: #ddd;
  }
}
.flexButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  button {
    border-radius: 50px;
    padding: 5px 10px;
    font-size: 16px;
    border: 2px solid #ddd;
    transition: 0.35s;
    &:disabled {
      // background: #ddd !important;
      // border: 2px solid #ddd !important;
      opacity: 0.35s;
      transition: 0.35s;
      display: block !important;
    }
  }
  button:first-of-type {
    background: transparent;
    color: #555;
    border: 2px solid #ddd;
  }
  button:last-of-type {
    background: #fff;
    color: #ff6900;
    border: 2px solid #ff6900;
    margin-left: 5px;
  }
}
.flexText {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .buttonStep {
    padding: 0;
    background: 0;
    border: none;
    svg {
      font-size: 25px;
      display: block;
    }
  }
}
.flexSearch {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  form {
    position: relative;
    margin: 0;
    padding: 0;
    width: 100%;
    &.hasFilter {
      width: calc(100% - 45px);
    }
    &.hasScanner {
      width: calc(100% - 90px);
    }
    .searchSubmitButton {
      position: absolute;
      top: 4px;
      right: 4px;
    }
  }
  label:disabled {
    display: none !important;
    transform: scale(0);
  }
  .searchSubmitButton:disabled {
    transition: 0.35s;
    transform: scale(0);
    opacity: 0;
  }
  .searchSubmitButton {
    background: #ff6900;
    width: 35px;
    height: 35px;
    transition: 0.35s;
    transform: scale(1);
    transform-origin: center;
    opacity: 1;
    svg {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      color: #fff;
    }
  }
  button {
    width: 43px;
    height: 43px;
    border: none;
    background: #f5f5f5;
    border-radius: 10px;
    padding: 0;
    svg {
      display: block;
      width: 24px;
      height: 24px;
      padding: 8px;
      color: #999;
      margin: 0 auto;
    }
  }
}
.buttonBack {
  position: fixed;
  top: 10px;
  left: 10px;
  border-radius: 40%;
  width: 40px;
  height: 40px;
  border: none;
  background: rgba(255, 255, 255, 0.5);
  svg {
    font-size: 25px;
    display: block;
    margin: 0 auto;
    color: #ff6900;
  }
}
.recipeTags {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  button:first-of-type {
    margin: 0;
  }
  button {
    background: #ff6900;
    font-size: 11px;
    color: #fff;
    padding: 5px;
    border: none;
    border-radius: 50px;
    letter-spacing: 0.5px;
    margin: 2.5px;
  }
}
.recipeCategory {
  margin: 5px 0 0 0;
  font-size: 13px;
  font-weight: 500;
  color: #999;
}
.recipeAuthor {
  margin: 12px 0;
  font-size: 11px;
  font-weight: 400;
  color: #999;
  display: flex;
  align-items: center;
  strong {
    margin-left: 5px;
    span {
      svg {
        margin-right: 5px;
      }
    }
  }
  svg {
    display: block;
  }
  &.business {
    display: inline-flex;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 4px 0;
    overflow: scroll;
    width: 100%;
    &.wrap {
      flex-wrap: wrap;
      small {
        margin: 4px 4px 0 0;
      }
    }
    svg {
      min-width: 20px;
      height: 20px;
      display: block;
      margin: 0 4px 0 0;
      color: #777;
    }
    small {
      font-size: 14px;
      font-weight: 500;
      padding: 4px 8px;
      min-width: fit-content;
      width: fit-content;
      display: flex;
      align-items: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex-wrap: wrap;
      background: #eee;
      border-radius: 50px;
      margin: 0 4px 0 0;
      color: #252525;
    }
  }
}
.highlightAllergen {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #ffae00;
  margin-right: 4px;
}
.buttonFav:disabled {
  background: #fff;
}
.buttonPlay {
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 60px;
  height: 60px;
  padding: 0;
  background: none;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  border: none;
  svg {
    display: block;
    width: 100%;
    height: 100%;
    padding: 13px 13px 13px 16px;
    box-sizing: border-box;
    color: #fff;
  }
}
.addRecipeForm {
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  &.foodgpt {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 500px;
    padding: 12px 8px;
    box-sizing: border-box;
    background: #fff;
    .recipeMethodStepInput {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .btnTertiary {
        position: relative;
        top: unset;
        width: fit-content;
        font-size: 30px;
        padding: 7.5px;
        background: #eee;
        svg {
          display: block;
          color: #1a1b1c;
        }
      }
      .input {
        width: calc(100% - 100px);
        input {
          padding: 12px !important;
        }
      }
      .btnPrimary {
        position: relative;
        height: 44px;
        width: 44px;
        top: unset;
        right: unset;
        background: #eee;
        color: #1a1b1c;
      }
    }
  }
  &.heightFit {
    height: fit-content;
  }
  &.business {
    .logoButton {
      background: transparent;
      padding: 0;
      border: 4px solid #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 120px;
      height: 120px;
      background: #fff;
      margin: 0;
      border-radius: 50%;
      color: #999;
      overflow: hidden;
      img {
        object-fit: cover;
        padding: 3px;
        box-sizing: border-box;
        border-radius: 50%;
      }
    }
  }
  &.businessClaim {
    height: fit-content;
    .addImageContainer {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .selectedContainer {
        width: 49%;
        min-height: 160px;
        display: block;
        background: #f5f5f5;
        padding: 8px;
        box-sizing: border-box;
        border-radius: 20px;
        h4 {
          font-weight: 600;
        }
        img {
          min-height: 140px;
          border-radius: 20px;
        }
      }
      label {
        height: 204px;
        width: 49%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        box-sizing: border-box;
        border-radius: 20px;
        color: #999;
        svg {
          font-size: 40px;
        }
      }
      img {
        height: 100px;
      }
    }
    .pageRecipeText {
      margin: 0;
      padding: 0;
    }
  }
  .logoGallery,
  .logoCapture,
  .backgroundGallery,
  .backgroundCapture,
  .menuCapture,
  .menuGallery {
    display: none;
  }
  .modalBottom {
    h3 {
      text-align: center;
    }
  }

  .flexHeader {
    padding: 5px 0;
  }
  #recipeImageLabel {
    height: 100vw;
    width: 100vw;
    margin: 0;
    padding: 0;
    display: block;
    position: relative;
    background: #f5f5f5;
    img {
      position: relative;
      z-index: 3;
      display: block;
      font-size: 60px;
      color: #999;
      font-weight: 600;
      display: flex;
      align-items: center;
      background: #eee;
      border-radius: 0;
      height: 100%;
    }
    .addSvg {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -45%);
      width: 90%;
      height: 50px;
      z-index: 3;
      border-radius: 50%;
      color: #999;
      svg {
        font-size: 50px;
      }
      p {
        color: #999;
        margin: 0;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
  #recipeCapture {
    display: none;
  }
  #recipePhoto {
    display: none;
  }
  h3 {
    margin: 15px 0 5px 0;
  }
  h4 {
    margin: 5px 0;
  }
  img {
    width: 100%;
    background: #eee;
    object-fit: cover;
  }
  .flexHeaderSmall {
    margin: 0;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
    .flexSpan {
      width: 40%;
      display: flex;
      align-items: center;
      span {
        width: 50%;
        text-align: left;
      }
    }
  }
  .viewList {
    background: transparent;
    font-size: 16px;
    border: none;
    color: #ff6900;
    border-radius: 50px;
    float: right;
  }
  .input {
    select {
      width: 100%;
    }
  }
  #recipeCategory:valid {
    border: 1px solid #ccc;
  }
  #recipeCategory:focus:valid {
    border: 1px solid #555;
  }
  #recipeMethod {
    border-radius: 10px;
    box-sizing: border-box;
    transition: 0.35s;
    width: 100%;
    border: 1px solid #ccc;
  }
  input:focus:valid ~ .inputLabel {
    display: block;
  }
  .checkboxCardBox {
    span {
      animation: fadeInLanguage 0.3s;
    }
  }
  button {
    animation: fadeInLanguage 0.3s;
  }
  h3,
  h4,
  h5 {
    text-align: left;
  }
  h5 {
    margin: 0;
    font-size: 14px;
    color: #999;
  }
  h4 {
    font-size: 16px;
    font-weight: 500;
  }
  .addRecipeHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .inputFlex {
    .input {
      margin: 0;
      input ~ .inputLabel {
        margin-top: -10px;
        color: #ff6900 !important;
      }
    }
  }
  .inputFlex:not(:first-of-type) {
    .input {
      margin: 0;
      input ~ .inputLabel {
        display: none;
      }
    }
  }
}
.linkButton {
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  background: transparent;
  border: none;
}
.logoBtn {
  background: transparent;
  padding: 0;
  border: none;
}
.copyright {
  font-family: "Montserrat";
  text-align: center;
  margin: 30px auto 0 auto;
  width: fit-content;
  text-align: center;
  .logo {
    height: fit-content;
  }
  span {
    font-family: "Kopenhagen";
    color: #999;
    span {
      color: #ff6900;
    }
  }
}
.version {
  text-align: center !important;
  font-size: 11px;
  color: #999;
  margin: 0;
}
.adviceAdd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 20px;
  color: #555;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 2px 8px -2px #999;
  text-align: left;
  margin: 10px 0;
  form {
    width: fit-content;
  }
  textarea {
    height: 60px;
    border-radius: 10px;
  }
  .flexHeader {
    margin-top: 5px;
  }
}
.btnList {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  border: none;
  border-bottom: 1px solid #ddd;
  padding: 16px 4px;
  margin: 0;
  font-size: 16px;
  text-align: left;
  overflow: hidden;
  background: transparent;
  font-weight: 600;
  color: #1a1b1c;
  &.last {
    border-bottom: none;
  }
  &.red {
    color: #ff3830;
    border-bottom: none !important;
  }
  &.flex {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    &:last-of-type {
      border-bottom: none;
    }
    button {
      border: none;
      background: transparent;
      text-align: left;
      small {
        font-size: 13px;
        font-weight: 500;
        color: #777;
        margin-top: 4px;
        display: block;
      }
      svg {
        display: block;
      }
    }
  }
  span {
    flex-shrink: 0;
    .switchDescription {
      display: block;
      width: 90vw;
      word-wrap: break-word;
    }
  }
  .input {
    margin: 0;
  }
  &.wrap {
    flex-wrap: wrap;
    ul {
      width: 100%;
      list-style: none;
      margin-bottom: 0;
      text-align: left;
      padding: 0;
      display: flex;
      align-items: center;
      overflow: scroll;
      li:first-of-type {
        margin-left: 0;
      }
      li {
        border: 2px solid #ddd;
        border-radius: 50px;
        width: fit-content;
        padding: 3px 37px 3px 8px;
        display: flex;
        position: relative;
        margin: 2.5px;
        svg {
          position: absolute;
          top: 0;
          right: 0;
          height: 28px;
          width: 28px;
          padding: 6px;
          box-sizing: border-box;
          background: #ddd;
          display: block;
          border-radius: 50%;
          margin: 0;
          margin-left: 5px;
        }
      }
    }
  }
  .spacer {
    width: 110px;
  }

  .newTag {
    font-size: 12px;
    background: #86dc3d;
    color: #fff;
    padding: 3px 5px;
    border-radius: 3px;
    font-weight: 700;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
  svg {
    margin-right: 10px;
    font-size: 24px;
    min-width: 20px;
  }
  p {
    max-width: 60%;
    margin: 0;
  }
  .input {
    margin-left: auto;
    .familyMemberSelector {
      position: absolute;
      top: 50%;
      right: 0 !important;
      transform: translate(0, -50%);
      border: 2px solid #ff6900;
      border-radius: 20px;
      width: 45px;
      text-align: center;
      padding: 8px 0;
      font-weight: 900;
    }
  }
}

.tag {
  font-size: 12px;
  background: #999;
  color: #fff;
  padding: 3px 5px;
  border-radius: 3px;
  font-weight: 700;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  &.pro {
    background: linear-gradient(45deg, #add100, #7b920a);
  }
  &.business {
    background: linear-gradient(45deg, #00c6ff, #0072ff);
  }
  &.creator {
    background: linear-gradient(45deg, #b993d6, #8ca6db);
  }
}
.backdrop999 {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100vh !important;
  z-index: 9 !important;
  display: block !important;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
}
.backdrop {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100vh !important;
  z-index: 8;
  display: block !important;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  &.transparent {
    background: transparent;
    backdrop-filter: none;
    display: none !important;
    &.show {
      display: block !important;
    }
  }
}
.backdropTransparent {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100vh !important;
  z-index: 6;
  display: block !important;
}
.backdropBottom {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9;
  animation: fadeIn 0.2s;
}
.ingredientsList {
  input[type="checkbox"] {
    display: block;
    transform: scale(2);
  }
  ul {
    list-style: none;
    li {
      display: flex;
      align-items: center;
      margin: 5px 0;
      input {
        margin-right: 10px;
      }
    }
  }
}
//TOGGLE SWITCH
.switch {
  position: relative;
  display: inline-block;
  margin-left: auto;
  input {
    display: none;
  }
  svg {
    margin: 0;
    width: 16px;
    height: 16px;
  }
  .sliderTheme::before {
    transition: 0.35s;
  }
  input + .sliderTheme {
    cursor: pointer;
    display: flex;
    border-radius: 50px;
    transition: 0.35s;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    overflow: hidden;
    background: #ddd;
    padding: 4px;
    transition: 0.35s;
    svg:first-of-type {
      padding: 5px 2px;
      background: #555;
      border-radius: 20px;
      color: #fff;
      transition: 0.35s;
    }
    svg:last-of-type {
      color: #555;
      padding: 5px 2px;
      transition: 0.35s;
    }
  }
  input:checked + .sliderTheme {
    background: #ff6900;
    svg:first-of-type {
      padding: 5px 2px;
      background: transparent;
      color: #fff;
      transition: 0.35s;
    }
    svg:last-of-type {
      padding: 5px 2px;
      background: #fff;
      border-radius: 20px;
      color: #333;
      transition: 0.35s;
    }
  }
  .sliderPrivacy {
    svg:last-of-type {
      color: #1a1b1c;
    }
  }
  .sliderPrivacy::before {
    transition: 0.35s;
  }
  input + .sliderPrivacy {
    cursor: pointer;
    display: flex;
    border-radius: 50px;
    transition: 0.35s;
    overflow: hidden;
    background: #ddd;
    padding: 4px;
    transition: 0.35s;
    span:first-of-type {
      padding: 4px;
      background: #555;
      border-radius: 20px;
      color: #fff;
      font-size: 12px;
      font-weight: 600;
    }
    svg:first-of-type {
      padding: 4px;
      background: #555;
      border-radius: 20px;
      color: #fff !important;
      transition: 0.35s;
      display: block;
    }
    span:last-of-type {
      padding: 4px;
      font-size: 12px;
      font-weight: 600;
    }
    svg:last-of-type {
      background: transparent;
      color: #555;
      padding: 4px;
      transition: 0.35s;
      display: block;
    }
  }
  input:checked + .sliderPrivacy {
    background: #ff6900;
    transition: 0.35s;
    span:first-of-type {
      padding: 4px;
      background: transparent;
      color: #fff !important;
    }
    svg:first-of-type {
      padding: 4px;
      background: transparent !important;
      color: #fff !important;
      transition: 0.35s;
      display: block;
    }
    span:last-of-type {
      padding: 4px;
      background: #fff;
      border-radius: 20px;
      color: #333 !important;
    }
    svg:last-of-type {
      padding: 4px;
      background: #fff;
      border-radius: 20px;
      color: #333 !important;
      transition: 0.35s;
      display: block;
    }
  }
}
.fixedHeader {
  text-align: center !important;
  h3 {
    display: flex;
    align-items: center;
    width: fit-content;
    margin: 15px auto !important;
    svg {
      margin: 0 5px;
      font-size: 14px;
      display: block;
    }
    span {
      color: #ff6900;
      font-size: 14px;
    }
  }
}
.cardMeshGradient {
  background: transparent;
  position: relative;
  animation: slideRight 0.35s;
  text-align: left;
  padding: 10px 3px 10px 10px;
  border-radius: 20px;
  // box-shadow: 0 2px 8px -2px #999;
  border: 2px solid #ddd;
  height: fit-content;
  overflow: hidden;
  form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    select {
      width: fit-content;
    }
    input[type="radio"] {
      display: none;
    }
    label:last-of-type {
      margin: 0 0 0 20px;
    }
    button {
      width: fit-content;
      background: #ff6900;
      color: #fff;
      height: 100% !important;
      margin: 0 -3px 0 0;
      padding: 60px 10px;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      overflow: hidden;
      svg {
        display: block;
        font-size: 20px;
      }
    }
    p {
      text-align: left;
      width: 85%;
      line-height: 80% !important;
      color: #1a1b1c;
      small {
        color: #777;
        font-size: 14px;
      }
    }
  }
  button {
    border: none;
    border-radius: 50px;
    background: transparent;
    padding: 10px;
    color: #111;
    margin: 5px 5px 0 0;
    width: 40%;
    text-align: left;
  }
  h3 {
    color: #111;
    font-size: 30px;
    font-weight: 900;
  }
  p {
    color: #111;
    font-size: 20px;
    font-weight: 600;
  }
  label {
    color: #111;
    font-size: 20px;
    font-weight: 600;
  }
  .infoButton {
    position: absolute;
    bottom: 20px;
    right: 20px;
    padding: 0;
    margin: 0;
    border: none;
    padding: 3px;
    background: transparent;
    z-index: 2;
    width: fit-content;
    text-align: center;
    svg {
      color: #111;
      display: block;
      font-size: 28px;
    }
    .modalTooltip {
      position: relative;
      top: 0;
      left: 0;
      transform: translate(0);
      background: transparent;
      z-index: 8;

      .modalFullContent {
        transform-origin: bottom right;
        top: -440%;
        right: 83%;
        background: #fff;
        border-bottom-right-radius: 0;
        label {
          color: #777;
          font-size: 13px;
        }
      }
    }
  }
}
.ingredientNameBrand {
  width: 100%;
  &.main {
    text-align: left;
    display: flex;
    align-items: center;
    .btnIngredientInfo {
      margin: 0 12px 0 0;
      background: transparent;
      svg {
        color: #ff6900 !important;
        width: 35px !important;
        height: 35px !important;
        padding: 0;
      }
    }
    .btnIngredientRemover {
      margin: 0 0 0 24px;
      background: transparent;
      svg {
        color: #ff6900 !important;
        width: 35px !important;
        height: 35px !important;
      }
    }
    .input {
      width: fit-content;
    }
  }
  .ingredientBrand {
    font-size: 13px;
    display: block;
    width: 100%;
    text-align: left;
    color: #999;
  }
  .input {
    input {
      padding: 5px 0 !important;
      font-size: 13px;
      border: none !important;
      border-radius: 0;
      width: 80%;
    }
    input:focus,
    .active {
      background: #fce205;
    }
  }
}
.ingredientAdded {
  box-sizing: border-box;
  border-radius: 0;
  margin: 0;
  border: none;
  text-align: left;
  font-weight: 500;
  width: 140px !important;
  min-width: 140px !important;
  max-width: 140px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  white-space: normal;
  &.placeholder {
    opacity: 0.3;
  }
}
li:disabled {
  pointer-events: none;
  color: #999;
}

.ingredientsButton:disabled {
  background: transparent;
  opacity: 0.4;
}
.ingredientsButton:nth-child(even) {
  background: #f9f9f9;
}
.ingredientsButton {
  background: transparent;
  width: 100%;
  border: none;
  text-align: left;
  display: flex;
  align-items: center;
  padding: 5px 0;
  font-size: 16px;
  font-weight: 300;
  color: #1a1b1c;
  img {
    width: 40px !important;
    height: 40px !important;
    margin-right: 10px;
    background: transparent;
  }
}
.sliderBoxEmpty {
  width: 110%;
  margin-left: -5%;
  .swiper {
    padding: 0 5%;
    box-sizing: border-box;
    .swiper-slide {
      width: 100px !important;
      .carrouselItem {
        min-width: 100px;
        min-height: 100px;
        width: 100px;
        height: 100px;
        max-width: 100px;
        max-height: 100px;
        background: #f5f5f5;
        border-radius: 20px;
        border: none;
        padding: 0;
        margin: 0;
        display: block;
        box-sizing: border-box;
      }
      .carrouselItemText {
        width: 60px;
        height: 19px;
        display: block;
        background: #f5f5f5;
        border-radius: 50px;
        margin: 8px 0 0 0;
      }
    }
  }
}
.sliderBox {
  width: 110%;
  margin-left: -5%;
  .swiper {
    padding: 0 5%;
    box-sizing: border-box;
    .swiper-slide {
      width: 100px !important;
      .carrouselItem {
        padding: 0;
        margin: 0;
        width: 100px;
        height: fit-content;
        border: none;
        background: transparent;
        img {
          width: 100px;
          max-width: 100px;
          height: 100px;
          max-height: 100px;
          position: relative;
        }
        link[rel="preload"][as="image"] {
          min-width: 100px;
          min-height: 100px;
          max-width: 100px;
          max-height: 100px;
          position: relative;
          display: block;
          image {
            min-width: 100px;
            min-height: 100px;
            max-width: 100px;
            max-height: 100px;
          }
        }
      }
    }
  }
}
#recipeServings {
  border: none;
  padding: 5px 0;
  text-align: center;
  border-radius: 5px;
}
.recipeServings {
  text-align: center;
  background: #f5f5f5;
  border-radius: 10px;
  font-size: 25px !important;
  label {
    font-size: 11px;
    display: block;
  }
}
#recipePreparationTime {
  border: none;
  padding: 5px 0;
  text-align: center;
  border-radius: 5px;
}
#recipeCookingTime {
  border: none;
  padding: 5px 0;
  border-radius: 5px;
  text-align: center;
}

.listShareImage {
  font-family: "Kopenhagen";
  color: #555;
  font-size: 30px;
  text-align: center;
  span {
    color: #ff6900;
  }
}
.listShare {
  text-align: left;
  width: 100vw;
  padding: 0 50px;
  box-sizing: border-box;
  list-style: none;
  input {
    margin: 0 10px 0 0;
  }
  label {
    text-align: left;
    display: block;
    width: 90%;
  }
  li {
    display: flex;
    margin: 10px 0;
  }
}

.modalVideo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 255px;
  z-index: 4;
  iframe {
    height: 100%;
  }
}
.roadmapStartEndBtn:disabled {
  display: none;
}

.logo {
  width: 74px;
  height: 34px;
  font-family: "Kopenhagen";
  font-weight: 600;
  color: #999;
  letter-spacing: 1px;
  &.mediumThin {
    font-weight: 500 !important;
  }
  &.small {
    font-size: inherit !important;
    font-weight: 600;
    margin: 0;
  }
  &.medium {
    font-size: 30px;
    font-weight: 600;
    margin: 0;
  }
  &.large {
    font-size: 40px;
    font-weight: 600;
    margin: 0;
    width: fit-content;
    height: fit-content;
  }
  &.centered {
    margin: 0 auto;
  }
  &.standalone {
    width: 100%;
    height: fit-content;
    font-size: 40px;
    text-align: left;
    font-weight: 500;
    small {
      font-family: "Montserrat";
      font-weight: 400;
      font-size: 18px;
      display: block;
    }
  }
  span {
    color: #ff6900;
  }
}
.hidden {
  display: none !important;
}

.dayHeader {
  position: relative;
  background: #fff;
  padding: 20px 0;
  z-index: 4;
}
.updateProfile {
  .imageLabel {
    display: block;
    width: fit-content;
    margin: 0 auto;
    position: relative;
    svg {
      position: absolute;
      bottom: 5px;
      right: 5px;
      background: #999;
      padding: 5px;
      color: #fff;
      border-radius: 40%;
      display: block;
    }
  }
  input[type="file"] {
    display: none;
  }
  .profileImage {
    width: 100px;
    min-height: 100px;
    min-width: 100px;
    height: 100px;
    border-radius: 40%;
    border: 2px solid #999;
    object-fit: cover;
    font-size: 0;
  }
}
.ingredientAdder:disabled {
  display: block;
  opacity: 0.3;
}
.ingredientAdder {
  width: 100%;
  padding: 12px !important;
  border: 1px solid #ccc;
  margin: 0;
}
.userRecipes {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100vw;
  margin-left: -20px;
  border-top: 3px solid #ddd;
  a {
    &:nth-child(3n) {
      .card {
        margin-right: 0 !important;
      }
    }
  }
  a {
    .card {
      width: calc(33.333vw - 4px) !important;
      height: calc(33.333vw - 4px) !important;
      max-width: 150px;
      max-height: 150px;
      border-radius: 0 !important;
      margin: 0;
      margin-right: 5px;
      margin-bottom: 5px;
      img {
        border-radius: 0;
        object-fit: cover;
        min-width: 33.333vw !important;
        min-height: 33.333vw !important;
        width: 33.333vw !important;
        height: 33.333vw !important;
      }
    }
  }
  p {
    width: 90%;
    margin: 0 auto;
  }
}
.qrCodeBox {
  position: relative;
  width: fit-content;
  margin: 0 auto;
  border-radius: 20px;
  overflow: hidden;
  .qrCodeImage[src] {
    pointer-events: initial;
    width: 200px;
    height: 200px;
    margin: 0 auto;
    box-sizing: border-box;
    border-radius: 10px;
    display: block;
  }
  .qrCodeLogo {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50px;
    height: 30px;
    padding: 5px;
    box-sizing: border-box;
    background: #000;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    // filter:contrast(1.5);
  }
}

.flexCategories {
  table {
    width: fit-content;
    tbody {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      .hiddenValue {
        display: none;
      }
    }
  }
  .categoryBox {
    border: 1px solid #ddd;
    margin: 0;
    width: calc(47vw - 15px);
    height: calc(47vw - 15px);
    max-width: 165px;
    max-height: 165px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    margin: 0 0 10px 0;
    padding: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    span {
      position: absolute;
      bottom: 0;
      left: 0;
      background: rgba(255, 255, 255, 0.5);
      backdrop-filter: blur(10px);
      z-index: 2;
      width: 100%;
      display: block;
      padding: 10px 0;
      color: #1a1b1c;
      font-weight: 600;
    }
  }
}
.viewsText {
  margin-left: auto;
  display: flex;
  align-items: center;
  padding: 0 4px;
  svg {
    display: block;
  }
}
.recipeMethod {
  font-weight: 500;
}
.changePassword {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 20px;
  text-align: left;
  margin-bottom: 10px;
}
.deleteAccount {
  border: 1px solid #ff6900;
  padding: 10px;
  border-radius: 20px;
  text-align: left;
}
.errorText {
  color: #ff6900 !important;
}
.placeholderImage {
  width: 30vh;
  height: 30vh;
}
.flexFilter {
  display: flex;
  align-items: center;
  padding-right: 2px !important;
  svg {
    margin: 0 !important;
    margin-left: 5px !important;
    margin-right: -10px !important;
    background: #eee;
    color: #ff6900;
    display: block;
    padding: 2px;
    border-radius: 50%;
  }
  img {
    margin: 0 !important;
    margin-left: -10px !important;
    margin-right: 5px !important;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background: #f9f9f9;
  }
}
.centeredText {
  text-align: center;
  width: 100%;
}
.selectedFilter:disabled {
  display: none !important;
  height: 0;
  overflow: hidden;
}
.selectedFilter {
  margin-bottom: 10px;
  flex-shrink: 0;
  span {
    margin: 0;
    padding: 0;
    display: block !important;
  }
  label {
    margin: 0;
    padding: 0;
    display: block !important;
  }
}
.flexPreferences {
  overflow: visible;
  width: 100%;
  padding: 10px 0;
  box-sizing: border-box;
  button {
    transition: 0.35s;
  }
  button:disabled {
    background: #ddd;
    transition: 0.35s;
  }
  .flexPreferencesBox {
    display: flex;
    width: fit-content;
    width: 100%;
    box-sizing: border-box;
    .cardMeshGradient:first-of-type {
      margin-left: 0;
    }
    .cardMeshGradient:last-of-type {
      margin-right: 0;
    }
    .cardMeshGradient {
      margin: 0 5px;
      width: 100%;
      height: 150px;
      transition: 0.35s;
    }
  }
}
.usernameInput {
  input {
    text-transform: lowercase;
  }
}
.languageSelector {
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 50%;
  margin: 0;
  padding: 0;
  z-index: 2;
  .btnLanguages {
    padding: 0;
    border: none;
    background: transparent;
    margin-left: auto;
  }
  .fi {
    width: 25px;
    height: 20px;
    border-radius: 3px;
    padding: 0;
    background-size: cover;
  }
  .modalBottom {
    h3 {
      text-align: center;
      padding: 15px 0;
    }
    .fi {
      margin-right: 10px;
    }
    .btnLanguage {
      display: flex;
      align-items: center;
      background: transparent;
      border: none;
      padding: 15px 20px;
      width: 100%;
      text-align: left;
      border-bottom: 1px solid #ddd;
      p {
        width: fit-content;
        margin: 0;
      }
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      height: 65vh;
      overflow: scroll;
    }
  }
}
.resetPasswordBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    width: fit-content;
  }
}
.userDetailsBio {
  display: block;
  font-weight: 500;
  width: 100%;
  position: relative;
  transition: 0.35s;
  margin: 8px 0;
  color: #777;
  .mention {
    display: inline-block;
    width: fit-content;
    height: fit-content;
    color: #1a1b1c !important;
    margin: 0;
  }
  .moreButton {
    position: absolute;
    top: 35px;
    right: 0;
    background: #fff;
    border: none;
    font-size: 13px;
  }
}
.showFullBio {
  -webkit-line-clamp: initial;
  line-clamp: initial;
  transition: 0.35s;
}
.addRecipeErrors {
  label {
    margin: 5px 0;
    display: block;
    width: 100%;
  }
}
.textLeft {
  text-align: left;
  width: 100%;
  margin: 0;
  a {
    color: #ff6900;
  }
  &.message {
    background: #ddd;
    padding: 10px;
    border-radius: 10px;
    box-sizing: border-box;
  }
}
.textYellow {
  color: #ffae00 !important;
  transition: 0.35s linear;
}
.textOrange {
  color: #ff6900 !important;
  transition: 0.35s linear;
  &.download {
    svg {
      margin: 0 0 0 4px;
    }
  }
}
.textCapitalize {
  text-transform: capitalize;
}
.textRed {
  color: #ff1b1c !important;
  &.marginVertical {
    margin: 12px 0;
    display: block;
  }
}
.textGreen {
  color: #00af54 !important;
}
.textPurple {
  color: #8a2be2 !important;
}
.textGray {
  color: #999 !important;
  &.small {
    font-size: 13px;
  }
  &.smaller {
    font-size: 9px;
  }
}
.backgroundYellow {
  background: #ffae00 !important;
}
.backgroundOrange {
  background: #ff6900 !important;
}
.backgroundPurple {
  background: #8a2be2 !important;
}
.backgroundGreen {
  background: #00af54 !important;
}
.ellipsis {
  display: block;
  width: 140px !important;
  min-width: 140px !important;
  max-width: 140px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.light {
  font-weight: 500;
}
.textRight {
  text-align: right;
  width: 100%;
  margin: 0;
  &.message {
    background: #ddd;
    padding: 10px;
    border-radius: 10px;
    box-sizing: border-box;
  }
}
.textFlexLeft {
  display: flex;
  align-items: flex-start;
  text-align: left;
  svg {
    margin: 5px 8px 0 0;
    padding: 5px;
    flex-shrink: 0;
    font-size: 20px;
    border: 2px solid #1a1b1c;
    border-radius: 10px;
  }
  small {
    font-weight: 500;
    font-size: 15px;
    color: #777;
    strong {
      font-size: 14px;
      color: #1a1b1c;
    }
  }
}
.smallText {
  color: #777;
  a {
    color: #ff6900;
  }
}
.subscriptionHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    object-fit: cover;
    margin: 0 !important;
  }
}
.rightIcon {
  margin: 0 0 0 auto !important;
}
.selectProfileAvatar {
  border-top: 2px solid #ddd;
}
.row3 {
  margin: 0;
  width: 90%;
  margin: 0 auto;
  button:hover,
  .active,
  .focus {
    border: 2px solid #ff6900;
    transition: 0.35s;
  }
  button {
    width: 60px;
    height: 60px;
    border-radius: 10px;
    padding: 0px;
    background: #f5f5f5;
    border: 2px solid transparent;
    border-radius: 50%;
    margin: 0;
    transition: 0.35s;
    span {
      display: flex;
      align-items: center;
      img {
        width: 40px !important;
        height: 40px !important;
        margin: 0 auto;
      }
    }
  }
}
.flexSubrecipe {
  display: flex;
  p {
    width: 70%;
  }
}

.addRecipeForm {
  #recipeImage {
    display: none;
  }
}
.recipeMethodImagePlaceholder {
  width: 80px;
  height: 80px;
  background: #fff;
  display: flex;
  align-items: center;
  border-radius: 10px;
  svg {
    display: block;
    margin: 0 auto;
    font-size: 50px;
    color: #999;
  }
  span {
    display: block;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    color: #999;
    padding: 0 12px;
    box-sizing: border-box;
  }
}
.recipeMethodStep {
  position: relative;
  margin: 0 0 10px 0;
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
  #recipeMethodImage {
    display: none;
  }
  .recipeMethodStepsText {
    padding: 5px;
    display: block;
    width: calc(100% - 124px);
    margin: 0 0 0 4px;
    box-sizing: border-box;
    h5 {
      margin: 0;
      font-size: 14px;
      color: #999;
    }
  }
  .removeStepBtn {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    background: #ff1b1c;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 50%;
    svg {
      width: 15px;
      height: 15px;
      color: #fff;
      display: block;
      margin: 0 auto;
    }
  }
  p {
    text-align: left;
  }
}
.recipeMethodSteps {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #eee;
  border-radius: 10px;
  margin: 0 0 5px 0;
  padding: 5px;
  box-sizing: border-box;
  position: relative;
  span {
    width: 100%;
  }
  .removeStepBtn {
    position: absolute;
    top: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.25) !important;
  }
}
.recipeMethodStepsText {
  width: calc(100% - 80px);
  overflow: hidden;
  input {
    width: 100%;
    border: 1px solid #ccc;
  }
}
.recipeMethodStepInput {
  position: relative;
  .textLeft {
    word-wrap: break-word;
    width: 200px !important;
  }
  input {
    padding: 12px 50px 12px 12px !important;
    box-sizing: border-box;
  }
  .stepImageAdder {
    display: none !important;
  }
  .btnPrimary,
  .btnSecondary {
    position: absolute;
    top: 2px;
    right: 4px;
    width: 38px;
    height: 38px;
    border-radius: 8px;
    padding: 0;
    svg {
      display: block;
      margin: 0 auto;
      width: 60%;
      height: 60%;
    }
  }
  .btnSecondary {
    top: 45px;
  }
}
.eventsNone {
  pointer-events: none;
}
.privateAccountBox {
  width: 100%;
  svg {
    font-size: 50px;
    margin: 70px auto 0 auto;
    display: block;
    text-align: center;
    color: #777;
  }
  h3 {
    color: #777;
  }
}
.pageRecipeSkeleton {
  &:before {
    content: "";
    height: calc(100vh + 5px);
    width: 0;
    box-shadow: 0 0 40px 15px #fff;
    position: absolute;
    top: -5px;
    left: -25px;
    transform: translateX(0) rotate(8deg);
    animation: skeletonRay 1.8s linear infinite;
    z-index: 9;
  }

  .mainImage {
    height: 100vw;
    width: 100vw;
    background: #f5f5f5;
  }
  .pageRecipeText {
    .category {
      width: 60px;
      height: 22px;
      background: #f5f5f5;
      border-radius: 50px;
    }
    .name {
      width: 120px;
      height: 33px;
      background: #f5f5f5;
      border-radius: 50px;
      margin: 5px 0;
    }
    .author {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .user {
        height: 11px;
        width: 45px;
        background: #f5f5f5;
        border-radius: 50px;
        margin-left: 5px;
      }
      .views {
        height: 11px;
        width: 45px;
        background: #f5f5f5;
        border-radius: 50px;
        margin-left: auto;
      }
      .date {
        height: 11px;
        width: 32px;
        background: #f5f5f5;
        border-radius: 50px;
        margin-left: 5px;
      }
    }
    .timing {
      height: 91px;
      width: 100%;
      background: #f5f5f5;
      border-radius: 10px;
    }
  }
}
.notificationTextYellow {
  background: #fce205;
  padding: 10px 15px;
  border-radius: 20px;
  font-weight: 500;
  text-align: left;
  color: #555;
  box-sizing: border-box;
  margin: 5px 0 10px 0;
  border: none;
  font-size: 13px;
}
.notificationTextYellow:disabled {
  display: none;
}
.notification {
  display: flex;
  align-items: center;
  background: #f9f9f9;
  border-radius: 20px;
  width: 100%;
  text-align: left;
  margin: 0 0 5px 0;
  padding: 10px;
  box-sizing: border-box;
  &.empty {
    min-height: 64px;
    width: 100%;
    border-radius: 20px;
    background: #f9f9f9;
    display: flex;
    align-items: center;
    margin: 0 0 5px 0;
    .notificationText {
      display: block;
      min-width: 250px;
      min-height: 14px;
      background: #fff;
      border-radius: 50px;
    }
    .notificationDate {
      display: block;
      min-width: 85px;
      max-width: 85px;
      min-height: 10px;
      margin: 8px 0 0 0;
      background: #fff;
      border-radius: 50px;
    }
    .notificationImg {
      min-width: 43px;
      min-height: 43px;
      max-width: 43px;
      max-height: 43px;
      background: #fff;
      margin: 0 8px 0;
      display: block;
      border-radius: 40%;
    }
  }
  &.unviewed {
    animation: notificationPulse 4s linear;
  }
  &.fullWidth {
    display: flex;
    align-items: flex-start;
    button {
      width: 100%;
      border: none;
      p {
        text-align: left;
        padding: 0;
        font-weight: 400;
      }
      button {
        width: initial;
        border: 2px solid #ff6900;
      }
    }
  }
  .followRequestCta {
    margin-left: auto;
    display: flex;
    align-items: center;
    button:first-of-type {
      margin-right: 5px;
    }
  }
  .notificationImage {
    width: 43px;
    height: 43px;
    border-radius: 12px;
  }
  a:nth-child(3) {
    margin-left: auto;
  }
  a {
    button {
      padding: 0;
      margin: 0;
      width: fit-content;
      text-align: left;
      border: none;
      font-weight: 400;
      display: block;
    }
    .roleIndicatorBox {
      margin-right: 10px;
      width: 44px;
      height: 44px;
      display: block;
      img {
        width: 35px !important;
        height: 35px !important;
        min-width: 35px;
        min-height: 35px;
        border: 2px solid #fff;
        border-radius: 40% !important;
        background: #fff;
        margin: 2px 0 0 2px;
        object-fit: cover;
      }
      .userBackground,
      .creatorBackground,
      .proBackground,
      .businessBackground,
      .adminBackground {
        width: 43px;
        height: 43px;
        border-radius: 40% !important;
      }
    }
  }
  h5 {
    margin: 0;
  }
  p {
    margin: 0;
    font-size: 13px;
    display: block;
    min-width: 80px;
    padding-right: 8px;
  }
  small {
    font-size: 11px;
    color: #999;
  }
  button {
    background: transparent;
    padding: 0;
    svg {
      color: #999;
      display: inline-block;
    }
  }
}
//AUTH CONGRATS
.confetti-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  z-index: 9;
}
.confetti {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  top: -50px;
  left: 50%;
  animation: confetti linear 5s;
  background-color: random-color();
}
@function random-color() {
  $colors: #ff7675, #74b9ff, #55efc4, #fdcb6e, #a29bfe, #ff6b81, #00cec9,
    #fd79a8, #00b894, #ffeaa7, #0984e3, #6c5ce7, #fab1a0, #55efc4, #ff9f43;
  $index: random(length($colors));
  @return nth($colors, $index);
}
.bottomBtn {
  width: 100%;
  position: absolute;
  bottom: 10px;
  left: 0;
  padding: 0 10px;
  box-sizing: border-box;
  z-index: 8;
  .flexBtn {
    display: flex;
    align-items: center;
    width: 100%;
    background: #f5f5f5;
    border-radius: 50px;
    button:first-of-type {
      position: relative;
      svg {
        position: absolute;
        top: 0;
        left: 10px;
        height: 100%;
      }
    }
    button:last-of-type {
      svg {
        position: absolute;
        top: 0;
        right: 10px;
        height: 100%;
      }
    }
  }
}
.notificationBullet {
  border: 0;
  position: absolute;
  bottom: -8px;
  left: 12px;
  background: #ff6900 !important;
  color: #fff;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  transform: scale(1);
  transition: 0.35s;
  // box-sizing: content-box;
  margin: 0;
  padding: 0;
}
.leftTable {
  text-align: left;
  h3 {
    text-align: center !important;
  }
  tr {
    border-bottom: 1px solid #eee;
    .box {
      padding: 10px 10px 10px 0;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      p {
        margin: 0;
      }
      button {
        background: transparent;
        margin-left: auto;
        border: 0;
        padding-right: 0;
        margin-right: 0;
        svg {
          display: block;
          font-size: 20px;
        }
      }
    }
  }
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 20px;
    object-fit: cover;
  }
}
.recipeWebsite {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.scrolledHeader {
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 5vw;
  box-sizing: border-box;
  z-index: 5;
  background-color: #fff;
  transition: top 0.35s ease-in-out;
}
.longText {
  align-items: baseline;
  span {
    width: 80%;
    margin: 0 !important;
  }
  small {
    word-wrap: wrap;
    color: #777;
  }
}

.businessLogo {
  width: 80px;
  height: 60px;
  object-fit: cover;
}

//NUTRITION
.nutritionPage {
  &.new {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.35s linear, opacity 0.35s linear;
    opacity: 0;
  }
  &.new.open {
    max-height: 500px;
    transition: max-height 0.35s linear, opacity 0.35s linear;
    opacity: 1;
  }
  .flexShare {
    .flexShareBtn {
      width: fit-content;
      span {
        width: fit-content;
        height: fit-content;
      }
      svg {
        border-radius: 50%;
        box-sizing: border-box;
        background: #fff !important;
      }
      &.protein {
        svg {
          border: 6px solid #5ac8fb !important;
        }
      }
      &.fiber {
        svg {
          border: 6px solid #34c85a !important;
        }
      }
      &.sugar {
        svg {
          border: 6px solid #ffcc00 !important;
        }
      }
      &.salt {
        svg {
          border: 6px solid #ff9501 !important;
        }
      }
      &.fat {
        svg {
          border: 6px solid #ff3830 !important;
        }
      }
    }
  }
  .nutritionCounter {
    width: 150px;
    height: 150px;
    position: relative;
    margin: 10px auto;
    .calorieCounter {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100px;
      height: 100px;
      border-radius: 50%;
      border: 10px solid #5855d6;
      z-index: 2;
      background: #fff;
      small {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -49%);
        font-weight: 500;
        font-size: 11px;
        line-height: 0.9;
        color: #555;
        span {
          font-size: 23px;
          font-weight: 600;
        }
      }
    }
    .selectedCounter {
      width: 150px;
      height: 150px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
      }
      .emptyStat {
        background: #f5f5f5;
        border-radius: 50%;
        width: 150px;
        height: 150px;
        z-index: 1;
      }
      .middleStat {
        width: 130px;
        height: 130px;
        background: #fff;
        z-index: 3;
      }
      .progressStat {
        width: 150px;
        height: 150px;
        background: conic-gradient(transparent 0deg, transparent 0deg);
        transition: 0.35s linear;
        z-index: 2;
      }
    }
  }
}
//SUBSCRIBE CARDS
.cardSubscribe {
  padding: 0 5% 20px 5%;
  box-sizing: border-box;
  background: transparent;
  text-align: left;
  width: 100%;
  height: fit-content;
  .popularPlanRibbon {
    background: linear-gradient(45deg, #add100, #7b920a);
    color: #fff;
    position: absolute;
    top: 20px;
    right: -30px;
    padding: 5px 0;
    display: block;
    width: 130px;
    height: fit-content;
    text-align: center;
    transform: rotate(45deg);
    font-weight: 600;
  }
  h2 {
    font-weight: 700 !important;
    .badgeActive {
      font-size: 11px;
      background: linear-gradient(45deg, #add100, #7b920a);
      color: #fff;
      padding: 3px 5px;
      border-radius: 50px;
      font-weight: 500;
    }
  }
  p {
    color: #777;
  }
  ul {
    list-style: none;
    padding: 0;
    text-align: left;
    li {
      padding: 5px 0;
      font-size: 16px;
      display: flex;
      align-items: center;
      position: relative;
      svg {
        display: block;
        margin: 0 10px 0 0;
      }
    }
  }
  &.starter {
    border: 2px solid #ddd;
    border-radius: 30px;
    h2 {
      background-image: linear-gradient(-45deg, #999999, #777);
      background-clip: text;
      // text-fill-color: transparent;
      color: transparent;
    }
  }
  &.creator {
    border: 2px solid #ddd;
    border-radius: 30px;
    h2 {
      background-image: linear-gradient(45deg, #b993d6, #8ca6db);
      background-clip: text;
      // text-fill-color: transparent;
      color: transparent;
    }
  }
  &.pro {
    border: 2px solid #add100;
    border-radius: 30px;
    h2 {
      background-image: linear-gradient(45deg, #add100, #7b920a);
      background-clip: text;
      // text-fill-color: transparent;
      color: transparent;
    }
  }
  &.business {
    border: 2px solid #ddd;
    border-radius: 30px;
    h2 {
      background-image: linear-gradient(45deg, #00c6ff, #0072ff);
      background-clip: text;
      color: transparent;
    }
  }
}
.modalTooltip {
  position: absolute;
  top: 50%;
  left: 95%;
  transform: translate(0, -50%);
  background: transparent;
  z-index: 7;
  svg {
    margin: 0;
    color: #999;
    font-size: 14px;
  }
}
.modalFullContent {
  position: relative;
  z-index: 9;
  transform: scale(0);
  transition: 0.35s;
  transform-origin: top right;
  position: absolute;
  top: 16px;
  right: 12px;
  width: 160px;
  height: fit-content;
  padding: 5px 10px;
  border-radius: 10px;
  font-weight: 600;
  background: #ddd;
  &.show {
    transform: scale(1);
  }
}
//PROGRESS
.progressContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 65px;
  position: relative;
  .progressCover {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background: #fff;
    h3 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
      font-size: 9px;
    }
  }
  .progressChart {
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #f2f2f2;
    overflow: hidden;
  }
  .progressCircle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: conic-gradient(limegreen 0deg, transparent 0);
    transform-origin: center;
    transition: background 0.3s ease-in-out;
  }
}
.textLimit {
  p {
    text-align: right !important;
    margin-top: -10px;
    font-size: 11px;
    color: #777;
  }
}
.capitalizeText {
  text-transform: capitalize;
}
.addRecipeHeaderBtn {
  background: transparent;
  border: none;
  color: #ff6900;
  font-size: 13px;
  display: flex;
  align-items: center;
  svg {
    display: block;
    margin-right: 5px;
    font-size: 10px;
  }
}
.addImageContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  input {
    display: none;
  }
  .centeredImagelabel {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    color: #ccc;
    svg {
      font-size: 45px;
      color: #ccc;
    }
  }
  label {
    display: block;
    height: 100vw;
    width: 100vw;
    background: #f9f9f9;
    z-index: 1;
  }
  .croppingResult {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    label {
      margin-left: 0;
    }
    img {
      width: 100vw;
      height: 100vw;
    }
  }
}

.higlightText {
  color: #ff6900;
  margin-left: 5px;
}
.categoriesSkeleton {
  .navSecondary {
    h1 {
      width: 100%;
      margin: 20px 0;
      .text {
        min-width: 180px;
        width: 180px;
        min-height: 24px;
        height: 24px;
        display: block;
        background: #f5f5f5;
        margin: auto !important;
        border-radius: 50px;
      }
      img {
        background: #222;
      }
    }
  }
  .inputSearch {
    min-width: 100%;
    min-height: 39px;
    background: #f5f5f5;
    border-radius: 10px;
  }
  .flexCategories {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .categoryBox {
      border: 1px solid #ddd;
      margin: 0;
      min-width: calc(47vw - 15px);
      height: calc(47vw - 15px);
      max-width: 165px;
      max-height: 165px;
      border-radius: 10px;
      overflow: hidden;
      position: relative;
      margin: 0 0 10px 0;
      padding: 0;
      background: linear-gradient(-45deg, #ddd, #fff);
      box-shadow: 0 2px 6px -2px #999;
      span {
        position: absolute;
        bottom: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(10px);
        z-index: 2;
        width: 100%;
        display: block;
        padding: 10px 0;
        color: #1a1b1c;
        font-weight: 600;
        p {
          min-width: 100px;
          width: 100px;
          min-height: 22px;
          height: 22px;
          background: #ddd;
          border-radius: 50px;
          margin: 0 auto;
        }
      }
    }
  }
}
.userProfileSkeleton {
  .navSecondary {
    h1 {
      width: 100%;
      margin: 20px 0;
      .text {
        min-width: 100px;
        width: 100px;
        min-height: 24px;
        height: 24px;
        display: block;
        background: #f5f5f5;
        margin: auto !important;
        border-radius: 50px;
      }
    }
  }
  .name {
    width: 63px;
    height: 24px;
    background: #f5f5f5;
    border-radius: 50px;
    margin-top: 25px;
  }
  .bio {
    width: 200px;
    height: 18px;
    background: #f5f5f5;
    border-radius: 50px;
    margin-top: 13px;
  }
  .cta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    width: 100%;
    span {
      border-radius: 10px;
      display: block;
    }
    .followBtn {
      background: #f5f5f5;
      width: calc(100% - 82px);
      height: 38px;
      border-radius: 50px;
    }
    .messageBtn {
      background: #f5f5f5;
      width: 38px;
      height: 38px;
      border-radius: 50px;
    }
  }
  .flexHeaderAccount {
    .profileImageBox {
      width: 78px;
      height: 78px;
      background: #f5f5f5;
      border-radius: 40%;
    }
    .userStats {
      width: calc(100% - 84px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: auto;
      button {
        border: none;
      }
      .count {
        min-width: 25px;
        min-height: 30px;
        width: 25px;
        height: 30px;
        display: block;
        background: #f5f5f5;
        border-radius: 20px;
        margin: 0 auto;
      }
      .text {
        min-width: 55px;
        width: 55px;
        min-height: 14px;
        display: block;
        background: #f5f5f5;
        margin: 5px auto 0 auto;
        border-radius: 50px;
      }
    }
  }
  .tabs {
    width: 25%;
    height: 22px;
    margin-bottom: 8px;
    background: #f5f5f5;
    display: block;
    border-radius: 50px;
  }
  .userRecipes {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100vw;
    max-width: 500px;
    margin-left: -20px;
    border-top: 3px solid #f5f5f5;
    .card:nth-child(3n) {
      margin-right: 0 !important;
    }
    .card {
      background: #f5f5f5;
      width: calc(33.333vw - 4px) !important;
      height: calc(33.333vw - 4px) !important;
      max-width: 150px;
      max-height: 150px;
      border-radius: 0 !important;
      margin: 0;
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }
}
.swiper-slide {
  overflow: hidden;
  box-sizing: border-box;
}
.paymentHistoryCard {
  background: #f5f5f5;
  width: 100%;
  min-height: 100px;
  height: fit-content;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 20px;
  position: relative;
  p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: fit-content;
    padding: 0;
    margin: 0;
  }
}
.headerImage {
  width: 80%;
  max-width: 400px;
  margin: 0 auto;
  &.small {
    width: 50%;
  }
}
.swiper-container-free-mode > .swiper-wrapper {
  transition-timing-function: linear;
}
.swiper-free-mode > .swiper-wrapper {
  transition-timing-function: linear !important;
}
.chat {
  background: #fff;
  height: 65vh;
  .chatMessages {
    height: fit-content;
    max-height: 60vh;
    background: #fff;
    position: fixed;
    bottom: 80px;
    left: 0;
    width: 100%;
    overflow: scroll;
    padding: 0 10px;
    box-sizing: border-box;
    .chatMessage {
      display: flex;
      align-items: flex-end;
      margin-bottom: 5px;
      &.hiddenMessage {
        display: none;
      }
      &.sender {
        img {
          width: 25px;
          height: 25px;
          margin-left: 5px;
          border-radius: 50%;
        }
        .chatMessageText {
          text-align: right;
          margin: 0 0 0 auto;
          box-sizing: border-box;
          word-break: break-all;
          p {
            text-align: right;
            background: #ff6900;
            padding: 10px;
            box-sizing: border-box;
            border-radius: 15px 15px 0 15px;
            min-width: fit-content;
            width: fit-content;
            max-width: 80%;
            margin: 0 0 0 auto;
            display: block;
            font-weight: 500;
            color: #fff;
          }
          small {
            display: block;
            width: 100%;
            text-align: right;
            margin-top: 5px;
            color: #999;
            animation: scaleUp 0.35s linear;
          }
        }
      }
      &.receiver {
        img {
          width: 25px;
          height: 25px;
          margin-right: 5px;
          border-radius: 50%;
        }
        .chatMessageText {
          text-align: right;
          margin: 0 auto 0 0;
          box-sizing: border-box;
          p {
            text-align: left;
            background: #eee;
            padding: 8px 10px;
            border-radius: 15px 15px 15px 0;
            box-sizing: border-box;
            margin: 0;
            min-width: fit-content;
            max-width: 80%;
            display: block;
            font-weight: 500;
          }
          small {
            display: block;
            width: 100%;
            text-align: left;
            margin-top: 5px;
            color: #999;
            animation: scaleUp 0.35s linear;
          }
        }
      }
    }
  }

  .flexSearch {
    position: fixed;
    bottom: 0;
    left: 0;
    background: #fff;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    margin: 0;
    input,
    .input {
      margin: 0;
    }
    .searchSubmitButton {
      top: 4px;
      right: 4px;
      background: #ff6900;
      transition: 0.35s linear;
      height: 45px;
      width: 45px;
      &:disabled {
        opacity: 0.3;
        transition: 0.35s linear;
      }
      svg {
        color: #fff;
      }
    }
  }
}
.followRequestButtons {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 8px;
  .btnPrimary {
    background: #ff6900;
    border: 2px solid #ff6900;
    color: #fff;
    border-radius: 5px;
    padding: 5px;
    font-size: 13px;
    margin-left: 5px;
  }
  .btnSecondary {
    background: transparent;
    border: 2px solid #ff6900;
    color: #ff6900;
    border-radius: 5px;
    padding: 5px;
    font-size: 13px;
    margin-left: auto;
  }
}
.compareList {
  .flexShareBtn:last-of-type {
    margin-right: 0;
  }
  .flexShareBtn {
    margin-right: 5px;
    padding: 10px;
    box-sizing: initial;
    border: 1.5px solid #999;
    border-radius: 20px;
  }
}
.cardCompare {
  .tableCompare {
    width: 100%;
    display: block;
    overflow: hidden;
    tbody {
      display: block;
      overflow: scroll;
      width: 100%;
      tr {
        display: flex;
        width: fit-content;
        td {
          background: #f5f5f5;
          display: block;
          width: 120px;
          font-size: 16px;
          border-radius: 20px;
          margin-right: 8px;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
.bulletAddedToList {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25px;
  height: 25px;
  background: rgba(0, 0, 0, 0.2);
  display: block;
  border-radius: 50%;
  z-index: 9;
  animation: fadeIn linear 0.35s;
}
//BUSINESS
.businessApp {
  height: 100vh;
  max-height: 100vh;
  overflow: auto;
  padding: 0 0 80px 0;
  box-sizing: border-box;
  h1 {
    margin: 8px 0;
    font-size: 28px;
  }
  h5 {
    color: #555;
  }
  .tabButtonPrimary {
    h3 {
      padding: 0 12px 0 0;
      color: #999 !important;
    }
    &.active {
      h3 {
        color: #ff6900 !important;
      }
    }
  }
  .businessFacilities {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    span {
      display: flex;
      align-items: center;
      width: fit-content;
      padding: 8px;
      background: #f5f5f5;
      margin: 0 4px 4px 0;
      border-radius: 50px;
      height: fit-content !important;
      font-size: 13px;
      transition: 0.35s linear;
      font-weight: 600;
      color: #333;
      svg {
        margin: 0 4px 0 0;
        display: block;
        font-size: 20px;
      }
    }
  }
  .appMenuButton {
    position: fixed;
    bottom: 60px;
    right: 20px;
    z-index: 2;
    border-radius: 50%;
    padding: 0;
    border: 0;
    background: #ff6900;
    animation: popUp 0.35s linear;
    svg {
      display: block;
      padding: 15px;
      font-size: 20px;
      color: #fff;
    }
  }
  .appLogoButton {
    position: absolute;
    top: 150px;
    left: 20px;
    height: 30vw;
    width: 30vw;
    max-height: 30vw;
    max-width: 30vw;
    background: rgba(255, 255, 255, 0.7);
    border: none;
    border-radius: 30%;
    z-index: 8;
  }
  .appBackgroundButton {
    width: 100%;
    height: 200px;
    position: fixed;
    top: 0;
    left: 0;
    padding: 0;
    border: none;
    border-radius: 0;
    img {
      object-fit: contain;
    }
  }
  .appLogo {
    min-height: 120px;
    min-width: 120px;
    max-height: 120px;
    max-width: 120px;
    z-index: 2;
    background: #fff;
    padding: 0;
    border: 2px solid #999;
    border-radius: 50%;
    overflow: hidden;
    padding: 2px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin: -50px 0 50px 5%;
    position: relative;
    img {
      object-fit: contain;
      height: 100%;
      width: 100%;
      z-index: 2;
      padding: 5px;
      box-sizing: border-box;
      border-radius: 50%;
    }
  }
  .menuPhoto {
    position: absolute;
    top: 95px;
    left: 50%;
    transform: translateX(-50%);
    height: calc(100vh - 225px);
    width: 90%;
    border: 2px dashed #999;
    display: block;
    border-radius: 30px;
  }
  .menuImageModal {
    position: absolute;
    top: 95px;
    left: 50%;
    transform: translateX(-50%);
    height: calc(100vh - 225px);
    width: 90%;
    border: 2px dashed #999;
    display: block;
    border-radius: 30px;
  }
  .appFooter {
    color: #999;
    display: block;
    padding: 0;
    margin: 0;
    span {
      font-family: "Kopenhagen";
      // font-weight:900;
      color: #999;
      span {
        color: #ff6900;
      }
    }
  }
  .appMenu {
    margin: 0 auto;
    padding: 0;
    background: #eee;
    min-height: 80vh;
    min-width: 100%;
    max-height: 80vh;
    max-width: 100%;
    border: 0;
    border-radius: 20px;
    overflow: hidden;
    img {
      width: 100%;
      height: 80vh;
      object-fit: cover;
      object-position: center;
    }
  }
  .appBackground {
    width: calc(100% + 10px);
    min-width: 100%;
    height: 200px;
    object-fit: stretch;
    display: block;
    padding: 0;
    border: 0;
    z-index: 4;
    margin-left: -5px;
    img {
      height: 100%;
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      margin: 0;
      object-fit: cover;
      object-position: center;
      z-index: 2;
      border-radius: 0 0 50% 50% / 0 0 30% 30%;
    }
  }
  .appSpacer {
    height: 200px;
    width: 100%;
    display: block;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  h2 {
    text-align: left;
    word-wrap: break-word;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    position: relative;
    small {
      font-size: 13px;
      display: block;
    }
  }
  h3 {
    text-align: left;
  }
  .modalBottom {
    h3 {
      text-align: center;
    }
  }
  .flexShare {
    margin: 0;
    width: 100%;
  }
  .flexHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      padding: 5px 10px;
      margin: 0 2px;
      border: none;
      svg {
        display: block;
      }
    }
    button:last-of-type {
      margin: 0 0 0 2px;
    }
    button:disabled {
      transform: scale(1);
      background: #eee;
      svg {
        color: #ccc;
      }
    }
  }
  .btnPrimary:disabled {
    opacity: 0.5;
  }
  .btnTertiary {
    position: relative;
  }
  img {
    display: none;
    font-size: 0;
  }
  img[src] {
    display: block;
    font-size: 0;
    width: 100%;
  }
  .cardBusinessRecipe {
    background: #f5f5f5;
    border-radius: 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    text-align: left;
    img {
      min-height: 80px;
      min-width: 80px;
      max-height: 80px;
      max-width: 80px;
      background: #fff;
      border-radius: 40%;
      margin-right: 10px;
    }
    .cardBusinessRecipeText {
      width: 100%;
      display: block;
      .flexHeader {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      p {
        text-align: left;
        margin: 0;
        margin-top: 10px;
        display: flex;
        align-items: center;
      }
    }
  }
  .modal {
    .page {
      padding: 95px 0 20px 0;
      box-sizing: border-box;
      width: 100%;
    }
  }
  .addBusinessRecipeBtn {
    border: 2px solid #aaa;
    border-radius: 50%;
    background: transparent;
    width: 44px;
    height: 44px;
    padding: 5px;
    box-sizing: border-box;
    svg {
      width: 100%;
      height: 100%;
      display: block;
      color: #aaa;
    }
  }
}
.monthYearSelector {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 0 0;
  div:nth-child(2) {
    width: 30%;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 69%;
    text-align: center;
    padding: 0;
    background: #fff;
    border-radius: 50px;
    box-sizing: border-box;
    border: 2px solid #f1f1f1;
    select:focus {
      border: none;
      outline: none !important;
    }
    select {
      border: none;
      border-radius: 50px;
      padding: 4px;
      font-weight: 600;
      text-align: center;
      font-size: 13px;
      outline: none;
      option {
        font-weight: 600;
      }
      svg {
        display: block;
      }
    }
  }
}
.bookingBox {
  .flexBox {
    .input {
      min-width: 49%;
      width: 49%;
    }
  }
  h5 {
    margin: 0;
  }
  h3 {
    small {
      text-transform: capitalize;
    }
  }
  .weekHeader:last-of-type {
    color: #ff1b1c !important;
  }
  .weekDay:last-of-type {
    color: #ff1b1c !important;
  }
  button:disabled {
    opacity: 0.3 !important;
  }
}
.cartImage {
  min-width: 45px;
  min-height: 45px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 50%;
  object-fit: cover;
}
.cartQuantitySelector {
  background: #f5f5f5;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span:nth-child(2) {
    width: 50px;
    display: block;
    text-align: center;
  }
}
.programCalendar {
  &.horizontal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .days {
      width: fit-content;
      margin: 0 !important;
      padding: 0;
      transform: scale(0.9);
    }
  }
  .times {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 59px);
    .input {
      min-width: 49%;
      max-width: 49%;
      transform: scale(0.9);
      border-radius: 8px;
    }
  }
  .days {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    margin: 4px 0;
    background: #fff;
    padding: 12px;
    box-sizing: border-box;
    border-radius: 12px;
    &:first-of-type {
      margin: 0 0 4px 0;
    }
    &:last-of-type {
      margin: 4px 0 0 0;
    }
    input[type="checkbox"] ~ label {
      border: 2px solid #eee;
      display: block;
      width: 29px;
      height: 29px;
      padding: 8px;
      border-radius: 10px;
      transition: 0.35s;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      p {
        display: block;
        margin: 0;
        font-size: 20px;
        small {
          color: #999;
        }
      }
    }
    input {
      border-radius: 8px;
      background: #f9f9f9 !important;
      border: none;
    }
    input:checked ~ label {
      border: 2px solid #ff6900;
      transition: 0.35s;
      p {
        color: #ff6900;
        small {
          color: #ff6900;
        }
      }
    }
  }
}
//NUTRITION
.formNutrition {
  input[type="radio"] {
    display: none;
  }
  .input {
    min-width: 49%;
    max-width: 49%;
  }
  .flexWideRadio {
    margin: 0 0 5px 0;
    input:checked ~ label {
      background: #ff6900;
      transition: 0.35s;
      span {
        p {
          small {
            transition: 0.35s;
            color: #fff;
          }
        }
      }
    }
    label {
      display: block;
      width: 100%;
      text-align: left;
      background: #f5f5f5;
      padding: 10px;
      border-radius: 20px;
      box-sizing: border-box;
      transition: 0.35s;
    }
  }
}
.textEllipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100px;
  display: block;
}
.userHeaderBtn {
  .fi {
    width: 22px !important;
    height: 17px;
    margin: 3px 0 0 -1px !important;
    border-radius: 4px;
    overflow: hidden;
  }
  &.rotated {
    svg {
      transform: rotate(45deg) !important;
      transition: 0.35s;
    }
  }
  span {
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translate(-50%, 0);
    background: limegreen;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 13px;
  }
  svg {
    transition: 0.35s;
  }
}
//CARROUSEL BOTTOM RELATED POSTS
.carrouselBottom {
  width: 100%;
  overflow: scroll;
  .carrouselBottomScroll {
    display: flex;
    // align-items: center;
    width: fit-content;
    .carrouselBottomItem:first-of-type {
      img {
        margin-left: 0;
      }
    }
    a:last-of-type {
      .carrouselBottomItem {
        padding: 0;
      }
    }
    .carrouselBottomItem {
      padding: 0 10px 0 0;
      .textEllipsis {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: wrap;
        margin: 8px 0 0 0;
      }
      .flexMenuImagesScroll {
        display: flex;
        max-width: 100px;
        overflow: auto;
        border-radius: 20px;
        img {
          // margin: 0 5px 0 0;
          border-radius: 0;
        }
      }
      img {
        display: block;
        min-width: 100px !important;
        min-height: 100px;
        width: 100px;
        height: 100px;
        max-width: 100px;
        max-height: 100px;
        background: #f5f5f5;
        border-radius: 20px;
        // margin: 0 5px;
      }
      span {
        font-size: 14px;
        color: #999;
        // padding: 0 5px;
      }
    }
  }
}
.foodsList {
  ul {
    list-style: none;
    list-style-type: none;
    padding: 0;
    margin: 0;
    min-height: 324px;
    li {
      background: #f5f5f5;
      margin: 5px 0;
      text-align: left;
      padding: 5px;
      border-radius: 10px;
      font-weight: 500;
      display: flex;
      align-items: center;
      img {
        width: 30px;
        height: 30px;
        margin-right: 8px;
      }
    }
    ul {
      max-height: 350px;
      overflow: scroll;
      li {
        justify-content: space-between;
      }
    }
  }
  .pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    button:disabled {
      opacity: 0.3;
    }
    button {
      background: transparent;
      border: 2px solid #ff6900;
      border-radius: 50px;
      color: #ff6900;
      min-width: 85px;
    }
    span {
      display: block;
      font-weight: 600;
      font-size: 13px;
      color: #999;
    }
  }
}
//USER PAGE STYLES
.userProfileCta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .messageBtn {
    width: 51px !important;
    height: 51px;
    background: #eee;
    color: #ff6900;
    border-radius: 50px;
    svg {
      font-size: 20px;
    }
  }
  .userActionBtn {
    min-width: calc(100% - 108px);
  }
  .btnSecondary {
    width: 100%;
    padding: 12px 0;
    border-radius: 50px;
  }
  button {
    width: calc(100% - 108px);
    height: fit-content;
    background: transparent;
    border: none;
    border-radius: 10px;
    color: #999;
    position: relative;
    overflow: hidden;
    transition: 0.35s;
    font-size: 16px;
    opacity: 1;
    &.fullWidth {
      width: 100%;
    }
    &.mediumWidth {
      width: calc(100% - 44px);
    }
    span {
      transition: 0.35s;
      display: inline-block;
      padding: 8px 0;
      width: 100%;
    }
  }
}
//ANIMATIONS
@keyframes skeletonRay {
  0% {
    transform: translateX(-110vw) rotate(8deg);
  }
  100% {
    transform: translateX(110vw) rotate(8deg);
  }
}
@keyframes notificationPulse {
  0% {
    background: #ddd;
  }
  33% {
    background: #f9f9f9;
  }
  66% {
    background: #ddd;
  }
  100% {
    background: #f9f9f9;
  }
}
@keyframes confetti {
  from {
    transform: translateX(-50%) translateY(0);
  }
  to {
    $randomX: random(100) - 50;
    transform: translateX(#{$randomX}vw) translateY(110vh);
  }
}
@keyframes slideLeft {
  0% {
    transform: translateX(40px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideUp {
  from {
    transform: translate(0, 100%);
  }
  to {
    transform: translate(0, 0);
  }
}
@keyframes emptyState {
  from {
    opacity: 0.3;
    transform: translate(0, 0) rotate(20deg);
  }
  to {
    transform: translate(500%, 0) rotate(20deg);
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes pulse {
  0% {
    transform: scale(0.95) translate(-50%, -50%);
    box-shadow: 0 0 0 0 rgba(235, 69, 17, 0.7);
  }

  70% {
    transform: scale(1) translate(-50%, -50%);
    box-shadow: 0 0 0 10px rgba(235, 69, 17, 0);
  }

  100% {
    transform: scale(0.95) translate(-50%, -50%);
    box-shadow: 0 0 0 0 rgba(235, 69, 17, 0);
  }
}
@keyframes bounce {
  0% {
    transform: scale(0);
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes slideRight {
  from {
    transform: translate(100%, 0);
    opacity: 0.7;
  }
  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes scaleUp {
  from {
    transform: scale(0);
    transform-origin: right top;
  }
  to {
    transform: scale(1);
    transform-origin: right top;
  }
}
@keyframes popUp {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.compareList {
  overflow: auto;
  max-height: 150px;
  .compareListItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
    margin: 0 0 3px 0;
    // padding: 5px;
    &.main {
      font-weight: 600;
      margin-right: 5px;
      span {
        background-color: #ff6900;
        color: #fff;
      }
    }
    span:first-of-type {
      margin-left: 0;
    }
    span {
      min-width: 150px;
      max-width: 150px;
      text-align: left;
      padding: 5px;
      background-color: #f5f5f5;
      border-radius: 5px;
      margin-left: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      button {
        padding: 0;
        border: none;
        background: transparent;
      }
      svg {
        background: #ff6900;
        padding: 3px;
        border-radius: 3px;
        color: #fff;
        display: block;
      }
    }
  }
}
.listImage {
  padding: 20px;
  min-height: fit-content;
  min-width: 100%;
  height: fit-content;
  background: #fff;
  box-sizing: border-box;
  display: block;
  text-align: left;
  position: relative;
  overflow: hidden;
  .shoppingListItem:last-of-type {
    border-bottom: none;
  }
  .shoppingListItem {
    border-bottom: 1px solid #eee;
  }
  .listItems {
    padding: 30px 0;
  }
  .listImageImage {
    position: absolute;
    top: -50px;
    right: -50px;
    width: 200px;
    height: 200px;
    transform: rotate(-140deg);
  }
  &.hiddenShoppingList {
    display: none;
  }
  button {
    background: transparent;
    border: none;
  }
  h3 {
    width: fit-content;
  }
}
.textLarge {
  font-size: 40px;
  color: #fff !important;
  &.fontTheme {
    margin: 12px 0;
    text-align: left;
    color: #1a1b1c !important;
    span {
      color: #ff6900;
      font-weight: 700;
    }
  }
}
.textStrong {
  font-weight: 500;
  strong {
    font-weight: 900;
    font-family: "Montserrat";
    background: linear-gradient(45deg, rgb(255, 230, 7), rgb(255, 97, 6));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
  }
}

@keyframes fadeInNow {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.foodGptChat {
  animation: fadeInNow 0.5s;
  min-height: 500px;
  max-width: 100%;
  max-height: calc(98vh - 120px);
  padding: 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  .textRight {
    margin: 0 0 12px auto;
    width: fit-content;
    max-width: 90%;
    background: #ff6900;
    padding: 10px;
    font-weight: 500;
    border-radius: 20px 20px 0 20px;
    p {
      margin: 0;
      color: #fff;
    }
  }
  .textLeft {
    margin: 0 auto 12px 0;
    width: fit-content;
    max-width: 90%;
    background: #eee;
    padding: 10px;
    border-radius: 8px;
    border-radius: 20px 20px 20px 0;
    p {
      margin: 0;
      font-weight: 500;
    }
  }
}

.adsbygoogle {
  border: 2px solid #f5f5f5;
  border-radius: 20px;
}
.centeredPlaceholder {
  &.chatbot {
    position: absolute;
    bottom: 77px;
    left: 0;
    width: 100%;
    overflow: hidden;
    .textPreMade {
      display: flex;
      align-items: center;
      width: fit-content;
      overflow: auto;
      .textPreMadeCard {
        border: 1px solid #999;
        border-radius: 8px;
        text-align: left;
        min-width: calc(28% - 2px);
        width: calc(28% - 2px);
        max-width: 200px;
        min-height: 130px;
        padding: 12px;
        box-sizing: border-box;
        margin: 0 4px;
        background: transparent;
        display: flex;
        align-items: flex-start;
        &:first-of-type {
          margin: 0 4px 0 20px;
        }
        &:last-of-type {
          margin: 0 203px 0 4px;
        }
        svg {
          font-size: 20px;
          color: #ff6900;
        }
        p {
          padding: 0;
          margin: 0;
          color: #555;
          font-size: 13px;
          font-weight: 500;
        }
      }
    }
  }
  small {
    text-wrap: wrap;
    width: 80%;
    height: 50px;
    display: inline-block;
    word-break: break-word;
    white-space: normal;
    text-align: center;
    margin: 0 auto;
    color: #555;
  }
}
.completeProfile {
  text-align: left;
  padding-bottom: 60px;
  animation: fadeIn 0.35s linear;
  // select {
  //   width: fit-content;
  // }
  .flexButton {
    position: fixed;
    bottom: 0;
    left: 0;
    &.btnThree {
      min-width: 100% !important;
      justify-content: space-between !important;
      background: #fff;
      padding: 20px;
      box-sizing: border-box;
      z-index: 2;
      button {
        padding: inherit;
      }
    }
  }
  .switch {
    border: none !important;
    .sliderPrivacy {
      svg:last-of-type {
        color: #1a1b1c;
      }
    }
  }
  .flexBox {
    &.wrap {
      label {
        padding: 4px 12px 4px 8px;
        margin: 0 5px 5px 0;
        border: 2px solid #ddd;
      }
    }
  }

  .slide {
    &.one,
    &.two,
    &.three,
    &.four {
      .flexShareBtn {
        label {
          display: inherit;
        }
      }
      label {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        border-radius: 50px;
        transition: border 0.35s linear;
        width: fit-content;
        &.checked {
          border: 2px solid #ff6900;
          transition: border 0.35s linear;
          small {
            color: #ff6900;
            transition: 0.35s linear;
          }
          svg {
            color: #34c85a;
            transition: 0.35s linear;
          }
        }
        input[type="radio"] {
          margin: 0 8px 0 0;
          display: none;
        }
        small {
          font-size: 16px;
          font-weight: 600;
          display: block;
          line-height: 15px;
          color: #777;
          transition: 0.35s linear;
        }
        svg {
          font-size: 25px;
          color: #ddd;
          margin: 0 4px 0 0;
          transition: 0.35s linear;
        }
      }
    }
  }
}
.alertCenter {
  height: 60vh;
  display: flex;
  align-items: center;
}

.selectedIngredient {
  background: #f9f9f9;
  border-radius: 12px;
  margin: 0 0 4px 0;
}
.ctaButtons {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    span {
      padding: 5px 0;
    }
  }
  .userHeaderBtn {
    position: relative !important;
    top: 0 !important;
    right: 0 !important;
  }
  &.recipePage {
    position: fixed;
    top: 10px;
    right: 10px;
    display: block;
    width: 40px;
    z-index: 5;
    button {
      background: #f9f9f9;
      color: #1a1b1c;
      width: 100%;
      border: none;
      width: 40px;
      height: 40px;
      margin: 0 0 5px 0;
      border-radius: 30%;
      padding: 0;
      svg {
        display: block;
        margin: 0 auto;
        width: 23px;
        height: 23px;
      }
    }
  }
  &.business {
    position: absolute;
    min-width: 40px !important;
    max-width: 40px !important;
    width: fit-content !important;
    height: 40px;
    top: -10px !important;
    right: 10px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: transparent;
    padding: 0;
    margin: 0;
    button {
      margin: 0 4px 0 0;
      &:last-of-type {
        margin-right: 0;
      }
    }
    &.card {
      position: absolute;
      top: 20px !important;
      background: #fff;
      height: 40px;
      display: flex !important;
      align-items: center !important;
      height: fit-content !important;
      .buttonShare {
        width: 100% !important;
        height: 100% !important;
        svg {
          width: 25px !important;
          height: 25px !important;
          margin: 0 auto;
        }
      }
    }
  }
}
.chatsHistoric {
  list-style: none;
  padding: 0 0 20px 0;
  margin: 0;
  text-align: left;
  li {
    padding: 16px 0;
    font-weight: 600;
    border-bottom: 1px solid #333;
    &:last-of-type {
      border: none;
    }
  }
}
.updateRecipeImage {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  pointer-events: all !important;
  border-radius: 20px;
}
.checkoutDetails {
  height: 0;
  overflow: hidden;
  transition: 0.35s;
  &.showContactDetails {
    height: fit-content !important;
    transition: 0.35s;
    .flexBox {
      input {
        text-align: center;
      }
    }
  }
}
.ingredientSelector {
  .ingredientSelectorInput {
    position: relative;
    .dragButton {
      left: 2px;
      z-index: 9;
      background: #fff;
      svg {
        color: #999;
      }
    }
    input {
      border: 1px solid #ccc;
      background: transparent;
    }
    button {
      position: absolute;
      top: 4px !important;
      right: 4px !important;
      height: 37px;
      width: 37px;
      background: #ff6900;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      border: none;
      transition: 0.35s;
      svg {
        width: 90%;
        height: 90%;
      }
    }
  }
  .ingredientDetails {
    height: 0;
    overflow: hidden;
    padding: 8px;
    &.show {
      height: fit-content;
    }
    ul {
      list-style: none;
      padding: 0;
      display: flex;
      justify-content: space-between;
      overflow: auto;
      width: 100%;
      li {
        font-weight: 600;
        font-size: 13px;
        border-radius: 20px;
        min-width: 100px;
        box-sizing: border-box;
        padding: 12px;
        margin: 0 5px 0 0;
        background: #fff;
        text-align: left;
        border: 2px solid transparent;
        &:hover {
          border: 2px solid #ff6900;
        }
      }
    }
  }
  .ingredientResults {
    list-style: none;
    padding: 0;
    li:nth-child(odd) {
      background: #f9f9f9;
    }
    button {
      width: 100%;
      background: transparent;
      text-align: left;
      border: none;
      padding: 16px 8px;
      font-weight: 600;
      text-transform: capitalize;
    }
  }
}
.businessBox {
  margin: 0 0 8px 0;
  padding: 0 0 24px 0;
  background: #f9f9f9;
  padding: 12px;
  border-radius: 20px;
  .btnSecondary {
    background: transparent !important;
  }
  h1 {
    text-align: left !important;
    small {
      font-size: 16px;
    }
  }
}
.cardClaim {
  background: #ff6900;
  padding: 12px;
  border-radius: 20px;
  .btnSecondary {
    border: none;
  }
  h3,
  p {
    color: #fff;
  }
  p {
    font-weight: 500;
    width: 95%;
  }
}
.map {
  border-radius: 20px;
  overflow: hidden;
  pointer-events: none;
  min-height: 100px;
  width: 100px;
  background: #f9f9f9;
}
.recipeAdderImage {
  width: 100%;
  aspect-ratio: 1/1;
  // height: 150px;
  object-fit: cover;
  border-radius: 20px;
  pointer-events: all !important;
}
.workingDays:first-of-type {
  color: #34c85a;
  padding-top: 0;
}
.workingDays {
  color: #777;
  width: 100%;
  height: 24px !important;
  max-height: 24px !important;
  transition: 0.35s linear;
  overflow: hidden;
  &.showAll {
    height: 220px !important;
    max-height: 220px !important;
    transition: 0.35s linear;
  }
  h5 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 0;
    margin: 0;
    color: #777;
  }
  .daysList {
    display: block;
    width: fit-content;
    text-align: left;
    transition: linear 0.35s;
    font-size: 18px;
    font-weight: 500;
  }
  .hoursList {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 500;
    padding: 0 2px 0 0;
    span:nth-child(2) {
      padding: 0 8px;
      min-width: fit-content;
      width: fit-content;
    }
    span {
      min-width: 50px;
      width: 50px;
      display: block;
    }
  }
}
.showAllWorkingDaysBtn {
  background: transparent;
  padding: 4px 0;
  border: 0;
  font-size: 13px;
  color: #ff6900;
  margin: 0 0 0 auto;
}
.listFeatures {
  padding: 0;
  width: 100%;
  text-align: center;
  padding: 0;
  list-style: none;
  li {
    background: #f9f9f9;
    font-weight: 600;
    margin: 8px 0;
    padding: 12px;
    font-size: 20px;
    // display: flex;
    // align-items: center;
    border-radius: 12px;
    button {
      background: transparent;
      border: none;
      padding: 16px 0;
      text-align: center;
    }
    small {
      display: block;
      font-weight: 500;
      padding-top: 4px;
    }
  }
}
#fullName {
  text-transform: capitalize !important;
}
.businessRating {
  border-radius: 50px;
  font-size: 16px;
  color: orange;
  display: flex;
  align-items: center;
  margin: 0 0 0 8px;
  svg {
    display: block;
  }
}
.feedbackStars {
  svg {
    color: #999;
    font-size: 50px;
  }
}
.card {
  &.feedback {
    padding: 8px 12px;
    box-sizing: border-box;
    text-align: left;
    .feedbackStars {
      font-size: 25px;
      color: #999;
      display: flex;
      align-items: center;
      svg {
        display: block;
        color: orange;
      }
    }
  }
}
.businessOrderItems {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  min-width: 100%;
  max-width: 100%;
  border-radius: 8px;
  font-weight: 600;
  color: #777;
  img {
    width: 35px;
    height: 35px;
    margin: 0 8px 0 0;
    object-fit: cover;
    border-radius: 50%;
  }
  .businessOrderItemMain {
    display: block;
    min-width: 65%;
    width: 65%;
    display: flex;
    align-items: center;
  }
  .businessOrderItemSecond {
    display: flex;
    align-items: center;
    width: 35%;
    max-width: 35%;
  }
  .businessOrderItemQuantity {
    display: flex;
    align-items: center;
    min-width: 50%;
    width: 50%;
    flex-shrink: 0;
  }
  .businessOrderItemPrice {
    display: block;
    width: 50%;
    min-width: 50%;
    text-align: right;
  }
}
.businessOrderLogo {
  width: 95px;
  height: 95px;
  object-fit: cover;
  border-radius: 50%;
}
.mockup {
  width: 100%;
  height: 50%;
  overflow: hidden;
  object-fit: cover;
}
.iconImage {
  min-width: 100px;
  width: 100px;
  min-height: 100px;
  height: 100px;
  display: block;
  margin: 0 auto;
}

.swiperFeatures {
  width: calc(100% + 40px) !important;
  margin: 0 -20px 0 -20px !important;
  &.howItWorks {
    .swiper-pagination {
      position: absolute;
      width: 100%;
      height: 50px;
      top: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }
    .customBullet {
      background-color: transparent !important;
      color: #000;
      width: 50px !important;
      height: 50px !important;
      line-height: 30px;
      border-radius: 50%;
      font-size: 18px;
      font-weight: 600;
      position: relative;
      border: 3px solid #ddd;
      color: #ddd;
      display: flex !important;
      align-items: center !important;
      justify-content: center;
      transition: 0.35s linear;
      opacity: 1;
    }
    .customBullet:before {
      content: "";
      position: absolute;
      top: 50%;
      left: -22px;
      width: 22px;
      height: 3px;
      background-color: #ddd;
      transform: translateY(-50%);
      z-index: -1;
    }
    .customBullet:first-child:before {
      content: none;
    }
    .swiper-pagination-bullet-active {
      border: 3px #ff6900 solid;
      background: #fff;
      opacity: 1 !important;
      transition: 0.35s linear;
      color: #ff6900;
    }
    .customBullet.completed {
      border: 3px #ff6900 solid;
      background: #fff;
      opacity: 1 !important;
      transition: 0.35s linear;
      color: #ff6900;
    }
    .customBullet.completed:before {
      background: #ff6900;
      opacity: 1;
    }
  }
  &.menu {
    width: 100% !important;
    overflow: visible;
    .swiper-pagination {
      margin-bottom: -50px;
      .swiper-pagination-bullet-active {
        background: #ff6900;
      }
    }
    .swiper-slide {
      background: #f9f9f9;
      padding: 16px;
      border-radius: 20px;
      min-height: 264px;
      width: 100%;
      text-align: left;
      img {
        margin: 0;
      }
      small {
        color: #777;
      }
    }
  }
  h3 {
    width: 100% !important;
    font-size: 20px !important;
  }
  .swiper-slide {
    height: 95%;
    padding: 5% 0 0 0;
    h3 {
      margin-bottom: -30px;
      z-index: 2;
      position: relative;
    }
  }
}
.btnLogo {
  background: transparent;
  border: none;
  width: 100%;
}
.headerSecond {
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 22px;
  letter-spacing: 1px;
  text-align: left;
  margin-bottom: 0;
}
.featureDetail {
  padding: 10px;
  background: #f9f9f9;
  border-radius: 20px;
  margin-top: 5px;
  width: 100%;
  box-sizing: border-box;
  h3 {
    width: 100% !important;
    font-size: 20px !important;
  }
}
.swiper {
  width: 100%;
}
.flexAnimated {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 300%;
  overflow: auto;
  li {
    display: block;
    box-sizing: border-box;
    width: fit-content;
    small {
      display: block;
      width: 130px;
    }
  }
}
.listPrices {
  list-style: none;
  text-align: left;
  padding: 0;
  li {
    padding: 8px 12px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }
  li:before {
    content: "\2713";
    margin-right: 8px;
    color: #4caf50;
    border: 2px solid #4caf50;
    border-radius: 8px;
    width: 12px;
    font-size: 18px;
    padding: 0 6px 0 4px;
    flex-shrink: 0;
  }
}
.fullWidth {
  width: 100%;
}
.textSpacer {
  margin: 0 8px;
}
.textSeparator {
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    background: #ddd;
    height: 1px;
    width: 43%;
    display: inline-block;
  }
}
.textCopy {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: rtl;
  text-align: left;
  border: 2px solid #ddd;
  border-radius: 12px;
  padding: 12px;
  box-sizing: border-box;
  transition: 0.35s linear;
  &.copied {
    border: 2px solid #ff6900;
    transition: 0.35s linear;
  }
}
.layoutAuth {
  background: #ff1b1c;
}

.textWarning {
  background: #ff6900;
  width: 100%;
  padding: 16px 8px;
  border-radius: 12px;
  color: #fff;
  font-weight: 600;
  text-align: left;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  svg {
    width: 40px;
    height: 40px;
    margin: 0 16px 0 8px;
    flex-shrink: 0;
  }
}
.btnCopy {
  background-color: transparent;
  margin: 0 0 0 auto;
  border-radius: 50px;
  border: none;
  &.copied {
    color: #ff6900;
  }
}
.card {
  &.businessOrder {
    background: #f9f9f9;
    padding: 13px;
    border-radius: 20px;
    .textLeft {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      padding: 12px 0;
      border-bottom: 2px solid #fff;
      font-weight: 500;
      color: #777;
      .address {
        text-align: right;
      }
      &:first-of-type {
        padding-top: 0;
      }
      &:last-of-type {
        border-bottom: 0;
        padding-bottom: 0;
      }
      &.flexStart {
        align-items: flex-start;
        span {
          display: flex;
          flex-direction: column;
          justify-content: right;
        }
      }
      strong {
        flex-shrink: 0;
      }
    }
  }
}
.myFeaturesSwiper {
  margin-left: -20px !important;
  width: calc(100% + 40px);
  .swiper-slide:hover {
    border: 2px solid #ff6900;
    color: #ff6900;
    transition: 0.35s;
  }
  .swiper-slide {
    border: 2px solid #ddd;
    height: 60px;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    font-weight: 500;
    letter-spacing: 0.7px;
    transition: 0.35s;
  }
}
@media print {
  html,
  body * {
    visibility: hidden;
    width: fit-content;
    height: fit-content;
  }

  .qrCodeImage {
    visibility: visible;
    position: absolute;
    top: 0;
    left: 0;
  }
}
.businessRecipeImage {
  width: 100%;
  max-height: 150px;
  object-fit: cover;
  border-radius: 20px !important;
}
.element5 {
  input {
    width: 90%;
  }
}
.checkboxTerms {
  display: flex;
  align-items: center;
  color: #555;
  label {
    margin: 0 8px;
  }
}

.featureHeaderImage {
  width: 90%;
  max-width: 500px;
  aspect-ratio: 1/1 !important;
  margin: 20px auto 12px auto;
  overflow: hidden;
  position: relative;
  border-radius: 50% 4px 50% 4px;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1 !important;
  }
}

.bulletNutrition {
  display: block;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  margin: 0 4px 0 0;
  &.all {
    background: conic-gradient(
      from 15deg,
      #5855d6,
      lightcoral,
      #ff3830,
      #34c85a,
      #5ac8fb,
      #ffcc00
    );
  }
  &.calories {
    background: #5855d6;
  }
  &.carbs {
    background: lightcoral;
  }
  &.fats {
    background: #ff3830;
  }
  &.fibers {
    background: #34c85a;
  }
  &.protein {
    background: #5ac8fb;
  }
  &.sugar {
    background: #ffcc00;
  }
  &.salt {
    background: #ff9501;
  }
  &.header {
    width: 12px;
    height: 12px;
    margin: 0 4px 0 0;
  }
}
.flexStatus {
  display: flex;
  align-items: center;
  min-height: 45px;
  button {
    background: transparent;
    border: none;
    width: 45px;
    height: 45px;
    color: #ff6900;
    padding: 0 12px 0 0;
  }
}
.statusBar {
  height: 2px;
  width: 100%;
  border-radius: 50px;
  background: #ddd;
  transition: 0.35s;
  span {
    background: #ff6900;
    display: block;
    height: 2px;
    &.linear1 {
      width: 20%;
      transition: 0.35s;
    }
    &.linear2 {
      width: 40%;
      transition: 0.35s;
    }
    &.linear3 {
      width: 60%;
      transition: 0.35s;
    }
    &.linear4 {
      width: 80%;
      transition: 0.35s;
    }
    &.linear5 {
      width: 100%;
      transition: 0.35s;
    }
    &.linear6 {
      width: 100%;
      transition: 0.35s;
    }
  }
}
.marginNone {
  margin: 0;
}
.recipeInList {
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  img {
    width: 80px;
    min-width: 80px;
    max-width: 80px;
    border-radius: 20px;
    display: block;
  }
  font-weight: 500;
}
.fullWidth {
  width: 100%;
  display: block;
  &.centered {
    text-align: center;
  }
}
#nameOnCard {
  text-transform: capitalize;
}
.headerAuth {
  font-size: 35px;
  font-weight: 500;
  margin: 52px 0 36px 0;
}
.ingredientHeader {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .ingredientHeaderText {
    width: calc(100% - 118px);
  }
  input,
  select {
    border: none;
    background: #f9f9f9 !important;
  }
  .ingredientImage {
    label {
      width: 100%;
      height: 100%;
      img {
        width: 110px;
        min-width: 110px;
        max-width: 110px;
        height: 110px;
        min-height: 110px;
        max-height: 110px;
      }
    }
  }
  .flexInput {
    .input {
      input,
      select {
        border: none !important;
      }
      .inputLabel {
        top: calc(100% - 12px);
        left: 50% !important;
        transform: translateX(-50%);
      }
    }
  }
}
.listNotes {
  padding: 0 0 12px 0;
  li {
    display: flex;
    align-items: center;
    padding: 4px 0;
    &:before {
      content: "";
      width: 3px;
      height: 3px;
      background: #999;
      display: block;
      margin: 0 4px 0 0;
    }
    .deleteNoteBtn {
      background: #ff3830;
      padding: 2px;
      border-radius: 50%;
      margin: 0 0 0 auto;
      border: none;
      box-sizing: border-box;
      svg {
        display: block;
        color: #fff;
      }
    }
  }
}
.longPressWrapper {
  background: transparent;
  border: none;
  width: 100%;
}
.buttonAddToCart {
  width: 30px;
  height: 30px;
  background: transparent;
  border: 2px solid #ff6900;
  border-radius: 50%;
  margin: 0 0 0 8px;
  transition: 0.35s linear;
  svg {
    color: #ff6900;
    display: block;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    transition: 0.35s linear;
  }
  &.active {
    transition: 0.35s linear;

    background: #ff6900;
    svg {
      color: #fff;
      transition: 0.35s linear;
    }
  }
}
.marginNone {
  margin: 0;
}
canvas {
  display: block;
}
.stripePaymentPlanCheckout {
  width: 100%;
}
.StripeElement {
  background: #f5f5f5;
  padding: 16px 12px;
  border-radius: 10px;
  font-size: 16px;
}

.buttonBottomFixed {
  position: fixed;
  bottom: 0;
  left: 50%;
  width: 90%;
  max-width: 500px;
  background: #ff6900;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  transition: 0.35s linear;
  transform: translate(-50%, -100%);
  border-radius: 50px;
  z-index: 1;
  box-shadow: 0 20px 25px -20px #999;
  padding: 16px 26px;
  &.active {
    transform: translate(-50%, -20px);
    transition: 0.35s linear;
  }
  h3 {
    color: #fff;
    margin: 0;
    font-size: 20px;
  }
  button {
    border: none;
    color: #fff;
    background: transparent;
    font-size: 20px;
  }
}

/* SPICY RANGE */
.custom-range-wrapper {
  width: 100%;
  position: relative;
}
.custom-range {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px;
  background: #ddd;
  border-radius: 4px;
  outline: none;
  margin: 0;
}

.custom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 30px;
  height: 40px;
  border: 5px solid #fff;
  background: #ffae00;
  border-radius: 8px;
  cursor: pointer;
}
.custom-range::-moz-range-thumb {
  width: 30px;
  height: 40px;
  border: 4px solid #fff;
  background: #ffae00;
  border-radius: 8px;
  cursor: pointer;
}
.custom-range::-ms-thumb {
  width: 30px;
  height: 40px;
  border: 5px solid #fff;
  background: #ffae00;
  border-radius: 8px;
  cursor: pointer;
}
.custom-range::-webkit-slider-runnable-track,
.custom-range::-moz-range-track,
.custom-range::-ms-track {
  height: 8px;
  border-radius: 4px;
  background: #ddd;
  transition: background 0.3s;
}
.custom-range:focus {
  outline: none;
}
.custom-range:focus::-webkit-slider-runnable-track {
  background: #ffae00;
}
.range-labels {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 0 0;
}
@media print {
  body {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
  #businessOrder {
    page-break-inside: avoid;
  }
  .businessOrderItems {
    page-break-inside: avoid;
  }
  img {
    max-height: 100%;
  }
}
.statusBox {
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  .dot {
    width: 24px;
    height: 24px;
    background: #ddd;
    border-radius: 50%;
    position: relative;
    z-index: 2;
  }
  .line {
    position: absolute;
    height: 2px;
    width: 100%;
    background: #ddd;
  }
  .line1,
  .line2,
  .line3 {
    height: 3px;
    width: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.35 linear;
  }
  .line1 {
    &.active {
      width: 33%;
      background: linear-gradient(to right, #ffae00, #ff6900) !important;
      transition: 0.35 linear;
    }
  }
  .line2 {
    &.active {
      background: linear-gradient(to right, #ff6900, #8a2be2) !important;
      width: 33%;
      left: 33%;
      transform: translateY(-50%, -50%);
      transition: 0.35 linear;
    }
  }
  .line3 {
    &.active {
      background: linear-gradient(to right, #8a2be2, #00af54) !important;
      width: 33%;
      left: 66%;
      transform: translateY(-50%, -50%);
      transition: 0.35 linear;
    }
  }
  &.pickUp {
    .line1 {
      &.active {
        width: 50%;
      }
    }
    .line2 {
      &.active {
        left: 50%;
        width: 50%;
        background: linear-gradient(to right, #ff6900, #00af54) !important;
      }
    }
  }
}
.statusText {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 13px;
  margin: 20px auto 40px auto;
  font-weight: 500;
  span {
    display: block;
    width: 25%;
  }
}
.businessSmallCards {
  background: #fff;
  padding: 8px;
  margin: 4px 0;
  border-radius: 20px;
  position: relative;
  small {
    display: flex;
    align-items: center;
    padding: 4px 0;
    svg {
      margin: 0 8px 0 0;
      display: block;
    }
  }
  .externalLink {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 30px;
    color: #ff6900;
  }
}
.blogContentItem {
  position: relative;
  &.form {
    background: #f9f9f9;
  }
  .blogContentRemoveBtn {
    background: transparent;
    border: none;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    svg {
      display: block;
    }
  }
}
.ql-toolbar {
  border-radius: 10px !important;
}
.ql-container {
  border-radius: 10px !important;
  margin: 4px 0;
}
.ql-editor {
  min-height: 100px;
}
.emailPreview {
  text-align: left;
  overflow: hidden;
  img {
    width: 100%;
  }
}
