@import url("https://fonts.googleapis.com/css2?family=Coiny&display=swap");
@font-face {
  font-family: "Montserrat";
  src: url("/assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf")
    format("truetype");
  font-display: swap;
}
.homepage {
  height: 100%;
  width: 100%;
  &.features {
    .homepageCenter {
      .cardHomepage {
        h1 {
          width: 90% !important;
          max-width: 90% !important;
          line-height: unset !important;
          font-family: "Montserrat" !important;
          font-size: 40px !important;
          font-weight: 500;
        }
      }
      .accordion {
        width: 100%;
      }
      h2 {
        font-weight: 500;
      }
    }
  }
  .homepageCenter {
    width: 100%;
    height: 100%;
    overflow: hidden;
    .cardHomepage {
      position: relative;
      padding: 0 20px;
      box-sizing: border-box;
      width: 100%;
      max-width: 500px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 0 auto;
      h1 {
        font-family: "Coiny" !important;
        font-size: 65px;
        margin: 0;
        color: #1a1b1c;
        line-height: 40px;
        width: 300px;
        max-width: 300px;
        span {
          font-size: 42px;
        }
        span:first-of-type {
          color: #ff6900;
        }
      }
      h3 {
        font-weight: 600;
        font-size: 16px;
        width: 100%;
        color: #444;
        &.heightFixed {
          min-height: 76px;
        }
      }

      .homepageButtons {
        width: 80%;
        margin: 0 auto;
      }
      .featuresSwiper {
        position: relative;
        min-height: 260px;
        height: 260px;
        width: 100%;
        max-width: 500px;
        box-sizing: border-box;
        z-index: 3;
        overflow: hidden;
        background: #f9f9f9;
        padding: 8px;
        border-radius: 20px;
        .swiper-slide {
          min-height: 40px;
          height: 40px;
          z-index: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 20px;
          color: #555;
          font-weight: 600;
          font-family: "Montserrat";
          box-sizing: border-box;
          transition: transform 0.8s linear;
          span {
            display: flex;
            align-items: center;
            font-size: 13px;
          }
          .customCheckbox {
            position: absolute;
            top: 10px;
            right: 5px;
          }
        }
        .borderedSlide {
          border: 1px solid #ddd;
          border-radius: 12px;
          transition: border 0.3s ease, background-color 0.3s linear;
        }
      }
    }
  }
}
