//CARROUSEL DASHBOARD USERS
.carrousel {
  width: 100%;
  margin-left: -5%;
  padding: 0 5%;
  overflow: scroll;
  overflow-x: visible;
  .carrouselScroll {
    display: flex;
    align-items: center;
    overflow: visible;
    margin: 0;
    padding: 0;
    &.noAlign {
      align-items: baseline;
      li {
        min-width: 300px !important;
        margin-right: 10px;
      }
      strong {
        margin-right: auto;
      }
    }
    a {
      margin: 0;
      padding: 0;
      display: block;
    }
    .carrouselItemAccountsEmpty {
      min-width: 130px !important;
      min-height: 180px !important;
      height: fit-content;
      background: transparent;
      border: 1px solid #ddd;
      text-align: center;
      margin: 0 8px 0 0;
      padding: 10px 15px;
      border-radius: 20px;
      box-sizing: border-box;
      &.small {
        min-height: fit-content !important;
      }
      .img {
        width: 63px;
        height: 63px;
        border-radius: 40%;
        background: #f5f5f5;
        display: block;
        margin: 0 auto 8px auto;
        font-size: 0;
        display: block;
      }
      h3 {
        font-size: 14px;
        width: 70px;
        height: 18px;
        margin: 0 auto;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        background: #f5f5f5;
        border-radius: 50px;
        padding: 0;
        font-size: 0;
      }
      p {
        width: 50px;
        height: 13px;
        background: #f5f5f5;
        border-radius: 50px;
        padding: 0;
        margin: 5px auto 0 auto;
        font-size: 0;
      }
      .userActionBtn {
        min-width: 82px;
        min-height: 25px;
        border-radius: 50px;
        background: #f5f5f5;
        margin: 5px auto 0 auto;
        padding: 0;
        font-size: 0;
      }
    }
    .carrouselItemAccounts {
      min-width: 130px;
      height: fit-content;
      background: transparent;
      text-align: center;
      margin: 0 8px 0 0;
      padding: 10px 0;
      border-radius: 20px;
      border: 1px solid #ddd;
      .roleIndicatorBox {
        .userBackground {
          background: #ddd;
          padding: 2px;
        }
        img {
          object-fit: cover !important;
          padding: 2px;
        }
      }
      .modalBottomCloseButton {
        padding: 0;
        border: 0;
      }
      img {
        width: 55px;
        height: 55px;
        border-radius: 30px;
        background: #fff;
        display: block;
        margin: 0 auto 5px auto;
        font-size: 0;
        object-fit: cover;
      }
      p {
        margin: 0;
        font-size: 13px;
        padding: 0 8px;
        max-width: 120px;
      }
      .textFlexLeft {
        margin: revert;
      }
      .smallText {
        a {
          display: inline-block;
        }
      }
      .btnSecondary {
        font-size: initial;
        border: 2px solid #ff6900;
        padding: 12px 0;
      }
      .carrouselItemAccountHeader {
        margin: 13px auto 0 auto;
        font-size: 16px;
        width: 122px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .carrouselItemAccountsBtn {
        button {
          border: none;
          font-size: 13px;
          margin: 5px auto 0 auto;
          padding: 5px 8px;
        }
      }
    }
  }
}

.carrouselItem {
  width: 160px;
  height: 210px;
  margin: 5px;
  overflow: hidden;
  position: relative;
  text-align: left;
  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: transparent;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 3;
    button {
      width: 100%;
      height: 100%;
      padding: 0;
      background: transparent;
      border: 0;
    }
  }
  img {
    height: 156px;
    width: 156px;
    object-fit: cover;
    z-index: 1;
    border-radius: 20px;
  }
  link[rel="preload"][as="image"] {
    height: 156px;
    width: 156px;
    object-fit: cover;
    z-index: 1;
    border-radius: 20px;
    border: 1px solid #ddd;
    display: block;
  }
  span {
    font-size: 20px;
    color: #999;
    width: 100%;
    z-index: 2;
    font-size: 14px;
    box-sizing: border-box;
    height: fit-content;
    font-weight: 600;
    font-family: "Montserrat";
    white-space: nowrap;
    max-width: 155px;
    text-overflow: ellipsis;
  }
}
