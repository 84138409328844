.offlineNotification {
  background: #333;
  color: white;
  padding: 5px 0;
  text-align: center;
  z-index: 9;
  font-size: 13px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  svg {
    margin-right: 10px;
    font-size: 18px;
  }
}
