//TABS
.tabs {
  width: 100%;
  &.skeleton {
    input {
      background: #f5f5f5 !important;
      border: none;
    }
  }
  .tabButtonsPrimary {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    z-index: 4;
    overflow: scroll;
    &.usersPage {
      width: 110%;
      margin-left: -5%;
      justify-content: space-between;
      .tabButtonPrimary:last-of-type {
        width: 33.33%;
        margin-right: 0;
      }
      .tabButtonPrimary {
        min-width: calc(33.33% - 5px);
        border-radius: 0;
        border-bottom: 2px solid transparent !important;
        padding: 10px;
      }
      h3 {
        margin: 0 !important;
      }
    }
    .tabButtonPrimary {
      background: transparent;
      border: none;
      font-weight: 700;
      border-radius: 50px;
      display: block;
      margin: 0;
      transition: 0.35s;
      padding: 0;
      color: #999999 !important;
      &.skeleton {
        margin-top: 7px;
        h3 {
          display: block !important;
          min-width: 70px !important;
          min-height: 24px !important;
          width: 70px !important;
          height: 24px !important;
          background: #f5f5f5 !important;
          border-radius: 50px !important;
          margin: 0px 10px 10px 0;
          font-size: 0;
        }
      }
      .explore {
        padding: 0 10px 16px 0;
        margin: 0 10px 0 0;
        transition: 0.35s;
        color: #999;
        font-weight: 600;
      }
      &.active {
        transition: 0.35s;
        h3 {
          color: #1a1b1c;
          transition: 0.35s;
        }
      }
    }
  }

  .tabButtonsSecondary {
    width: 100%;
    text-align: left;
    z-index: 4;
    display: flex;
    border-bottom: 2px solid rgba(30, 30, 30, 0.1) !important;
    overflow: scroll;
    .tabButtonSecondary {
      background: transparent;
      padding: 5px 10px;
      color: #999;
      width: fit-content;
      font-size: 14px;
      transition: 0.35s;
      border: none;
      border-bottom: 2px solid transparent !important;
      flex-shrink: 0;
      &.skeleton {
        display: block !important;
        min-width: 50px !important;
        min-height: 14px !important;
        width: 50px !important;
        height: 14px !important;
        background: #f5f5f5 !important;
        border-radius: 50px !important;
        margin: 5px;
      }
      &.active {
        color: #ff6900;
        transition: 0.35s;
        border-bottom: 2px solid #ff6900 !important;
      }
    }
  }
}

//AUTH

.btnsAuthTab {
  position: relative;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 0;
  text-align: center;
  .tabs {
    .tabButtonsPrimary {
      .tabButtonPrimary {
        width: 50%;
        margin: 0 0 30px 0;
        h3 {
          background: transparent;
          color: #999;
          border: none;
          border-radius: 0;
          box-sizing: border-box;
          padding: 20px 0;
          transition: 0.35s;
          border-bottom: 2px solid #f5f5f5;
          width: 100%;
          display: block;
          box-sizing: border-box;
          margin: 0;
          font-size: 14px;
        }
        &.active {
          h3 {
            color: #1a1b1c;
            border-bottom: 2px solid #1a1b1c;
          }
        }
      }
    }
    .tabContentPrimary {
      padding: 0 5%;
      small {
        text-align: left;
        display: block;
        width: 100%;
      }
    }
  }
}
