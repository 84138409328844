.navBottom {
  position: fixed;
  top: inherit;
  bottom: 30px !important;
  left: 50%;
  transform: translateX(-50%);
  height: fit-content;
  width: fit-content;
  max-width: 500px;
  background: #fbfbfb;
  box-shadow: 0 20px 25px -20px #999;
  border-radius: 50px;
  overflow: visible;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.35s;
  padding: 4px 8px;
  &.five {
    a {
      min-width: 20%;
      width: 20%;
    }
  }
  a {
    position: relative;
    min-width: 25%;
    width: 25%;
    max-width: 125px;
    &.active {
      svg {
        color: #ff6900;
        margin: 0 auto;
      }
    }
  }
  button {
    background: transparent;
    border: 0;
    padding: 12px;
    box-sizing: border-box;
    width: 54px;
    height: 54px;
    border-radius: 50px;
    svg {
      width: 90%;
      height: 90%;
      display: block;
      color: #999;
      margin: 0 auto;
      transition: 0.35s linear;
    }
  }
  .emptyShoppingList {
    display: none;
  }
  .listQuantityButton {
    position: absolute;
    top: -5px;
    left: calc(50% + 15px);
    transform: translateX(-50%) !important;
    width: 20px;
    height: 20px;
    background: #ff6900;
    border-radius: 50%;
    font-size: 11px;
    padding: 0;
    color: #fff;
    border: 2px solid #ff6900;
    display: none;
    &.show {
      display: block;
    }
    &.right {
      top: 20%;
      left: 65% !important;
      transform: translate(-50%, -50%) !important;
      height: 20px;
      width: 20px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        display: block;
        height: fit-content;
        margin: 0;
      }
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -2px;
    }
  }
}
.navTop {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 5;
  box-shadow: 0 16px 15px 15px rgba(255, 255, 255, 1);
  transition: 0.35s linear;
  background: #fff;
  box-sizing: border-box;
  padding: 30px 20px 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 500px;
  height: 50px;
  z-index: 4;
  .navButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: -2.5px 0 0 auto;
    a:first-of-type {
      margin: 0 8px;
    }
    a {
      position: relative;
      display: block;
      padding: 0;
      margin: 0;
      border-radius: 12px;
      &.active {
        .navTopBtn {
          svg {
            color: #ff6900;
          }
        }
      }
      .navTopBtn {
        margin: 0;
        display: block;
        height: 30px;
        width: 30px;
        transition: 0.35s;
        svg {
          width: 28px;
          height: 28px;
          display: block;
        }
        img {
          width: 26px;
          height: 26px;
          border: 2px solid #ccc;
          border-radius: 12px;
          object-fit: cover;
          background: #fff;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    a:hover,
    .active,
    .focus {
      .navTopBtn {
        background: #ff6900;
        transition: 0.35s;
        svg {
          color: #ff6900;
          transition: 0.35s;
        }
        img {
          border: 2px solid #ff6900;
        }
        &.creator {
          img {
            border: 2px solid purple;
          }
        }
        &.nutrition {
          img {
            border: 2px solid #add100;
          }
        }
        &.business {
          img {
            border: 2px solid #00c6ff;
          }
        }
      }
    }
    .navTopBtn:first-of-type:hover,
    .active,
    .focus {
      background: #ff6900;
      transition: 0.35s;
      svg {
        color: #ff6900;
        transition: 0.35s;
      }
    }
    .navTopBtn:first-of-type {
      padding: 0;
      border-radius: 12px;
      transition: 0.35s;
      svg {
        box-sizing: border-box;
        padding: 4px;
        margin: 2px;
        color: #999;
        background: #fff;
        width: 26px;
        height: 26px;
        transition: 0.35s;
      }
    }
    .navTopBtn {
      background: #ccc;
      border: none;
      border-radius: 30px;
      position: relative;
      border-radius: 15px;
      svg {
        display: block;
        width: 30px;
        height: 30px;
        transition: 0.5s;
        color: #ccc;
        border-radius: 10px;
      }
    }
  }
}
.navSecondary {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%) !important;
  background: #fff;
  display: flex;
  align-items: center;
  height: 55px;
  width: 100%;
  max-width: 500px;
  overflow: visible;
  box-sizing: border-box;
  transition: 0.35s linear;
  z-index: 5;
  box-shadow: 0 16px 15px 15px rgba(255, 255, 255, 1);
  &.image {
    display: flex;
    align-items: center;
    box-shadow: none !important;
    background: #999;
    padding: 10px 0;
    height: 150px;
    position: relative;
    h1 {
      font-size: 24px;
      width: 100%;
      position: absolute;
      bottom: -10px;
      left: 0;
      padding: 110px 0 40px 0;
      margin: 0;
      color: #1a1b1c;
      background: linear-gradient(transparent, #fff);
    }
    img {
      width: 100%;
      max-width: 550px;
      height: 170px;
      object-fit: cover;
    }
    .navBusinessLogo {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 150px;
      height: 150px;
      border-radius: 50%;
      object-fit: contain;
      border: 4px solid #fff;
      background: #fff;
      padding: 5px;
      box-sizing: border-box;
    }
  }
  &.transparent {
    background: transparent;
    box-shadow: none;
    width: fit-content;
    left: calc(50% - 250px);
  }
  &.fullWidth {
    background: transparent;
    box-shadow: none;
    width: 100%;
    max-width: 500px;
    // transform: translate(-50%) !important;
  }
  h1 {
    font-size: 18px;
    width: 100%;
    max-width: 500px;

    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    margin: 20px 0;
    .userHeaderBtn {
      position: fixed;
      top: 10px;
      right: 10px;
      width: 40px !important;
      height: 40px !important;
      border: none;
      border-radius: 40%;
      background: #f9f9f9;
      z-index: 6;
      svg {
        font-size: 25px;
        display: block;
        margin: 0 auto;
        color: #1a1b1c !important;
      }
    }
    .modalTooltip {
      top: 0;
      left: auto !important;
      right: 0;
      background: transparent;
      z-index: 9;
      .modalFullContent {
        text-align: left;
        button {
          text-align: left;
          display: block;
          color: #1a1b1c;
        }
        a:hover,
        .active {
          button {
            color: #999;
          }
        }
      }
    }
  }
}
@media (max-width: 500px) {
  .navSecondary {
    left: 0 !important;
    transform: translateX(0) !important;
  }
}
@media (min-width: 500px) {
  .fullWidth {
    left: 50% !important;
  }
  .ctaButtons {
    &.recipePage {
      right: calc(50% - 240px) !important;
      transform: translateX(-50) !important;
    }
  }
}
