button {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;
  outline: none;
}
button:disabled {
  opacity: 0.5;
  transition: 0.35s;
}
.btnPrimary {
  width: 100%;
  border-radius: 50px;
  background: #1a1b1c;
  padding: 16px 12px;
  border: none;
  color: #fefefe;
  margin: 2px 0;
  position: relative;
  transition: 0.35s;
  .btnFlex {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.btnSecondary {
  width: 100%;
  max-width: 550px;
  border-radius: 50px;
  padding: 16px 12px;
  color: #1a1b1c;
  margin: 2px 0;
  border: 2px solid #1a1b1c;
  background: #fff;
  position: relative;
  transition: 0.35s;
  &.red {
    border: 2px solid red;
    color: red;
  }
  .btnFlex {
    display: flex;
    align-items: center;
    width: fit-content;
    margin: 0 auto;
    svg {
      margin-right: 8px;
    }
  }
}
.btnTertiary {
  width: 100%;
  border-radius: 10px;
  background: #fff;
  padding: 16px 12px;
  border: none;
  color: #1a1b1c;
  margin: 2px 0;
  transition: 0.35s;
  &.red {
    color: red;
  }
}
.userActionBtn {
  background: transparent;
  border: none !important;
  border-radius: 50px !important;
  padding: 16px 0;
  width: fit-content;
  font-weight: 600;
  &.followBtn {
    background: #ff6900;
    color: #fff;
  }
  &.unfollowBtn {
    background: #eee !important;
    color: #ff6900;
    &.tertiary {
      background: transparent !important;
      color: #999;
    }
  }
  &.blockBtn {
    background: #999 !important;
    color: #fff;
  }
  &.unblockBtn {
    background: #999 !important;
    width: 100% !important;
    color: #fff;
  }
  &.pendingBtn {
    background: #999;
    color: #fff;
    opacity: 0.3;
  }
  &.subscribeBtn {
    background: linear-gradient(45deg, #b993d6, #8ca6db);
    color: #fff;
  }
  &.subscribedBtn {
    background: #333;
    color: #fff;
  }
  span {
    border: 0 !important;
  }
}
